.p-pages-personal_storage article.headline .image:before
  background-image: url('../../assets/images/static/personal_business_storage/background-personal.png')

.p-pages-business_storage article.headline .image:before
  background-image: url('../../assets/images/static/personal_business_storage/background-business.png')

.p-pages-personal_storage,
.p-pages-business_storage
  article.headline
    .custom-content > *:last-child
      margin-bottom: 0

    .image
      +lte_ipad
        margin-top: rem(28px)

      +gte_laptop
        margin-top: 0

      &:before
        background-size: contain
        content: ''
        display: block

        +lte_ipad
          height: rem(249px * math.div(288, 439))
          width: rem(288px)

        +gte_laptop
          height: 249px
          width: 439px


  .info-services
    +lte_ipad
      display: flex
      flex-direction: column-reverse

  .expandable-list-block
    +lte_ipad
      background: #f6f6f6
      padding-bottom: rem(54px)

    h2
      +lte_ipad
        padding-top: rem(50px)

    .f-content
      li > .inner
        +fcolumn-responsive($columns: 1, $laptop-columns: 2, $desktop-columns: 2, $offset-desktop: 42px, $gutter-desktop: 42px, $selector: '.b-service')

        +lte_ipad
          .b-service:first-child
            margin-top: rem(-1px)

        +gte_laptop
          align-items: flex-start

  .storages-block
    +lte_ipad
      padding-top: rem(28px)
      margin-bottom: rem(39px)

  #map_mobile
    +lte_ipad
      margin-bottom: rem(55px)
