.p-pages-dostavka article.slide-2
  margin-bottom: 0
  overflow: hidden
  padding-bottom: rem(180px)

  h2
    +lte_ipad
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 88px

  ul
    position: relative

    +lte_ipad
      margin-bottom: rem(32px)

    $desktop-gutter: 28px
    +gte_laptop
      +fcolumn(3, $desktop-gutter, $desktop-gutter, 'li')
      // added to align blue circle properly because of negatibe bottom margin from fcolumn
      margin-bottom: #{120px - $desktop-gutter}
      margin-top: -$desktop-gutter
      padding-top: $desktop-gutter

    &:before
      background: #5096ff
      border-radius: rem(596px)
      content: ''
      height: rem(596px)
      left: 50%
      position: absolute
      top: 50%
      transform: translateX(-50%) translateY(-50%)
      width: rem(596px)
      z-index: -1

      +gte_laptop
        +margin-left_ltr(math.div($desktop-gutter, 2))

    li
      background: #fff
      border-radius: rem(32px)
      box-shadow: rem(0px) rem(22px) rem(44px) rem(-12px) rgba(0, 0, 0, 0.1)
      padding: rem(32px) rem(24px) rem(20px)
      position: relative

      +lte_ipad
        padding-top: rem(44px)

        &:not(:last-child)
          margin-bottom: rem(16px)

      +gte_laptop
        height: 240px

    .number
      align-items: center
      background: var(--primary-color)
      border-radius: rem(64px)
      color: #fff
      display: flex
      font-size: rem(30px)
      font-weight: 700
      height: rem(64px)
      justify-content: center
      width: rem(64px)

      +lte_ipad
        margin-bottom: rem(18px)

      +gte_laptop
        margin-bottom: 16px

    .icon
      align-items: center
      background: #eff3fa
      border-radius: 0 rem(32px) 0 rem(32px)
      display: flex
      justify-content: center
      position: absolute
      +right_ltr(0)
      top: 0

      +lte_ipad
        height: rem(120px)
        width: rem(116px)

      +gte_laptop
        height: 108px
        width: 108px

      &:before
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        content: ''
        height: rem(56px)
        width: rem(56px)

      @each $i in 1,2,3,4,5,6
        &-#{$i}:before
          background-image: url('../images/pages/dostavka/slide_2/icon_#{$i}.svg')

    .text
      +lte_ipad
        font-size: rem(16px)
        line-height: 1.3

      +gte_laptop
        font-size: 20px
        line-height: 28px

  .note
    background: var(--primary-color)
    border-radius: rem(15px)
    position: relative

    +lte_ipad
      padding: rem(12px) rem(28px)

    +gte_laptop
      padding-bottom: 24px
      +padding-left_ltr(192px)
      +padding-right_ltr(0)
      padding-top: 24px

    .text
      color: #fff
      font-size: rem(16px)
      line-height: 1.3

      +lte_ipad
        text-align: center

      +gte_laptop
        max-width: 580px

    img
      position: absolute

      +lte_ipad
        +left_ltr(rem(-16px))
        top: rem(62px)

      +gte_laptop
        +left_ltr(-36px)
        top: -48px
