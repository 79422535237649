article.care
  +gte_laptop
    margin-bottom: 120px

  .subheadline
    +gte_laptop
      max-width: 540px

  h2
    +gte_laptop
      max-width: 540px

  .background
    position: relative

    +gte_laptop
      margin-top: -10px

    &:before,
    &:after
      content: ''
      position: absolute
      width: 100%
      background-repeat: no-repeat
      z-index: -2

    &:before
      left: 0

      +lte_ipad
        background-image: url('../images/articles/care/background-mobile.svg')
        background-size: cover
        height: rem(227px)
        top: rem(59px)

      +laptop-new
        background-image: url('../images/articles/care/background-laptop.svg')
        background-size: contain
        height: 350px
        top: 12px

    +gte_desktop-new
      &:before,
      &:after
        background-size: cover
        height: 350px
        top: 12px
        width: 960px
        max-width: 50%

      &:before
        background-image: url('../images/articles/care/background-desktop-left.svg')

      &:after
        background-image: url('../images/articles/care/background-desktop-right.svg')
        background-position-x: right
        right: 0

  $inner-shadow-margin: 14px
  .background
    .container
      +lte_ipad
        padding-bottom: 0
        +padding-left_ltr(rem(2px))
        +padding-right_ltr(0)
        padding-top: 0
        width: rem(288px + $inner-shadow-margin * 2)

      +gte_laptop
        padding-bottom: 0
        +padding-left_ltr($inner-shadow-margin)
        +padding-right_ltr(0)
        padding-top: 0

      +laptop-new
        width: scale-laptop((224px + $inner-shadow-margin * 2) * 4, (255px + $inner-shadow-margin * 2) * 4)

      +gte_desktop-new
        width: #{(255px + $inner-shadow-margin * 2)*4}

    @media screen and (min-width: 1918px)
      .hd-background-fix
        background: #5096ff
        height: 232px
        +left_ltr(0)
        position: absolute
        top: 71px
        width: 100%
        z-index: -1

  .slides
    overflow: hidden

    +laptop-new
      +margin-left_ltr(-$inner-shadow-margin)

    +gte_desktop-new
      +margin-left_ltr(-$inner-shadow-margin + 2px)

    .slides-wrapper
      display: flex
      padding-bottom: 15px

      .slide
        flex-shrink: 0

        +lte_ipad
          width: rem(288px + $inner-shadow-margin * 2)

        +laptop-new
          width: scale-laptop(224px + $inner-shadow-margin * 2, 255px + $inner-shadow-margin * 2)

        +gte_desktop-new
          width: #{255px + $inner-shadow-margin * 2}

      .slide-inner
        border-radius: rem(20px)
        flex-shrink: 0
        overflow: hidden
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25)
        margin: rem(14px)

        &:before
          content: ''
          background-size: cover
          background-repeat: no-repeat
          display: block
          width: 100%

          +lte_ipad
            height: rem(196px)

          +laptop-new
            height: scale-laptop(153px, 174px)

          +gte_desktop-new
            height: 174px

      .slide
        // generate images from originals
        // cp app/packs/images/articles/care/original/* app/packs/images/articles/care/; for image in app/packs/images/articles/care/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
        @each $i in 1,2,3,4,5,6,7,8,9,10
          &-#{$i} .slide-inner:before
            background-image: url('../images/articles/care/slide_#{$i}.png')
            +retina_media
              background-image: url('../images/articles/care/slide_#{$i}@2x.png')

          .webp &-#{$i} .slide-inner:before
            background-image: url('../images/articles/care/slide_#{$i}.webp')
            +retina_media
              background-image: url('../images/articles/care/slide_#{$i}@2x.webp')

        .label
          align-items: center
          background: #fff
          color: var(--text-strong)
          display: flex
          font-size: rem(16px)
          justify-content: center
          line-height: 1.3
          padding: 0 rem(30px)
          text-align: center

          +lte_ipad
            height: rem(76px)

          +laptop-new
            height: scale-laptop(54px, 62px)

          +gte_desktop-new
            height: 62px

    .b-slider-pagination
      +lte_ipad
        margin-top: rem(11px)

      +gte_laptop
        display: none

    .slider-navigation
      +lte_ipad
        display: none

      +gte_laptop
        display: flex
        justify-content: center
        margin-top: 48px
        padding-bottom: 10px // because buttons have shadow

      .b-slider-prev
        +button_navigation(false)
        +margin-right_ltr(rem(24px))

      .b-slider-next
        +button_navigation(true)
