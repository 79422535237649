.p-pages-root.p-v4 article.slide_11
  +container
  margin-bottom: var(--padding-between-blocks)
  padding-bottom: 0
  position: relative

  +lte_ipad
    --offset: #{rem4(24px)}

  +gte_laptop
    --offset: 64px

  header
    bottom: var(--offset)
    left: var(--offset)
    position: absolute
    right: var(--offset)
    width: auto
    z-index: 2

    +lte_ipad
      bottom: rem4(40px)

    h2,
    .note
      color: var(--gray-25)
      padding: rem4(4px)

  .lead
    +button_v4('secondary', 'xl')

    +lte_ipad
      margin-top: rem4(16px)

    +gte_laptop
      margin-top: 8px

  picture img
    max-width: 100%
