article.red_headline
  margin-bottom: var(--padding-between-blocks)

  .wrapper
    padding-top: rem(18px)
    display: flex

    +lte_ipad
      padding-top: 0
      flex-direction: column
      align-items: center

    .left,
    .right
      width: 50%
      height: rem(426px)

    .left
      position: relative
      display: flex
      align-items: center
      justify-content: right

      +lte_ipad
        +padding-right_ltr(0)
        padding-top: rem(80px)
        height: rem(376px)
        width: rem(240px)

      +gte_laptop
        +padding-right_ltr(130px)

      +desktop-new
        +padding-right_ltr(30px)

      .webp:before
        +background-inline-retina('../images/articles/red_headline/background_top-mobile', 'webp', $w: rem(280px), $h: rem(430px), $repeat: no-repeat)

      &:before
        background-position: right
        background-repeat: no-repeat
        content: ''
        position: absolute
        z-index: -1

        +lte_ipad
          background-image: url('../images/articles/red_headline/background_top-mobile.png')
          background-size: contain
          height: rem(430px)
          left: 50%
          top: 0
          transform: translateX(-50%)
          width: rem(280px)

        +gte_laptop
          +rtl_flip
          background-image: url('../images/articles/red_headline/background_left-desktop.png')
          background-size: cover
          height: rem(426px)
          +right_ltr(rem(-162px))
          width: rem(1592px)

      .inner
        +gte_laptop
          +margin-left_ltr(40px)

        h1
          color: #fff

          +lte_ipad
            text-align: center
            font-size: rem(20px)
            line-height: rem(24px)
            margin-bottom: rem(16px)
            justify-content: center

          +gte_laptop
            font-size: 32px
            line-height: 36px
            margin: 0 0 24px
            +text-align_ltr(left)

          +laptop-new
            max-width: scale-laptop(475px, 523px)

          +gte_desktop-new
            max-width: 523px

        .note
          color: #fff

          +lte_ipad
            text-align: center
            font-size: rem(12px)
            line-height: rem(16px)
            margin-bottom: rem(32px)
            +mx-auto
            max-height: rem(146px)
            overflow: scroll

          +gte_laptop
            font-size: 16px
            line-height: 21px
            max-width: 358px
            margin-bottom: 40px

        .buttons
          +lte_ipad
            margin-bottom: rem(57px)

          +gte_laptop
            display: flex

            a:not(:last-child)
              +margin-right_ltr(30px)

          .rent
            +button_white
            +gte_laptop
              min-width: 180px

          .learn-more
            +lte_ipad
              display: none

            +gte_laptop
              +button_frame_white
              +button_frame-arrow('white')

        h1
          +lte_ipad
            min-height: rem(96px)
            display: flex
            align-items: center

    .right
      background-image: url('../images/articles/red_headline/background_right-desktop.png')
      background-repeat: no-repeat
      background-size: cover

      @media screen and (min-width: 2090px)
        background-size: contain
        background-position: left

      +lte_ipad
        height: rem(199px)
        width: rem(280px)
        +background-inline-retina('../images/articles/red_headline/background_bottom-mobile', 'png', $w: rem(280px), $h: rem(199px), $repeat: no-repeat)

      .webp &:after
        +background-inline-retina('../images/articles/red_headline/background_bottom-mobile', 'webp', $w: rem(280px), $h: rem(199px), $repeat: no-repeat)

      +gte_laptop
        +rtl_flip
