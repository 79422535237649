.p-pages-how_to_choose_box article.slide_1
  +lte_ipad
    margin-bottom: 0

  +laptop-new
    margin-bottom: scale-laptop(100px, 112px)

  +gte_desktop-new
    margin-bottom: 112px

  & > .container:first-child
    position: relative

    &:before
      +gte_laptop
        background-image: url('../images/pages/how_to_choose_box/slide_1/background_cicles_1-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: 213px
        +left_ltr(-84px)
        position: absolute
        top: 113px
        width: 213px

    &:after
      +gte_laptop
        background-image: url('../images/pages/how_to_choose_box/slide_1/background_cicles_2-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: 162px
        position: absolute
        +right_ltr(-24px)
        top: 149px
        width: 189px

  h1
    +lte_ipad
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 61px

  .b-switcher
    +lte_ipad
      margin-bottom: rem(66px)

    +gte_laptop
      margin-bottom: 0

    .inner
      +gte_laptop
        width: 540px

  .switcher-slides
    +mx-auto
    max-width: 1920px
