.p-pages-how_to_choose_box article.slide_10
  .subtitle-note
    color: var(--text-strong)

    +lte_ipad
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 60px

    b
      display: block

    +lte_ipad
      text-align: center

      b
        margin-bottom: rem(17px)

  .storable_types
    margin-top: 0

    &:before
      display: none

    .b-switcher
      display: none
