=switcher_colors($first, $first-hover, $first-active, $second, $second-hover, $second-active)
  button
    &.is-active
      &:first-child
        background-color: $first

      &:last-child
        background-color: $second

    +gte_laptop
      &:not(.is-active)
        &:first-child:hover
          background-color: $first-hover

        &:last-child:hover
          background-color: $second-hover

    &:not(.is-active)
      &:first-child:active
        background-color: $first-active

      &:last-child:active
        background-color: $second-active

.b-switcher
  +switcher_colors(var(--secondary-color), var(--secondary-color-hover), var(--secondary-color-active), var(--primary-color), var(--primary-color-hover), var(--primary-color-active))
  display: flex
  justify-content: space-around

  +lte_ipad
    margin-bottom: rem(40px)
    width: 100%

  +laptop-new
    margin-bottom: scale-laptop(32px, 64px)

  +gte_desktop-new
    margin-bottom: 64px

  & > .inner
    align-items: center
    background: #f4f4f4
    border-radius: rem(40px)
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.15)
    display: flex

    +lte_ipad
      height: rem(48px)
      padding: rem(4px)
      width: 100%
      justify-content: space-between
      // width: 288px

    +gte_laptop
      height: 68px
      padding: 8px
      display: grid
      grid-template-columns: repeat(2, minmax(0, 1fr))
      grid-gap: 8px
      // width: 350px

  button
    -webkit-tap-highlight-color: transparent
    align-items: center
    background-color: transparent
    border-radius: rem(40px)
    color: rgba(#939393, 0.8)
    display: flex
    // font-weight: bold
    height: 100%
    justify-content: center
    text-align: center
    transition: box-shadow var(--transition-duration), color var(--transition-duration), background-color var(--transition-duration)
    user-select: none
    white-space: nowrap

    +lte_ipad
      font-size: rem(12px)
      padding: 0 rem(12px)
      flex-grow: 1
      letter-spacing: 0.4475px

      &:first-child
        +margin-right_ltr(rem(4px))

    +gte_laptop
      font-size: 16px
      padding: 0 24px
      letter-spacing: 0.64px

    =active_button
      color: #fff
      font-weight: bold
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25)

    &.is-active
      +active_button

    +gte_laptop
      &:not(.is-active)
        &:hover
          +active_button

    &:not(.is-active)
      cursor: pointer

      &:active
        +active_button

  &:not(.b-switcher--transparent_switch) + div
    [data-switcher_type]
      &:not(.is-active)
        display: none

  &--transparent_switch + div
    [data-switcher_type]
      transition: opacity 2s
      opacity: 1

      &:not(.is-active)
        opacity: 0
        pointer-events: none
        position: absolute
        top: 0

  &--inverse_colors
    +switcher_colors(var(--primary-color), var(--primary-color-hover), var(--primary-color-active), var(--secondary-color), var(--secondary-color-hover), var(--secondary-color-active))

// Стиль для случая, когда слайды идут не сразу после переключателя.
.b-switcher-container
  [data-switcher_type]
    &:not(.is-active)
      display: none
