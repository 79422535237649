.b-mobile-card_selector
  display: flex
  margin: 0 rem(-8px) rem(16px)

  +gte_laptop
    display: none

  .selector
    flex-basis: 33%
    margin: 0 rem(8px)
    min-width: 0
    padding: 0 !important

    &:not(.active)
      +button-frame(true)
      min-width: 0

    &.active
      +button-orange(true)
      cursor: default
      min-width: 0

  +gte_laptop
    display: none
