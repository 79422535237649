.p-photos
  .container-flex
    display: flex

    +lte_ipad
      flex-direction: column

    +gte_laptop
      flex-direction: row

    input[type=submit]
      +lte_ipad
        width: 100%

    .item
      +gte_laptop
        flex-basis: 100px
        flex-grow: 3
        flex-shrink: 1

        &:not(:last-child)
          +margin-right_ltr(24px)

      &.right
        flex-grow: 1
        max-width: 360px

    .block-container
      p
        margin-bottom: rem(40px)

        +lte_ipad
          font-size: rem(14px)

      .examples
        color: var(--text-base)
        display: flex
        line-height: 1.3
        margin-bottom: rem(40px)
        text-align: center

        +lte_ipad
          flex-direction: column
          align-items: center

        +gte_laptop
          justify-content: space-around
          flex-direction: row
          flex-basis: 100px
          flex-grow: 1
          flex-shrink: 1

        .item
          +lte_ipad
            margin-bottom: rem(24px)
            font-size: rem(14px)

          +gte_laptop
            max-width: 200px

          .icon
            height: rem(140px)
            width: rem(140px)
            margin: 0 auto rem(12px)
            background-repeat: no-repeat
            background-size: contain

          &.passport-1-ru
            .icon
              background-image: url('../images/pages/photos/passport-1.svg')
          &.passport-2-ru
            .icon
              background-image: url('../images/pages/photos/passport-2.svg')
          &.passport-3-ru
            .icon
              background-image: url('../images/pages/photos/passport-3.svg')
          &.passport-1-et
            .icon
              background-image: url('../images/pages/photos/passport-1.svg')
          &.passport-2-et
            .icon
              background-image: url('../images/pages/photos/passport-3.svg')

      .buttons
        display: flex
        margin-bottom: rem(40px)

        +lte_ipad
          flex-direction: column
        +gte_laptop
          flex-direction: row

        .upload-photo,
        .make-photo
          +button_frame_white_inner

          +gte_laptop
            flex-basis: 100px
            flex-grow: 1
            flex-shrink: 1

          &.btn-loading
            +progress_loader

        .upload-photo
          +lte_ipad
            margin-bottom: rem(16px)

          +gte_laptop
            +margin-right_ltr(rem(24px))

          .mobile
            +gte_laptop
              display: none

          .desktop
            +lte_ipad
              display: none

        .make-photo
          +lte_ipad
            display: none

      .photo-progress
        h2
          margin-bottom: 24px

        .progress-bar
          background-color: rgba(#d7e0ef, 0.5)
          border-radius: rem(6px)
          height: rem(12px)
          margin-bottom: rem(4px)

          .progress
            background-color: var(--primary-color)
            border-radius: rem(6px)
            height: rem(12px)

            &.w-0
              width: 0px
            &.w-33
              width: 33%
            &.w-50
              width: 50%
            &.w-66
              width: 66%
            &.w-100
              width: 100%

        .hint
          color: var(--text-secondary)
          font-size: rem(12px)
          line-height: rem(14px)

          + .hint
            margin-top: 4px

        .b-gallery
          margin-top: rem(24px)

        .camera
          margin-bottom: rem(40px)

          .display
            +progress_loader
            border-radius: rem(12px)
            border: 1px solid var(--secondary-color)
            box-sizing: border-box
            height: 480px
            margin-bottom: rem(24px)
            width: 640px

            video
              border-radius: rem(12px)
              +margin-left_ltr(-1px)
              margin-top: -1px
              position: relative
              z-index: 1

          .webcam-actions
            .btn-webcam_snapshot
              +button_blue
              +margin-right_ltr(rem(30px))

            .btn-webcam_cancel
              +button_frame_red
              padding: 0 rem(20px)

      &.additional
        .icon
          background-image: url('../images/pages/photos/icon-info-red.svg')
          background-repeat: no-repeat
          background-size: contain
          height: rem(56px)
          margin: 0 auto rem(24px)
          width: rem(56px)

        .title
          font-size: rem(20px)
          font-weight: 700
          line-height: rem(24px)
          margin-bottom: rem(24px)
          text-align: center

        ul
          li
            color: var(--text-base)
            font-size: rem(14px)
            line-height: 1.3
            +padding-left_ltr(rem(20px))
            position: relative

            &:not(:last-child)
              margin-bottom: rem(22px)

            &:before
              background-color: var(--primary-color)
              border-radius: rem(4px)
              content: ''
              height: rem(8px)
              +left_ltr(0)
              position: absolute
              top: rem(5px)
              width: rem(8px)
