article.how_to_rent_box
  +container

  .subheadline
    +gte_laptop
      max-width: 720px

  .process
    position: relative

    &:before
      background: var(--secondary-color)
      content: ''
      position: absolute
      z-index: -1

      +lte_ipad
        height: 80%
        +left_ltr(rem(64px))
        top: rem(20px)
        width: rem(28px)

      +gte_laptop
        height: 16px
        +left_ltr(20px)
        top: 98px
        width: 80%

    +gte_laptop
      display: flex
      justify-content: space-between

    .step-1,
    .step-2,
    .step-3,
    .step-4
      display: flex

      +lte_ipad
        font-size: 0.75rem
        margin-bottom: rem(48px)
        +margin-left_ltr(rem(36px))

        p
          display: flex
          align-items: center

      +gte_laptop
        flex-direction: column
        margin-top: 55px

      &:before
        background-repeat: no-repeat
        background-size: contain
        box-shadow: 0px 4px 15px rgba(244, 79, 12, 0.35)
        content: ''
        flex-shrink: 0
        overflow: hidden

        +lte_ipad
          border-radius: rem(84px)
          height: rem(84px)
          +margin-right_ltr(rem(24px))
          width: rem(84px)

        +gte_laptop
          border-radius: 100px
          height: 100px
          margin-bottom: 36px
          width: 100px

      &.step-1:before
        background-image: url('../images/articles/how_to_rent_box/step_1.svg')
      &.step-2:before
        background-image: url('../images/articles/how_to_rent_box/step_2.svg')
      &.step-3:before
        background-image: url('../images/articles/how_to_rent_box/step_3.svg')
      &.step-4:before
        background-image: url('../images/articles/how_to_rent_box/step_4.svg')

      p
        +laptop-new
          max-width: scale-laptop(140px, 160px)

        +gte_desktop-new
          max-width: 160px

    .summary
      display: flex
      flex-direction: column
      background: var(--secondary-color)
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15)
      border-radius: rem(20px)
      align-items: center

      +lte_ipad
        padding: rem(36px) rem(17px)
        margin-top: rem(69px)

      +gte_laptop
        padding-top: 24px
        padding-bottom: 24px

      +laptop-new
        +padding-left_ltr(scale-laptop(24px, 46px))
        +padding-right_ltr(scale-laptop(24px, 46px))
        +margin-left_ltr(scale-laptop(10px, 20px))
        width: scale-laptop(308px, 352px)

      +gte_desktop-new
        +padding-left_ltr(46px)
        +padding-right_ltr(46px)
        +margin-left_ltr(20px)
        width: 352px

      p
        color: #fff
        text-align: center
        margin-bottom: rem(32px)

      button
        &.see-more
          +button_red

          +lte_ipad
            margin-bottom: rem(16px)

          +gte_laptop
            margin-bottom: 12px

          &:before
            background-image: url('../images/articles/how_to_rent_box/navigation.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(32px)
            +margin-right_ltr(rem(17px))
            width: rem(32px)

        &.show-more
          +button_frame_white
          +button_frame-arrow('white')
          font-weight: normal

        &.see-more,
        &.show-more
          border-radius: rem(10px)
          font-size: rem(12px)
          height: rem(52px)
          line-height: 1.2
          width: rem(224px)

  .popup
    display: none

  .popup.is-active
    +height-100vh
    display: block
    +left_ltr(0)
    position: fixed
    top: 0
    width: 100%
    z-index: 100

    // Временно заменил бэкграунд embeded видео из ютуба
    background: #000
    //&:before
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%)
      content: ''
      height: 100%
      +left_ltr(0)
      pointer-events: none
      position: absolute
      top: 0
      width: 100%
      z-index: 2

    iframe
      z-index: 101
      display: flex
      margin-top: rem(70px)

    // Временно заменил бэкграунд embeded видео с vimeo.
    //
    // generate images from originals
    // cp app/packs/images/articles/how_to_rent_box/popup/original/* app/packs/images/articles/how_to_rent_box/popup/; for image in app/packs/images/articles/how_to_rent_box/popup/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
    //&:after
      background-repeat: no-repeat
      background-position: 50%
      content: ''
      height: 100%
      +left_ltr(0)
      pointer-events: none
      position: absolute
      top: 0
      width: 100%
      z-index: 1
      @media screen and (min-width: 768px) and (max-width: 1439px)
        background-size: cover
      +lte_ipad
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-mobile.png')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-mobile@2x.png')
      +laptop-new
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-laptop.png')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-laptop@2x.png')
      +gte_desktop-new
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-hd.png')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-hd@2x.png')

    //.webp &:after
      +lte_ipad
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-mobile.webp')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-mobile@2x.webp')
      +laptop-new
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-laptop.webp')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-laptop@2x.webp')
      +gte_desktop-new
        background-image: url('../images/articles/how_to_rent_box/popup/popup_background-hd.webp')
        +retina_media
          background-image: url('../images/articles/how_to_rent_box/popup/popup_background-hd@2x.webp')

    .container
      display: flex
      position: relative
      z-index: 102

      +lte_ipad
        flex-direction: column
        justify-content: flex-end

      +gte_laptop
        align-items: flex-end
        justify-content: center
        padding: 32px

    .pick-box
      +button_red

      +lte_ipad
        margin-bottom: rem(16px)

      +gte_laptop
        +margin-right_ltr(30px)

    .learn-more
      +button_frame_white

    .pick-box,
    .learn-more
      +gte_laptop
        font-size: 14px
        font-weight: bold
        height: 50px
        line-height: 1.2
        width: 255px

    .play
      +button_red
      +button_round(rem(94px))
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
      z-index: 4

      &:before
        background-image: url('../images/articles/how_to_rent_box/popup/play.svg')
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(39px)
        +margin-left_ltr(rem(4px))
        width: rem(31px)

    .close
      +button_frame_white
      +button_round
      position: absolute
      z-index: 4

      +lte_ipad
        +right_ltr(rem(16px))
        top: rem(16px)

      +gte_laptop
        +right_ltr(32px)
        top: 32px

      &:before
        background-image: url('../images/articles/how_to_rent_box/popup/close.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(16px)
        width: rem(17px)
