.p-info_pages-index
  .b-tags
    margin-top: rem(16px)

  .articles
    column-gap: rem(36px)
    display: grid
    flex-wrap: wrap
    margin-bottom: rem(56px)
    margin-top: rem(36px)
    row-gap: rem(30px)

    +iphone
      grid-template-columns: 1fr

    +ipad
      grid-template-columns: 1fr 1fr

    +gte_laptop
      grid-template-columns: 1fr 1fr 1fr

  // Стили для первью начала статьи.
  .custom-content
    p
      margin-bottom: 0

    ul
      margin-bottom: rem(10px)
      margin-top: rem(10px)

      li
        margin-bottom: 0

  .article
    +gte_laptop
      &:hover
        text-decoration: none

        .headline
          color: var(--primary-color-hover)

    &:active
      .headline
        color: var(--primary-color-active)

    .image
      display: flex
      justify-content: center
      margin-bottom: rem(24px)
      position: relative

      .tags
        bottom: rem(12px)
        display: flex
        gap: rem(4px)
        +left_ltr(rem(12px))
        overflow: hidden
        position: absolute
        width: calc(100% - #{rem(24px)})

        .tag
          background: #e6ecf7
          border-radius: rem(20px)
          color: var(--text-base)
          flex-shrink: 0
          font-size: rem(12px)
          line-height: 1.3
          padding: rem(4px) rem(8px)

      img
        border-radius: rem(8px)
        width: 100%
        height: rem(176px)
        object-fit: cover

    .headline
      color: var(--text-strong)
      font-weight: 700

      +lte_ipad
        font-size: rem(18px)
        line-height: rem(22px)
        margin-bottom: rem(8px)

      +gte_laptop
        font-size: 20px
        line-height: 24px
        margin-bottom: 12px

    .headline-text
      color: var(--text-base)
      font-size: rem(14px)
      line-height: 1.3

  .pagination
    display: flex
    justify-content: center
    flex-wrap: wrap

    +lte_ipad
      column-gap: rem(8px)
      row-gap: rem(4px)

    +gte_laptop
      gap: 12px

    .page-item
      .page-link
        align-items: center
        display: flex
        justify-content: center
        line-height: 1.2

        +lte_ipad
          font-size: rem(18px)
          height: rem(30px)
          width: rem(30px)

        +gte_laptop
          font-size: 24px
          height: 36px
          width: 36px

      &.active .page-link
        cursor: default

      &:not(.active) a.page-link
        +link(var(--text-strong))

      a.page-link
        +gte_laptop
          &:hover
            text-decoration: none !important

      &.active
        .page-link
          background: #5096ff
          border-radius: 100%
          color: #fff
