=link_color($color: var(--link-color))
  @if $color != ''
    color: $color

  +gte_laptop
    &:hover:not(:active)
      color: var(--link-color-hover)

  &:active
    color: var(--link-color-active)

=link($color: '')
  +link_color($color)
  cursor: pointer
  text-decoration: var(--link-decoration)

  +gte_laptop
    &:hover
      text-decoration: var(--link-decoration-hover)

  &:active
    text-decoration: var(--link-decoration-active)

=link_underline($color: var(--link-color))
  +link_color($color)
  cursor: pointer
  text-decoration: underline

  &:hover:not(:active)
    text-decoration: none

=link_white
  color: #fff
  cursor: pointer

  +lte_ipad
    &:active
      color: #fff
      text-decoration: underline

  +gte_laptop
    &:hover:not(:active)
      color: #fff
      text-decoration: underline
