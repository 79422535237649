=laptop_image_scale($width)
  +laptop_new
    width: scale-laptop($width * math.div(1024, 1200), $width)

.p-pages-dostavka article.slide-3
  h2
    +lte_ipad
      margin-bottom: rem(16px)

    +gte_laptop
      margin-bottom: 56px

  .item
    color: var(--text-strong)
    line-height: 1.4
    position: relative

    +lte_ipad
      text-align: center

    .text
      font-size: rem(16px)

    img
      display: block

    &-1
      +lte_ipad
        display: flex
        flex-direction: column
        justify-content: center
        margin-bottom: rem(64px)

      +gte_laptop
        display: flex
        margin-bottom: 52px

      .content
        +gte_laptop
          +margin-right_ltr(auto)
          padding-top: 62px

      .headline
        font-weight: 700
        line-height: 1.4

        +lte_ipad
          font-size: rem(16px)
          margin-bottom: rem(8px)

        +gte_laptop
          font-size: 24px
          margin-bottom: 12px

      .text
        +lte_ipad
          margin-bottom: rem(21px)

        +gte_laptop
          max-width: 388px

      img
        +laptop_image_scale(541px)

    &-2
      +lte_ipad
        margin-bottom: rem(37px)
        padding: 0

      +gte_laptop
        margin-bottom: 52px

      .content
        background: #fff
        border-radius: 44px

        +lte_ipad
          margin: rem(-64px) auto 0
          padding: rem(28px)
          position: relative
          width: calc(100% - var(--container-padding-x) * 2)
          z-index: 1

        +gte_laptop
          padding-bottom: rem(48px)
          +padding-left_ltr(rem(65px))
          +padding-right_ltr(rem(73px))
          padding-top: rem(48px)
          position: absolute
          +right_ltr(0)
          top: 50%
          transform: translateY(-50%)

      .text
        +gte_laptop
          &:first-child
            max-width: 348px

          &:last-child
            max-width: 288px

        &:first-child:after
          background-image: url('../images/pages/dostavka/slide_3/truck.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          display: block

          +lte_ipad
            height: rem(44px)
            margin: rem(15px) auto
            width: rem(57px)

          +gte_laptop
            height: 52px
            margin: 22px 0
            width: 68px

      img
        +laptop_image_scale(920px)

    &-3
      +gte_laptop
        display: flex

      .content
        +lte_ipad
          margin-bottom: rem(44px)

        +gte_laptop
          +margin-right_ltr(auto)
          padding-top: 84px

      .headline
        font-weight: 700

        +lte_ipad
          font-size: rem(24px)
          margin-bottom: rem(16px)

        +gte_laptop
          font-size: 32px
          margin-bottom: 24px

      .submit-container
        .submit
          +button_red
          color: #fff
          height: rem(44px)
          width: rem(225px)

          +lte_ipad
            margin: 0 auto

      img
        +laptop_image_scale(541px)
