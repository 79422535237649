.p-boxes.p-boxes-show
  h2
    text-align: center

  h3
    font-size: rem(24px)
    font-weight: 700
    line-height: rem(28px)
    margin-bottom: rem(16px)

    +lte_ipad
      font-size: rem(16px)

  .header.slide_1
    position: relative
    text-align: center

    +lte_ipad
      padding: 0 0 rem(22px)

    +gte_laptop
      padding: 6px 0 56px

    h1
      align-items: center
      display: flex
      justify-content: center

      +lte_ipad
        padding-bottom: rem(6px)

      +gte_laptop
        padding-bottom: 14px

      .discount
        background-color: var(--secondary-color)
        border-radius: rem(10px)
        color: #fff
        font-size: rem(16px)
        font-weight: 900
        +margin-left_ltr(rem(16px))
        padding: rem(10px)
        vertical-align: top

        +lte_ipad
          padding: rem(6px) rem(10px)

    .address
      font-size: rem(14px)
      color: var(--text-base)
      padding-bottom: rem(24px)

      +gte_laptop
        display: none

    .price
      color: var(--primary-color)
      font-size: rem(24px)

      .amount
        display: inline-block
        font-weight: 600
        +margin-left_ltr(rem(3px))

    .full-price
      color: var(--text-base)
      margin-bottom: rem(8px)
      text-decoration: line-through

  article.slide_2
    padding-top: 0

    // Класс для задания имени изображения для разметки https://schema.org/ImageObject
    // (важно для СЕО, но не должно отображаться пользователям).
    .item-prop-image-name
      display: none

    .photos
      position: relative

      +gte_laptop
        display: inline-block
        +margin-right_ltr(30px)

      .rented-overlay
        background-color: rgba(#333, 0.5)
        position: absolute
        z-index: 3
        border-radius: rem(20px)

        +lte_ipad
          height: rem(200px)
          width: 100%

        +gte_laptop
          height: 508px
          width: 444px

        &:before
          background-image: url('../images/pages/boxes/slide_2/sad_smile.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          position: absolute

          +lte_ipad
            height: rem(80px)
            +left_ltr(calc(50% - #{rem(40px)}))
            top: rem(60px)
            width: rem(80px)

          +gte_laptop
            height: 160px
            +left_ltr(142px)
            top: 174px
            width: 160px

      .photos-counter
        background: var(--text-strong)
        border-radius: rem(8px)
        bottom: rem(16px)
        box-sizing: border-box
        color: #fff
        font-size: rem(14px)
        height: rem(40px)
        line-height: rem(20px)
        min-width: rem(100px)
        opacity: 0.9
        padding-bottom: rem(10px)
        +padding-left_ltr(rem(42px))
        +padding-right_ltr(rem(16px))
        padding-top: rem(10px)
        position: absolute
        +right_ltr(rem(16px))
        +text-align_ltr(right)
        z-index: 2

        &:before
          background-image: url('../images/pages/boxes/slide_2/icon-photos-counter.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(16px)
          position: absolute
          top: rem(12px)
          +left_ltr(rem(16px))
          width: rem(16px)

        +lte_ipad
          display: none

      .b-photos
        border-radius: rem(20px)

        +lte_ipad
          margin: 0 auto rem(24px)
          height: rem(200px)
          width: 100%

        +gte_laptop
          height: 508px
          width: 444px

      .no-photos-placeholder
        align-items: center
        background: #f5f5f5
        border-radius: rem(20px)
        color: #567
        display: flex
        justify-content: center

        +lte_ipad
          margin: 0 auto rem(24px)
          height: rem(200px)
          width: 100%

        +gte_laptop
          height: 508px
          width: 444px

    .details
      vertical-align: top

      +gte_laptop
        display: inline-block

      .tags
        margin-bottom: rem(16px)

        +gte_laptop
          max-width: 520px

        .tag
          background-color: #eff3fa
          border-radius: rem(30px)
          display: inline-block
          margin-bottom: rem(8px)
          +margin-right_ltr(rem(12px))

          +lte_ipad
            font-size: rem(10px)
            padding: rem(6px) rem(16px)

          +gte_laptop
            font-size: 14px
            padding: 12px 20px

      .short-links
        height: rem(16px)
        margin-bottom: rem(44px)

        +lte_ipad
          display: none

        a
          color: var(--primary-color)
          font-size: rem(12px)
          +margin-right_ltr(rem(24px))
          text-decoration: underline

          &:hover
            text-decoration: none

      .attrs
        display: inline-block
        vertical-align: top

        +lte_ipad
          width: 100%
          +margin-right_ltr(0)

        +gte_laptop
          +margin-right_ltr(30px)
          width: 350px

        a.rent
          +button_red
          min-width: rem(240px) !important
          // TODO: Add the +small_text as mixin to button_new.sass
          font-size: rem(12px) !important

          +lte_ipad
            margin-top: rem(24px)

          +gte_laptop
            margin-top: 10px

      .blue-block
        background-color: #5096ff
        border-radius: rem(12px)
        box-sizing: border-box
        color: #fff
        display: inline-block
        font-size: rem(12px)
        line-height: rem(16px)
        padding: rem(32px)
        text-align: center
        vertical-align: top

        +lte_ipad
          width: 100%
          margin-top: rem(48px)

        +gte_laptop
          width: 250px

        .title
          font-size: rem(16px)
          font-weight: 700
          margin-bottom: rem(12px)

        a.guest-request,
        a.lead
          +button_white
          margin-top: rem(24px)
          // TODO: Add the +small_text as mixin to button_new.sass
          font-size: rem(12px) !important

  // NOTE: Disable promo article for now.
  // article.slide_3
  //   position: relative
  //   +lte_ipad
  //     padding: 0
  //     margin-bottom: 100px
  //   .container
  //     +gte_laptop
  //       &:before
  //         background-image: url('../images/pages/boxes/slide_3/background.svg')
  //         background-repeat: no-repeat
  //         background-size: contain
  //         content: ''
  //         height: 64px
  //         position: absolute
  //         top: -100px
  //         +left_ltr(140px)
  //         width: 88px
  //   h2
  //     +lte_ipad
  //       display: none
  //   .promo
  //     background-color: #64b5f6
  //     border-radius: 30px
  //     box-sizing: border-box
  //     margin: 0 auto
  //     max-width: 840px
  //     padding: 50px 0 50px 60px
  //     text-align: left
  //     +lte_ipad
  //       padding: 28px 20px 28px 8px
  //     .body
  //       background-color: var(--primary-color)
  //       border-radius: 20px
  //       color: #fff
  //       display: inline-block
  //       max-width: 570px
  //       padding: 28px 90px 28px 64px
  //       +lte_ipad
  //         padding: 14px 10px 20px 18px
  //       .title
  //         font-size: 28px
  //         font-weight: 700
  //         margin-bottom: 12px
  //         +lte_ipad
  //           font-size: 16px
  //       .description
  //         font-size: 16px
  //         line-height: 20px
  //         +lte_ipad
  //           font-size: 12px
  //           line-height: 14px

  article.slide_5
    position: relative

    +lte_ipad
      padding: 0
      margin-bottom: 100px

    .body
      position: relative

      +gte_laptop
        +padding-left_ltr(380px)
        min-height: 316px

      &.simple_locks
        &:before
          background-image: url('../images/pages/boxes/slide_5/simple_lock.jpg')
          background-repeat: no-repeat
          content: ''
          +left_ltr(0)
          top: 0
          position: absolute
          width: 350px

          +lte_ipad
            background-position: center
            background-size: cover
            height: rem(172px)
            margin: 0 rem(-18px)
            top: 0
            width: calc(100% + 2.250rem)

          +gte_laptop
            border-radius: 12px
            height: 316px
            +left_ltr(0)
            top: 0

        .note
          &:before
            background-image: url('../images/pages/boxes/slide_5/simple_icon.svg')

      &.electric_locks
        &:before
          background-image: url('../images/pages/boxes/slide_5/electric_lock.jpg')
          background-repeat: no-repeat
          content: ''
          position: absolute

          +lte_ipad
            background-position: center
            background-size: cover
            height: rem(172px)
            margin: 0 rem(-18px)
            top: 0
            width: calc(100% + #{rem(34px)})

          +gte_laptop
            border-radius: 12px
            height: 316px
            +left_ltr(0)
            top: 0
            width: 350px

        .note
          &:before
            background-image: url('../images/pages/boxes/slide_5/electric_icon.svg')

      h3
        font-size: rem(20px)
        font-weight: 700
        line-height: rem(24px)
        padding: rem(12px) 0 rem(26px)
        margin: 0

        +lte_ipad
          font-size: rem(16px)
          line-height: 20px
          padding: rem(192px) 0 rem(12px)

      .note
        background-color: var(--primary-color)
        border-radius: rem(12px)
        color: #fff
        font-size: rem(20px)
        line-height: rem(24px)

        +gte_laptop
          position: absolute
          bottom: 0
          padding-bottom: 28px
          +padding-left_ltr(120px)
          +padding-right_ltr(42px)
          padding-top: 28px
          min-height: rem(104px)
          display: flex
          align-items: center

          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 56px
            position: absolute
            top: 24px
            +left_ltr(32px)
            width: 56px

        +lte_ipad
          font-size: rem(14px)
          line-height: rem(18px)
          margin-top: rem(32px)
          min-height: rem(80px)
          padding-bottom: rem(16px)
          +padding-left_ltr(rem(76px))
          +padding-right_ltr(rem(16px))
          padding-top: rem(16px)
          position: relative

          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(44px)
            position: absolute
            top: rem(16px)
            +left_ltr(rem(16px))
            width: rem(44px)

  article.slide_9
    position: relative

    +lte_ipad
      padding: 0
      margin-bottom: rem(100px)

    a.signup
      color: var(--primary-color)

    .process
      position: relative
      width: 100%

      &:before
        background: var(--secondary-color)
        content: ''
        position: absolute
        z-index: -1

        +lte_ipad
          height: 80%
          +left_ltr(rem(64px))
          top: rem(20px)
          width: rem(28px)

        +gte_laptop
          height: 16px
          +left_ltr(20px)
          top: 98px
          width: 80%

      +gte_laptop
        display: flex
        justify-content: space-between

      .step-1,
      .step-2,
      .step-3
        display: flex

        +lte_ipad
          margin-bottom: rem(48px)
          +margin-left_ltr(rem(36px))

        +gte_laptop
          flex-direction: column
          margin-top: 55px

        &:before
          background-repeat: no-repeat
          background-size: contain
          box-shadow: 0px 4px 15px rgba(244, 79, 12, 0.35)
          content: ''
          flex-shrink: 0
          overflow: hidden
          background-position: center

          +lte_ipad
            border-radius: rem(84px)
            height: rem(84px)
            +margin-right_ltr(rem(24px))
            width: rem(84px)

          +gte_laptop
            border-radius: 50px
            height: 100px
            margin-bottom: 36px
            width: 100px

        &.step-1:before
          background-image: url('../images/pages/boxes/slide_9/step_1.svg')
        &.step-2:before
          background-image: url('../images/pages/boxes/slide_9/step_2.svg')
        &.step-3:before
          background-image: url('../images/pages/boxes/slide_9/step_3.svg')

        p
          +laptop-new
            max-width: scale-laptop(140px, 160px)

          +gte_desktop-new
            max-width: 160px

      .summary
        align-items: center
        background: var(--secondary-color)
        border-radius: rem(20px)
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15)
        box-sizing: border-box
        display: flex
        flex-direction: column

        +lte_ipad
          padding: rem(36px) rem(17px)
          margin-top: 69px

        +gte_laptop
          padding: 32px 18px

        +laptop-new
          +padding-left_ltr(scale-laptop(24px, 46px))
          +padding-right_ltr(scale-laptop(24px, 46px))
          +margin-left_ltr(scale-laptop(10px, 20px))
          width: scale-laptop(308px, 352px)

        +gte_desktop-new
          +margin-left_ltr(20px)
          width: 352px

        .percent,
        .mobile-phone
          background-color: #fff
          border-radius: rem(60px)
          color: var(--secondary-color)
          font-weight: 700
          text-align: center
          margin-bottom: rem(16px)

          +lte_ipad
            font-size: rem(28px)
            height: rem(80px)
            line-height: rem(80px)
            width: rem(80px)

          +gte_laptop
            font-size: 36px
            height: 112px
            line-height: 112px
            width: 112px

        .mobile-phone
          align-items: center
          display: flex
          justify-content: center

          &:before
            background-image: url('../images/pages/boxes/slide_9/mobile_phone.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 66px
            width: 48px

        p
          color: #fff
          text-align: center

          +lte_ipad
            font-size: rem(16px)
            line-height: rem(20px)

          +gte_laptop
            font-size: 24px
            line-height: 28px

          &.hint
            font-size: rem(12px)
            line-height: rem(16px)
            margin-top: rem(16px)

    .selfstorage-outer
      justify-content: center

      +gte_laptop
        display: flex

    a.find-box
      +button_red

  article.slide_12
    position: relative
    text-align: center

    +lte_ipad
      padding: 0
      margin-bottom: rem(100px)

    h2
      +lte_ipad
        margin-bottom: rem(24px)

    .b-fullwidth_map
      box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)

      +lte_ipad
        height: rem(238px)

      +gte_laptop
        height: 600px

    .path_to_storage
      +lte_ipad
        margin-bottom: rem(36px)

      +gte_laptop
        margin-bottom: 56px

  .auto-description
    padding-top: rem(40px)
