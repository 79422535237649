.custom-content,
.toastui-editor-contents,
.ProseMirror
  --p-offset: #{rem4(14px)}
  --li-offset: #{rem4(12px)}

  +lte_ipad
    --list-offset: #{rem4(42px)}
    // v1 sizes of h2
    --h2-font-size: #{rem4(20px)}
    --h2-font-weight: var(--headline-font-weight)
    --h2-line-height: #{rem4(24px)}
    --headline-margin: #{rem4(28px)}

  +gte_laptop
    --list-offset: var(--headline-margin)
    // v1 sizes of h2
    --h2-font-size: 32px
    --h2-font-weight: var(--headline-font-weight)
    --h2-line-height: 36px
    --headline-margin: 28px

  & > p:last-child,
  & > ul:last-child,
  & > ol:last-child
    margin-bottom: 0

  h2
    +lte_ipad
      font-size: rem4(24px)
      margin-bottom: var(--p-offset)

  h3, h4
    margin: 0
    font-size: unset

  img
    border-radius: rem(32px)
    margin-bottom: calc(var(--p-offset) * 2)
    max-height: rem(730px)
    object-fit: cover

  a
    +link(var(--primary-color))
    text-decoration: none

  ul,
  ol
    +text_font
    margin-bottom: var(--list-offset)

    p + ul,
    p + ol
      margin-top: calc(-1 * var(--p-offset) / 3)

  ul > li > ol:last-child,
  ol > li > ul:last-child
    margin-bottom: 0

  ol
    +lte_ipad
      +margin-left_ltr(rem4(18px))

    +gte_laptop
      +margin-left_ltr(21px)

    &> li
      list-style: decimal
      margin-bottom: var(--li-offset)

  ul
    list-style: disc
    margin-top: var(--li-offset)
    margin-left: rem4(18px)

    & > li
      margin-bottom: var(--li-offset)
      position: relative

      &:last-child
        margin-bottom: 0

    &.forbidden
      li:before
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjEwLjg5OTUiIHkxPSIxLjA2MDY2IiB4Mj0iMi4wNjA3IiB5Mj0iOS44OTk1IiBzdHJva2U9IiNGMDRFMjMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPGxpbmUgeDE9IjEwLjgzODgiIHkxPSIxMCIgeDI9IjEuOTk5OTIiIHkyPSIxLjE2MTE3IiBzdHJva2U9IiNGMDRFMjMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')

  ul + p,
  ol + p
    margin-top: calc(var(--list-offset) - var(--p-offset))

  p + ul,
  p + ol
    margin-top: calc(var(--p-offset) + #{rem4(2px)})

  p,
  li
    &:not(:last-child)
      margin-bottom: var(--p-offset)

  p + h1,
  p + h2
    margin-top: var(--headline-margin)

  span.red
    font-weight: 600

  .table-horizontal-fixer
    overflow-x: auto

  table
    +paragraph
    margin-bottom: var(--list-offset)
    text-align: center
    width: 100%

    &.text-align-left
      +text-align_ltr(left)

    &.red
      th,
      td
        border-bottom-color: var(--primary-color)

      tr
        &:nth-child(even)
          background: transparent

    th
      border-bottom: 2px solid #000
      font-weight: bold
      padding-bottom: 12px
      white-space: nowrap

    td
      border-bottom: 1px solid #ccc
      padding: 30px 0

    tr
      &:nth-child(even)
        background: #eee

    th,
    td
      vertical-align: middle

      +lte_ipad
        padding-left: rem4(8px)
        padding-right: rem4(8px)

  .fc-3
    +lte_ipad
      +fcolumn(1, 45px, 30px)

    +gte_laptop
      +fcolumn(3, 45px, 30px)

  .fc-4
    +lte_ipad
      +fcolumn(1, 45px, 30px)

    +gte_laptop
      +fcolumn(4, 45px, 30px)

  .font-size-30
    font-size: 30px

  .font-size-16
    font-size: 16px

  hr
    border: none !important
    border-bottom: 2px solid #ff5b00 !important

  .gray-note
    background: #f2f2f2
    margin-bottom: 45px

    +lte_ipad
      padding: 24px 20px

    +gte_laptop
      padding: 30px 40px

    .label
      +paragraph($add-margin: true)
      display: flex
      align-items: center
      font-weight: bold

      &:before
        background-image: url('../images/pages/info_pages/info.svg')
        background-repeat: no-repeat
        background-size: cover
        content: ''
        display: inline-block
        height: 24px
        +margin-right_ltr(8px)
        margin-top: -1px
        width: 24px

  .linked-service
    img
      border-radius: 12px
      display: block
      margin: 14px 0
      max-width: 100%

  span.red
    color: var(--primary-color)

  img
    max-width: 100%

  .final-bold-note
    font-weight: bold
    text-align: center
