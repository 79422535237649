.p-orders-v2-registrations .content-column
  .b-phone_area
    +gte_laptop
      align-items: flex-start
      display: flex
      flex-wrap: wrap

      .b-input
        &.phone-code
          +margin-right_ltr(16px)

        &.country_code
          width: 114px

        &.user_profile_phone input
          width: 224px

        &.code-area
          margin-bottom: 0

    .error-message.cant-send
      +gte_laptop
        margin-top: -10px

    .send-confirmation
      font-size: rem(14px)
      font-weight: 700
      line-height: 1.3

      +lte_ipad
        text-align: center
        width: 100%

      +gte_laptop
        margin-top: 15px

      &:not([disabled])
        color: var(--primary-color)
        cursor: pointer

        +gte_laptop
          margin-top: 15px

          &:hover
            color: var(--primary-color-hover)

        &:active
          color: var(--primary-color-active)

      &[disabled]
        color: var(--text-secondary)

    .switch-to-phone
      display: none

  .b-input.user_email
    +gte_laptop
      align-items: center
      column-gap: 16px
      display: flex
      flex-wrap: wrap
      width: auto

      input
        width: 350px

      .hint
        max-width: 168px
        margin: 0

      .error-message
        flex-basis: 100%

  .b-privacy_note
    margin-bottom: rem(16px) !important
