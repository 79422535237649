.b-faq-v4
  background: var(--gray-100)
  cursor: pointer
  transition: background 250ms

  +lte_ipad
    border-radius: rem4(24px)
    padding: rem4(24px)

  +gte_laptop
    border-radius: 24px
    padding: 32px

    &:hover
      background: var(--gray-50)

  &:active
    background: var(--gray-100)

  .title
    +p2(600)
    align-items: center
    display: flex

    +lte_ipad
      gap: var(--spacing-md)

    +gte_laptop
      gap: 16px

    &:after
      background-image: url('../images/blocks/b-faq-v4/plus.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      flex-shrink: 0
      margin-left: auto

      +lte_ipad
        height: rem4(32px)
        width: rem4(32px)

      +gte_laptop
        height: 32px
        width: 32px

  &.is-expanded .title:after
    background-image: url('../images/blocks/b-faq-v4/minus.svg')

  .body
    +p3
    color: var(--gray-500)

    +lte_ipad
      letter-spacing: rem4(0.16px)
      margin-top: rem4(16px)

    +gte_laptop
      letter-spacing: 0.16px
      margin-top: var(--spacing-md)
