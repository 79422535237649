.b-alert
  background: var(--secondary-color)
  border-radius: rem(12px)
  color: #fff
  line-height: 1.3
  margin-bottom: rem(24px)
  padding: rem(24px)

  &.danger
    color: #a00000
    background: #ffcdd2

    // for markdown_to_html
    p
      color: #a00000
      line-height: 1.4
      margin: 0
