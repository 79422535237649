.p-pages-how_to_choose_box
  h1
    +lte_ipad
      font-size: rem(24px)
      line-height: rem(28px)

    +gte_laptop
      font-size: 44px
      line-height: 48px

  .background-cutter
    // have to compensate negative top position of subtitle number
    &.is-subtitle-offset
      +lte_ipad
        margin-top: rem(-8px)
        padding-top: rem(8px)

      +gte_laptop
        margin-top: -12px
        padding-top: 12px

  .subtitle-container
    display: flex
    justify-content: center

  .subtitle
    align-items: center
    background: #e6ecf7
    border-radius: rem(50px)
    color: var(--text-strong)
    display: flex
    font-weight: bold
    justify-content: center
    line-height: 1
    position: relative

    @each $i in 1,2,3,4,5
      &[data-number='#{$i}']:before
        background-image: url('../images/pages/how_to_choose_box/#{$i}.svg')

      &[data-number='1']:before
        +gte_laptop
          height: 108px
          +left_ltr(-32px)
          top: -12px
          width: 68px

      &[data-number='2']:before,
      &[data-number='3']:before
        +gte_laptop
          height: 108px
          +left_ltr(-47px)
          top: -12px
          width: 80px

      &[data-number='4']:before
        +lte_ipad
          top: rem(-5px)
          +left_ltr(rem(-16px))

        +gte_laptop
          height: 108px
          +left_ltr(-48px)
          top: -5px
          width: 74px

      &[data-number='5']
        +gte_laptop
          min-width: 585px

          &:before
            height: 108px
            +left_ltr(-26px)
            top: -12px
            width: 76px

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

      +lte_ipad
        height: rem(72px)
        +left_ltr(rem(-19px))
        top: rem(-8px)
        width: rem(48px)

    +lte_ipad
      +margin-left_ltr(rem(11px))
      font-size: rem(16px)
      height: rem(52px)
      margin-bottom: rem(48px)
      width: rem(258px)

    +gte_laptop
      font-size: 32px
      height: 88px
      margin-bottom: 68px
      width: 388px

  .slide-vertical-outer
    +laptop-new
      max-width: scale-laptop(978px, 1112px)

    +gte_desktop-new
      max-width: 1112px

    +gte_laptop
      +mx-auto

      &:not(:last-child)
        margin-bottom: 40px

  .subtitle-note
    line-height: 1.3

    +lte_ipad
      font-size: rem(14px)

    +gte_laptop
      font-size: 16px
      +mx-auto
      max-width: 638px
      text-align: center

    &:not(:last-child)
      color: var(--text-strong)
      font-weight: bold

      +lte_ipad
        margin-bottom: rem(15px)

      +gte_laptop
        margin-bottom: 29px

    &:last-child
      color: var(--text-base)

      +lte_ipad
        margin-bottom: rem(50px)

      +gte_laptop
        margin-bottom: 87px

    span.red
      color: var(--primary-color)

  .slide-vertical
    background: #ffff
    border-radius: rem(30px)

    +lte_ipad
      border: 2px solid #e6ecf7
      box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.05)
      // added to prevent cutting of bottom box-shadow 35px + 5px = 40px of margin to pagination from the design
      margin-bottom: rem(40px)
      padding: rem(25px) rem(16px)
      position: relative

    +gte_laptop
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15)
      display: flex
      height: 468px
      overflow: hidden

    &.slide-picture-desktop-right
      .content
        +laptop-new
          +padding-left_ltr(scale-laptop(60px, 96px))
          padding-top: scale-laptop(50px, 77px)

        +gte_desktop-new
          +padding-left_ltr(96px)
          padding-top: 77px

        +gte_laptop
          +margin-right_ltr(auto)

    .content
      +gte_laptop
        +margin-right_ltr(auto)
        box-sizing: content-box

      +laptop-new
        +padding-left_ltr(scale-laptop(80px, 96px))
        +padding-right_ltr(scale-laptop(80px, 96px))
        padding-top: 77px
        width: scale-laptop(380px, 420px)

      +gte_desktop-new
        +padding-left_ltr(96px)
        +padding-right_ltr(96px)
        padding-top: 77px
        width: 420px

      &:not(:last-child)
        margin-bottom: 40px

    picture
      border-radius: rem(30px)
      line-height: 0
      overflow: hidden

      +lte_ipad
        bottom: rem(-1px)
        +left_ltr(rem(-0.5px))
        position: absolute
        +right_ltr(rem(-0.5px))

        img
          width: 100%

    .headline
      font-weight: bold
      color: var(--text-strong)

      +lte_ipad
        font-size: rem(20px)
        line-height: rem(24px)
        margin-bottom: rem(12px)

      +gte_laptop
        font-size: 24px
        line-height: 28px
        margin-bottom: 12px
        max-width: 410px

    p
      color: var(--text-base)
      font-size: rem(16px)
      line-height: 1.3

      &:not(:last-child)
        margin-bottom: rem(22px)

      a
        +link_underline

    ul
      +lte_ipad
        text-align: center

      +gte_laptop
        padding-top: 28px

      li
        display: flex

        +lte_ipad
          flex-direction: column

        &.icon:before
          background-repeat: no-repeat
          background-size: contain
          content: ''
          flex-shrink: 0

          +gte_laptop
            +margin-right_ltr(24px)

  .summary-block
    background: var(--primary-color)
    border-radius: rem(12px)
    display: flex
    position: relative

    +lte_ipad
      flex-direction: column
      padding: rem(21px) rem(24px) rem(16px)

    +gte_laptop
      align-items: center
      height: 104px
      +padding-left_ltr(35px)
      +padding-right_ltr(32px)

      &:before
        +rtl_flip
        background-image: url('../images/pages/how_to_choose_box/slide_4/circle_before-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: 104px
        +left_ltr(0)
        pointer-events: none
        position: absolute
        top: 0
        width: 151px

      &:after
        +rtl_flip
        background-image: url('../images/pages/how_to_choose_box/slide_4/circles_after-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        top: 0
        +right_ltr(0)
        pointer-events: none
        position: absolute
        width: 322px
        height: 104px

    .summary-text
      +lte_ipad
        color: #f2f2f2
        font-size: rem(12px)
        line-height: 1.30
        margin-bottom: rem(8px)
        text-align: center

      +gte_laptop
        color: #fff
        font-size: 16px
        line-height: 1.2

      b
        display: block

    .summary-button
      +button_white

      +gte_laptop
        +margin-left_ltr(auto)
        width: 240px
