.p-payments-qr
  h1
    margin-bottom: rem(4px)

  .center
    text-align: center

  .subheadline
    +gte_laptop
      margin-bottom: 48px

  .svg,
  .status-message
    display: flex
    justify-content: center
    margin-bottom: 48px

  .status-message
    align-items: center
    text-align: center
    font-size: 18px
    height: 176px

    &.is-error
      color: #f2642b

    &.is-success
      flex-direction: column
      a
        +link_underline

  .card-payment
    text-align: center

    a.button
      +button_red
      padding-left: rem(16px)
      padding-right: rem(16px)
