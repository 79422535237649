=top_menu_mobile_button($size, $icon)
  align-items: center
  cursor: pointer
  display: flex
  height: rem($size)
  justify-content: center
  width: rem($size)
  -webkit-tap-highlight-color: initial

  &:before
    content: ''
    background-image: url("../images/blocks/b-top_menu/v3/icon-#{$icon}.svg")
    background-repeat: no-repeat
    background-size: cover
    height: rem(20px)
    width: rem(20px)

.b-top_menu.v3
  align-items: center
  display: flex
  flex-shrink: 0

  +lte_ipad
    border-bottom: 1px solid #eaecf0
    box-shadow: 0px rem(1px) rem(2px) 0px rgba(16, 24, 40, 0.06), 0px rem(1px) rem(3px) 0px rgba(16, 24, 40, 0.10)
    height: rem(48px)
    margin-bottom: rem(12px)
    padding: rem(8px) rem(12px)

  +gte_laptop
    border-bottom: 1px solid #e6ecf6
    height: 72px
    margin-bottom: rem(24px)

  +laptop
    padding: 0 scale-laptop(30px, 45px)

  +gte_desktop
    padding: 0 45px

  .logo
    background-image: url(../images/blocks/b-top_menu/v3/logo.svg)
    background-repeat: no-repeat
    background-size: cover
    flex-shrink: 0

    +lte_ipad
      height: rem(32px)
      width: rem(121px)
      -webkit-tap-highlight-color: initial

    +gte_laptop
      +margin-right_ltr(16px)
      height: 40px
      width: 152px

  .b-locale_switcher-v3
    +lte_ipad
      display: none

    +gte_laptop
      +margin-right_ltr(16px)

  .mobile-trigger
    +lte_ipad
      +top_menu_mobile_button(32px, 'menu')
      +margin-left_ltr(auto)

    +gte_laptop
      display: none

  .navigations
    +lte_ipad
      background: #fff
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
      display: none
      flex-direction: column

    +gte_laptop
      display: flex
      flex-grow: 1

  .navigation
    display: flex

    +lte_ipad
      border-bottom: 1px solid var(--border-secondary)
      flex-direction: column
      gap: rem(8px)
      padding: rem(12px)

      &[data-mobile-headline]:before
        color: var(--text-secondary)
        content: attr(data-mobile-headline)
        font-size: rem(12px)
        font-weight: 700
        letter-spacing: rem(0.6px)
        line-height: rem(12px)
        padding: rem(8px) 0
        text-transform: uppercase

    +gte_laptop
      align-items: center
      flex-grow: 1

    &.navigation-profile
      +gte_laptop
        display: none

    &.navigation-auth
      +lte_ipad
        border-bottom: none
        flex-direction: column-reverse
        gap: rem(10px)
        padding: rem(16px) rem(12px)

      +gte_laptop
        +margin-left_ltr(auto)

    .link
      color: var(--text-base)
      cursor: pointer
      font-weight: 700
      white-space: nowrap

      +lte_ipad
        font-size: rem(14px)
        letter-spacing: rem(0.14px)
        line-height: rem(14px)
        padding: rem(8px) 0

      +gte_laptop
        font-size: 16px
        letter-spacing: 0.16px
        line-height: 16px

        &:not(:last-child)
          +margin-right_ltr(32px)

      &:hover
        color: var(--text-base-hover)
        text-decoration: none

      &:active
        color: var(--text-base-active)

      &.phone
        +lte_ipad
          display: none

    .profile,
    .sign-in
      +gte_laptop
        +margin-left_ltr(auto)

    .sign-in
      +button_white
      +button_small_v3
      +button_width_auto
      white-space: nowrap
      +gte_laptop
        +margin-right_ltr(8px)

    .sign-up
      +button_red
      +button_small_v3
      +button_width_auto
      white-space: nowrap

    .profile
      display: flex

      +lte_ipad
        align-items: center
        display: flex
        gap: rem(12px)

      .circle
        +button_red
        +button_width_auto
        border-radius: 100% !important
        font-size: 16px !important
        font-weight: 600
        height: 40px !important
        padding: 0 !important
        width: 40px !important

      .text
        font-size: rem(14px)
        line-height: rem(20px)

        +gte_laptop
          display: none

        .full_name
          color: var(--text-base)
          display: block
          font-weight: 600

        .email
          color: var(--text-secondary)
          content: attr(data-email)
          display: block

      .sign-out
        +top_menu_mobile_button(36px, 'sign_out')
        +margin-left_ltr(auto)

        +gte_laptop
          display: none

  &.is-mobile-active
    +lte_ipad
      border-bottom: none
      box-shadow: none

      .navigations
        // background: #ffffff
        display: flex
        left: 0
        position: fixed
        top: rem(48px)
        width: 100%
        z-index: $z-index-popup

      .mobile-trigger
        &:before
          background-image: url(../images/blocks/b-top_menu/v3/icon-close.svg)
