article.map_storages
  .b-switcher .inner
    +gte_laptop
      min-width: 360px

  .country-content
    width: 100%

  .slides
    .map-outer
      +container_desktop

      +lte_ipad
        margin-bottom: rem(60px)

      +gte_laptop
        margin-bottom: 80px

    .storages
      margin-bottom: 48px

  .b-switcher
    padding-left: var(--container-padding-x)
    padding-right: var(--container-padding-x)

  .selfstorage-outer
    +gte_laptop
      display: flex
      justify-content: center

  .selfstorage
    +button_frame_red
    +button_frame_arrow('red')

  .storage
    background: #fff
    border-radius: 20px
    border: 2px solid #e6ecf7
    box-shadow: 0px 22px 22px -22px rgba(0, 0, 0, 0.15)
    box-sizing: border-box
    color: var(--text-headline)
    display: flex
    line-height: 1.3

    +lte_ipad
      flex-direction: column
      padding: rem(16px)

    +gte_laptop
      padding: 12px

    +gte_laptop
      &:hover
        border-color: var(--primary-color)
        color: var(--text-headline)
        text-decoration: none

    &:active
      background: #eff3fa
      border-color: #e6ecf7
      box-shadow: none

    &:not(:last-child)
      +lte_ipad
        margin-bottom: rem(18px)

      +gte_laptop
        margin-bottom: 16px

    .photo
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      border-radius: rem(10px)
      flex-shrink: 0
      overflow: hidden

      +lte_ipad
        margin-bottom: rem(24px)
        width: rem(256px)
        max-width: 100%

        &-placeholder
          display: none

      +gte_laptop
        +margin-right_ltr(30px)
        width: 160px
        height: 106px

        &-placeholder
          background: #f5f5f5
          height: 106px
          width: 160px

    .name-address
      +gte_laptop
        display: flex
        flex-direction: column
        justify-content: center
        +margin-right_ltr(40px)

      .name
        font-size: rem(16px)
        font-weight: bold

      .address
        font-size: rem(14px)

      .badge-new
        align-items: center
        color: var(--primary-color)
        display: flex
        font-size: rem(10px)

        &:before
          background-image: url('../images/articles/map_storages/star.svg')
          background-position: center
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(14px)
          +margin-right_ltr(rem(5px))
          width: rem(14px)

        +lte_ipad
          margin-top: rem(8px)

        +gte_laptop
          margin-top: 27px

    .details
      +lte_ipad
        margin-top: rem(16px)

      +gte_laptop
        +margin-left_ltr(auto)
        display: flex
        align-items: center
        justify-content: space-between

      .price-boxes
        display: flex
        justify-content: space-between

        +gte_laptop
          align-items: center

    .price,
    .boxes
      font-size: rem(16px)

      +gte_laptop
        width: 160px

      .text
        font-size: rem(16px)
        font-weight: bold

      .label
        +lte_ipad
          font-size: rem(12px)

        +gte_laptop
          font-size: 16px

    .boxes
      +lte_ipad
        +margin-left_ltr(auto)

    .feature
      align-items: center
      display: flex

      +lte_ipad
        font-size: rem(12px)
        margin-top: rem(23px)

      +gte_laptop
        font-size: 14px
        +margin-left_ltr(8px)
        width: 210px

      &:empty
        +lte_ipad
          display: none

      &:before
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        content: ''
        flex-shrink: 0

        +lte_ipad
          height: rem(24px)
          +margin-right_ltr(rem(12px))
          width: rem(24px)

        +gte_laptop
          height: 35px
          +margin-right_ltr(16px)
          width: 35px

      @each $type in huge_boxes, car_box_access, metro_nearby, high_demand, high_boxes, best_offer_small_boxes
        &.#{$type}:before
          background-image: url('../images/articles/map_storages/features/#{$type}.svg')
