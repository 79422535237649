.p-pages-root.p-v3 article.slide_2
  background: #d92d20
  padding-bottom: var(--padding-between-blocks)

  .inner
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem(24px)

    +gte_laptop
      +container_desktop
      gap: 30px

  .head
    display: flex
    flex-direction: column
    flex-shrink: 0

    +lte_ipad
      +container_mobile

    +gte_laptop
      width: 232px

    .above-headline
      color: #fff

    h2
      color: #fff

      span
        color: #fde272

  .swiper-v8
    +lte_ipad
      +container_mobile
      overflow: hidden
      width: 100%

  .slides
    display: flex

    +gte_laptop
      gap: var(--padding-lg)

    .slide-wrapper
      +lte_ipad
        &:not(:last-child)
          +padding_right_ltr(var(--padding-lg))

      +gte_laptop
        flex-basis: 33.333%

    .slide
      background: #fff
      border-radius: rem(20px)
      display: flex
      flex-direction: column
      gap: var(--padding-sm)
      overflow: hidden
      position: relative

      +lte_ipad
        flex-shrink: 0
        height: rem(216px)
        padding: rem(12px)
        width: rem(200px)

      +gte_laptop
        height: 272px
        padding: 20px

      &:before
        background-repeat: no-repeat
        background-size: contain
        bottom: 0
        content: ''
        position: absolute
        right: 0

      &.slide_1:before
        background-image: url('../images/pages/root-v3/slide_2/slide_1.svg')
        height: rem(170px)
        width: rem(176px)

      &.slide_2:before
        background-image: url('../images/pages/root-v3/slide_2/slide_2.svg')
        height: rem(184px)
        width: rem(168px)

      &.slide_3:before
        background-image: url('../images/pages/root-v3/slide_2/slide_3.svg')
        height: rem(164px)
        width: rem(176px)

      .title
        +w1
        +font-cursive
        color: var(--text-strong)
        position: relative
        z-index: 1

      .text
        position: relative
        z-index: 1

        +lte_ipad
          +p4

        +gte_laptop
          +p2
