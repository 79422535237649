.b-button
  +button_red
  padding: 0 rem(12px)

.b-button-frame
  +button_frame_red
  padding: 0 rem(12px)

a.b-button,
a.b-button-frame
  &:hover
    text-decoration: none !important

a.b-button
  &:hover
    color: #fff !important
