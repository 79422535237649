.p-dashboards
  .row
    display: flex
    margin-bottom: rem(32px)

    +lte_ipad
      flex-direction: column

    +gte_laptop
      flex-direction: row
      justify-content: space-between

    &:last-child
      margin-bottom: 0

  .row-grid
    +gte_laptop
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      grid-template-rows: 1fr
      gap: 0px 0px
      grid-template-areas: '. . .'

      .right
        display: flex
        justify-content: flex-end

  .agreement
    .box
      display: flex
      align-items: center

    .details
      display: flex
      flex-direction: column

      +lte_ipad
        align-items: center
        margin: 0 auto

      +gte_laptop
        +margin-right_ltr(56px)

      .number
        color: var(--secondary-color)
        font-size: rem(44px)
        font-weight: 700
        line-height: rem(48px)
        margin-bottom: rem(12px)

      .address
        color: var(--text-base)
        line-height: 1.3

        +lte_ipad
          margin-bottom: rem(16px)
          text-align: center

    .note
      color: var(--text-secondary)
      font-size: rem(14px)
      line-height: 1.3
      max-width: rem(220px)

    .tag
      border-radius: rem(24px)
      color: #fff
      height: rem(48px)
      text-align: center

      +lte_ipad
        line-height: 1.3
        padding: rem(12px)

      +gte_laptop
        font-size: 20px
        line-height: 24px
        padding: 12px 24px

      &.open
        background-color: var(--secondary-color)

      &.closed
        background-color: var(--primary-color)

    .bottom-items
      line-height: rem(24px)
      +padding-left_ltr(rem(36px))
      position: relative

      +lte_ipad
        margin-bottom: rem(18px)
        font-size: rem(14px)

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(24px)
        +left_ltr(0)
        position: absolute
        top: 0
        width: rem(24px)

      &.pin
        .skud-password
          display: inline-block

          b
            background-repeat: no-repeat
            background-position: center right
            +padding-right_ltr(rem(26px))
            cursor: pointer

            &.mask
              color: var(--text-base)
              background-image: url('../images/pages/dashboards/icon-eye-open.svg')

            &.value
              background-image: url('../images/pages/dashboards/icon-eye-closed.svg')

        &:before
          background-image: url('../images/pages/dashboards/icon-pin.svg')

      &.storage-access-time
        &:before
          background-image: url('../images/pages/dashboards/icon-clock.svg')

      &.payment
        &:before
          background-image: url('../images/pages/dashboards/icon-card.svg')

      a
        +link_underline

      .label
        color: var(--text-secondary)
        +margin-right_ltr(rem(6px))

  .agreement + .agreement
    border-top: solid 2px #e6ecf7
    margin-top: rem(24px)
    padding-top: rem(24px)
