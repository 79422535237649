=paragraph($add-margin: false)
  +text_font

  // +lte_ipad
  //   font-size: rem(14px)
  //   line-height: rem(20px)
  // 
  // +gte_laptop
  //   font-size: 18px
  //   line-height: 25px

  @if $add-margin
    &:not(:last-child)
      margin-bottom: rem(20px)
