@use 'sass:math'

=cc
  +clear_left

=column($columns, $column_size: math.div(1, $columns), $gutter: 4%)
  @if $columns == 1
    $gutter: 0%

  box-sizing: border-box
  float: left
  +margin-right_ltr($gutter)
  position: relative
  width: 100% * $column_size - $gutter * math.div($columns - 1, $columns)

  &:nth-child(#{$columns}n)
    +margin-right_ltr(0)

  &:nth-child(#{$columns}n+1)
    clear: left

  &:last-child
    +margin-right_ltr(0)
