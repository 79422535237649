.b-sticky-area
  +root_based_colors

  &.is-visible .footer
    transform: translateY(0)

  .footer
    align-items: center
    backdrop-filter: blur(6px)
    background: rgba(#fff, 0.80)
    border-top: 1px solid #eff3fa
    bottom: 0
    display: flex
    left: 0
    position: fixed
    transform: translateY(100%)
    transition: transform var(--transition-duration) ease-in-out
    width: 100%
    z-index: $z-index-sticky-area

    +lte_ipad
      padding: rem(13px)

    +gte_laptop
      padding: 16px 45px

    .buttons
      display: flex
      gap: rem(24px)

      +lte_ipad
        gap: rem(20px)

      a,
      .icon-expand,
      .icon-assistant
        align-items: center
        display: flex
        height: rem(48px)
        justify-content: center
        width: rem(48px)

        +lte_ipad
          height: rem(40px)
          width: rem(40px)

      .icon-expand,
      .icon-assistant
        border-radius: 100%
        cursor: pointer
        transition: background var(--transition-duration)
        background: var(--color-default)

        +gte_laptop
          &:hover
            background: var(--color-hover)

        &.is-active,
        &:active
          background: var(--color-active)

        &:before
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(28px)
          width: rem(28px)

          +lte_ipad
            height: rem(24px)
            width: rem(24px)

      .icon-expand
        &:before
          background-image: url('../images/blocks/b-sticky-area/expand.svg')

      .phone
        .root-v1 &
          &:before
            background-image: url('../images/blocks/b-sticky-area/v1/phone.svg')
          &:active:before
            background-image: url('../images/blocks/b-sticky-area/v1/phone-active.svg')

          +gte_laptop
            &:hover:before
              background-image: url('../images/blocks/b-sticky-area/v1/phone-hover.svg')

        .root-v3 &
          &:before
            background-image: url('../images/blocks/b-sticky-area/v3/phone.svg')
          &:active:before
            background-image: url('../images/blocks/b-sticky-area/v3/phone-active.svg')

          +gte_laptop
            &:hover:before
              background-image: url('../images/blocks/b-sticky-area/v3/phone-hover.svg')

        &:before
          background-repeat: no-repeat
          background-size: contain
          content: ''

          +lte_ipad
            height: rem(20px)
            width: rem(20px)

          +gte_laptop
            height: 24px
            width: 24px

      .icon-assistant
        &:not(.is-active)
          display: none

        &.is-active
          &:before
            background-image: url('../images/blocks/b-sticky-area/assistant-close.svg')

          & + .icon-expand
            display: none

    .units_and_prices
      .root-v1 &
        +button_blue
      .root-v3 &
        +button_red

      font-size: rem(14px) !important
      height: rem(44px)
      margin-left: auto
      width: rem(138px)

      +lte_ipad
        font-size: rem(12px) !important
        height: rem(36px)
        width: rem(114px)

  .dropdown
    border-radius: rem(16px)
    bottom: rem(94px)
    display: flex
    flex-direction: column
    left: rem(16px)
    opacity: 0
    pointer-events: none
    position: fixed
    transition: opacity var(--transition-duration)
    z-index: -1

    &.is-active
      opacity: 1
      pointer-events: all
      z-index: $z-index-sticky-area

    &.menu
      backdrop-filter: blur(6px)
      background: rgba(#fff, 0.80)
      border: 1px solid #eff3fa
      padding: rem(24px)

      +lte_ipad
        left: rem(12px)
        bottom: rem(78px)
        padding: rem(19px)

      .inner
        align-items: flex-start
        display: flex
        flex-direction: column
        gap: rem(20px)

        +lte_ipad
          gap: rem(16px)

      .link
        color: var(--text-strong)
        cursor: pointer
        display: flex
        font-size: rem(20px)
        gap: rem(12px)
        line-height: rem(28px)

        +lte_ipad
          font-size: rem(14px)
          gap: rem(10px)
          line-height: rem(24px)

        +gte_laptop
          &:hover
            color: var(--text-secondary)
            text-decoration: none

        &:before
          background-repeat: no-repeat
          background-size: contain
          content: ''
          flex-shrink: 0

          +lte_ipad
            height: rem(24px)
            width: rem(24px)

          +gte_laptop
            height: 32px
            width: 32px

        @each $type in whatsapp, telegram, assistant, line
          .root-v1 &.#{$type}
            &:before
              background-image: url('../images/blocks/b-sticky-area/menu/v1/#{$type}.svg')

            +gte_laptop
              &:hover:before
                background-image: url('../images/blocks/b-sticky-area/menu/v1/#{$type}-hover.svg')

            &:active:before
              background-image: url('../images/blocks/b-sticky-area/menu/v1/#{$type}-active.svg')

          .root-v3 &.#{$type}
            &:before
              background-image: url('../images/blocks/b-sticky-area/menu/v3/#{$type}.svg')

            +gte_laptop
              &:hover:before
                background-image: url('../images/blocks/b-sticky-area/menu/v3/#{$type}-hover.svg')

            &:active:before
              background-image: url('../images/blocks/b-sticky-area/menu/v3/#{$type}-active.svg')

    &.assistant
      +lte_ipad
        border-radius: 0

        &.is-active
          background: #fff
          height: 100vh
          left: 0
          top: 0
          width: 100%
          z-index: $z-index-popup

      +gte_laptop
        background: #fff
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)
        // padding: 0 16px 12px
        width: 400px
        height: 500px

      .dropdown-headline
        background: var(--color-main)
        display: flex

        +lte_ipad
          gap: rem(11px)
          padding: rem(6px) rem(16px)

        +gte_laptop
          border-radius: 16px 16px 0px 0px
          gap: 12px
          padding: 12px 16px

        .logo
          align-items: center
          background: #fff
          border-radius: 100%
          display: flex
          justify-content: center

          +lte_ipad
            height: rem(36px)
            width: rem(36px)

          +gte_laptop
            height: 40px
            width: 40px

          &:before
            background-image: url('../images/blocks/b-sticky-area/assistant/logo.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            flex-shrink: 0

            +lte_ipad
              height: rem(18px)
              width: rem(18px)

            +gte_laptop
              height: 20px
              width: 20px

        .texts
          color: #fff
          display: flex
          flex-direction: column
          font-size: rem(14px)
          font-style: normal
          font-weight: 600
          justify-content: center
          letter-spacing: 0.16px
          line-height: rem(16px)

          +lte_ipad
            gap: rem(2px)

          +gte_laptop
            gap: 4px

          div:first-child
            font-weight: 700

            +gte_laptop
              font-size: 16px

      .dropdown-close
        +lte_ipad
          align-items: center
          cursor: pointer
          display: flex
          height: rem(30px)
          justify-content: center
          position: absolute
          position: absolute
          right: rem(10px)
          top: rem(8px)
          width: rem(30px)
          -webkit-tap-highlight-color: initial
          // 20x18

          &:before
            background-image: url('../images/blocks/b-sticky-area/assistant/close.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(20px)
            width: rem(20px)

      #assistant_app
        display: flex
        flex-direction: column
        height: 100%
        overflow: hidden

  .overlay
    height: 100%
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: $z-index-sticky-area - 1
    display: none

    &.is-active
      display: block

  .p-orders &,
  .p-orders-v2 &
    +lte_ipad
      display: none
