.p-pages-root.p-v4 article.slide_10
  +container
  padding-bottom: 0

  +lte_ipad
    --offset: var(--spacing-md)
    --storable-type-height: #{rem4(100px)}
    --storable-type-gap: var(--spacing-md)
    --appended-offset: 0px

  +laptop-new
    --offset: #{scale-laptop(16px, 32px)}

  +gte_laptop
    --storable-type-height: 126px
    --storable-type-gap: 16px
    --appended-offset: 0px

  +gte_desktop-new
    --offset: 32px

  .swiper
    +mx-auto
    display: flex
    flex-direction: column
    height: 100%
    position: relative
    width: 100%

    .swiper-wrapper
      overflow: hidden

    & > .inner
      display: flex
      flex-direction: column
      left: var(--offset)
      position: absolute
      right: var(--offset)
      top: rem4(64px)
      z-index: 2

      // restrict area to the size of image
      +lte_ipad
        margin-left: auto
        margin-right: auto
        max-width: 358px

      header
        h2,
        .note
          color: var(--gray-25)

        .note
          padding-left: var(--container-padding-x)
          padding-right: var(--container-padding-x)

  .swiper-slide
    +lte_ipad
      align-items: center
      display: flex
      flex-direction: column
      gap: var(--spacing-6xl)
      justify-content: center

      .outer
        $border-radius: rem4(32px)
        border-radius: $border-radius
        overflow: hidden

        &:after
          --initial-offset: #{$border-radius}
          --appended-offset: 0px
          // --appended-offset: #{rem4(100px)}
          --additional-offset: 0px
          background: #0a0a0a
          content: ''
          display: block
          height: calc(var(--initial-offset) + calc(var(--appended-offset)) + var(--additional-offset))
          border-radius: 0 0 $border-radius $border-radius
          margin-top: -$border-radius

          // @media screen and (min-width: 410px) and (max-width: 767px)
            // --appended-offset: #{rem4(120px)}

      &.is-expanded
        .show-more
          display: none

        .outer
          &:after
            --additional-offset: calc(var(--storable-type-height) * 3 + var(--storable-type-gap) * 3)

      &:not(.is-expanded)
        .storable-type
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6)
            display: none

    .outer
      position: relative

    .show-more
      +button_v4('primary', 'xl')

      +gte_laptop
        display: none

  picture img
    max-width: 100%

  .tabs-storable-types
    display: flex
    flex-direction: column
    bottom: calc(var(--offset) - var(--appended-offset))
    left: 0
    width: 100%
    // left: var(--offset)
    position: absolute
    // right: var(--offset)
    z-index: 2

    +lte_ipad
      gap: var(--spacing-6xl)

    +gte_laptop
      gap: 72px

  // .b-tabs
    margin-left: calc(-1 * var(--offset))
    margin-right: calc(-1 * var(--offset))

  .storable-types
    gap: var(--storable-type-gap)
    margin-left: var(--offset)
    margin-right: var(--offset)

    +lte_ipad
      display: flex
      flex-direction: column
      padding: rem4(4px)

    +gte_laptop
      display: grid
      grid-template-columns: 1fr 1fr 1fr

  .storable-type
    background: rgba(255, 255, 255, 0.10)
    height: var(--storable-type-height)
    color: #fff

    +lte_ipad
      backdrop-filter: blur(rem4(44px))
      border-radius: rem4(20px)
      padding: rem4(16px)

    +gte_laptop
      backdrop-filter: blur(44px)
      border-radius: 24px
      padding: 16px

    .inner
      height: 100%
      overflow: hidden
      position: relative
      width: 100%

    .default
      display: flex
      flex-direction: column

      +lte_ipad
        gap: rem4(12px)

      +gte_laptop
        gap: 16px

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''

        +lte_ipad
          height: rem4(32px)
          width: rem4(32px)

        +gte_laptop
          height: 32px
          width: 32px

    @each $subtype in season, relocation, vacation, documents, valuables, lichnye_veschi,
      &.personal-#{$subtype} .default:before
        background-image: url('../images/pages/root-v4/slide_10/personal/#{$subtype}.svg')

    @each $subtype in season, office_furniture, goods, equipment, archive, biznes_veshchi
      &.business-#{$subtype} .default:before
        background-image: url('../images/pages/root-v4/slide_10/business/#{$subtype}.svg')

    .title
      +lte_ipad
        +p3(700)

      +gte_laptop
        +p2(700)
        width: 0 // in order to wrap text

        span
          white-space: nowrap

    .hovered
      +lte_ipad
        display: none

    +gte_laptop
      &:hover
        .default
          +ltr
            transform: translateX(100%)

          +rtl
            transform: translateX(-100%)

        .hovered
          +ltr_rtl
            transform: translateX(0)

      .default
        +ltr_rtl
          transform: translateX(0)

      .hovered
        +ltr
          transform: translateX(-100%)

        +rtl
          transform: translateX(100%)

      .default,
      .hovered
        display: flex
        flex-direction: column
        height: 100%
        position: absolute
        transition: transform var(--transition-duration)
        width: 100%

      ul
        font-size: 18px
        font-weight: 700
        line-height: 1.3

      li
        align-items: center
        display: flex

        &:before
          background: #fff
          border-radius: 5px
          content: ''
          height: 5px
          margin: 0 12px
          width: 5px
