.b-note
  background: #f5f6fa
  border-radius: rem(3px)
  flex-basis: 22%

  +lte_ipad
    padding: rem(23px) var(--container-padding-x) rem(24px)

  +gte_laptop
    padding: 34px 30px

  .title
    +lte_laptop
      +mobile_subheadline

      &:not(:first-child)
        font-size: rem(16px)
        margin-bottom: rem(10px)
        margin-top: rem(17px)

    +gte_laptop
      font-size: 28px
      letter-spacing: -0.28px
      line-height: 30px
      margin-bottom: 13px

      &:not(:first-child)
        margin-top: 39px
        font-size: 23px
        margin-bottom: 9px

  p
    color: #8b98a7

    +lte_ipad
      font-size: rem(14px)
      line-height: rem(24px)

    +gte_laptop
      line-height: 1.65
