article.cannot_be_stored
  +container
  $inner-shadow-margin: 14px

  .container.background
    overflow: hidden
    padding: 0
    position: relative

    +gte_laptop
      &:before
        z-index: 1
        content: ''
        position: absolute
        background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
        transform: rotate(-180deg)
        left: -12px // ne need for ltr/rtl here
        top: 0
        width: 100px
        height: 280px

      &:after
        z-index: 1
        content: ''
        position: absolute
        background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
        right: 0 // ne need for ltr/rtl here
        top: 0
        width: 100px
        height: 280px

  .slides
    overflow: hidden

    +laptop-new
      +margin-left_ltr(-$inner-shadow-margin)

    +gte_desktop-new
      +margin-left_ltr(-$inner-shadow-margin + 2px)

    .slides-wrapper
      display: flex

      .slide
        flex-shrink: 0
        width: 160px

        +lte_ipad
          width: 100vw

      .slide-inner
        border-radius: rem(12px) rem(12px) 0 0
        flex-shrink: 0
        overflow: hidden
        margin: 0 rem(14px)

        &:before
          content: ''
          background-position-x: center
          background-size: contain
          background-repeat: no-repeat
          display: block
          width: 100%
          height: rem(200px)

      .slide
        // generate images from originals
        // cp app/packs/images/articles/cannot_be_stored/original/* app/packs/images/articles/cannot_be_stored/; for image in app/packs/images/articles/cannot_be_stored/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
        @each $i in 1,2,3,4,5,6,7,8,9
          &-#{$i} .slide-inner:before
            background-image: url('../images/articles/cannot_be_stored/slide_#{$i}.png')
            +retina_media
              background-image: url('../images/articles/cannot_be_stored/slide_#{$i}@2x.png')

          .webp &-#{$i} .slide-inner:before
            background-image: url('../images/articles/cannot_be_stored/slide_#{$i}.webp')
            +retina_media
              background-image: url('../images/articles/cannot_be_stored/slide_#{$i}@2x.webp')

        .label
          align-items: center
          color: var(--text-base)
          display: flex
          font-size: rem(16px)
          justify-content: center
          line-height: 1.2
          text-align: center
          word-break: break-word

          +lte_ipad
            font-size: rem(20px)
            line-height: rem(24px)
            margin-top: rem(20px)
            margin-bottom: rem(40px)

    .b-slider-pagination
      +lte_ipad
        margin-top: rem(11px)

      +gte_laptop
        display: none

    .slider-navigation
      +lte_ipad
        display: none

      +gte_laptop
        display: flex
        justify-content: center
        margin-top: 10px
        padding-bottom: 10px // because buttons have shadow

      .b-slider-prev
        +button_navigation(false)
        +margin-right_ltr(rem(24px))

      .b-slider-next
        +button_navigation(true)
