article.delivery
  .background
    padding: 0
    position: relative
    max-width: 944px

  .slides
    overflow: hidden

    .slides-wrapper
      display: flex

      .slide
        flex-shrink: 0

        +lte_ipad
          width: 100vw

        +gte_laptop
          width: 944px

      .slide-inner
        flex-shrink: 0
        padding: 0 rem(10px)
        margin: 0
        overflow: hidden
        position: relative

        +gte_laptop
          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 308px
            position: absolute
            +right_ltr(0)
            top: 0
            width: 376px

        +lte_ipad
          margin: 0 rem(16px)
          height: rem(490px)

          &:before
            background-position: center
            background-repeat: no-repeat
            background-size: cover
            border-radius: rem(12px)
            content: ''
            display: block
            height: rem(168px)
            position: absolute
            bottom: 0
            width: calc(100vw - #{rem(52px)})

        .blue-box
          background-color: var(--secondary-color)
          border-radius: rem(12px)
          box-sizing: border-box
          color: #fff
          height: rem(308px)
          padding: rem(44px) rem(32px)

          +lte_ipad
            text-align: center
            width: 100%

          +gte_laptop
            width: 540px

          .title
            font-weight: 600
            margin-bottom: rem(16px)

            +lte_ipad
              font-size: rem(20px)

            +gte_laptop
              font-size: 32px

          .description
            font-size: rem(16px)
            line-height: 1.3

          .button
            +button_red
            position: absolute

            +lte_ipad
              padding: 0 rem(18px)
              bottom: rem(214px)

            +gte_laptop
              padding: 0 22px
              bottom: 32px

      .slide
        // generate images from originals
        // cp app/packs/images/articles/delivery_slider/original/* app/packs/images/articles/delivery_slider/; for image in app/packs/images/articles/delivery_slider/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
        @each $i in 1,2
          &-#{$i} .slide-inner:before
            background-image: url('../images/articles/delivery_slider/slide_#{$i}.png')
            +retina_media
              background-image: url('../images/articles/delivery_slider/slide_#{$i}@2x.png')

          .webp &-#{$i} .slide-inner:before
            background-image: url('../images/articles/delivery_slider/slide_#{$i}.webp')
            +retina_media
              background-image: url('../images/articles/delivery_slider/slide_#{$i}@2x.webp')

    .b-slider-pagination
      justify-content: center

      +lte_ipad
        margin-top: rem(24px)

      +gte_laptop
        margin-top: 56px

    .b-slider-prev
      +left_ltr(rem(-100px))

    .b-slider-next
      +right_ltr(rem(-100px))

    .b-slider-prev,
    .b-slider-next
      top: 50%
      transform: translateY(-50%)
      margin-top: -34px
      position: absolute
      z-index: 1

      +lte_laptop
        display: none
