.p-pages-root.p-v4
  +lte_ipad
    --h1-font-size: #{rem4(48px)}
    --h1-font-weight: 700
    --h1-line-height: 1.2
    --h1-letter-spacing: #{rem4(-1.44px)}

  +gte_laptop
    --h1-font-size: 64px
    --h1-font-weight: 700
    --h1-letter-spacing: -1.92px
    --h1-line-height: 1.2

  article
    &.dark-wrapper
      h2,
      .note
        color: var(--gray-25)

    // отступ в футере
    &:last-child
      margin-bottom: 0 !important
