.p-orders-v2
  h1
    margin-bottom: rem(24px)

    +lte_ipad
      margin-top: rem(4px)
      font-size: rem(30px)

  h3
    +lte_ipad
      font-size: rem(22px)
      line-height: rem(28px)
      margin-bottom: rem(16px)

    +gte_laptop
      margin-bottom: 24px

  h4
    color: var(--text-strong)
    font-size: rem(20px)
    font-weight: 700
    line-height: rem(24px)
    margin-bottom: rem(8px)

  .layout-columns
    +lte_ipad
      padding-left: 0
      padding-right: 0

    +gte_laptop
      display: flex
      column-gap: 30px

    .content-column
      +lte_ipad
        padding-left: var(--container-padding-x)
        padding-right: var(--container-padding-x)

      +gte_laptop
        flex-grow: 1

    .order-column
      +lte_ipad
        padding-top: rem(24px)

  .content-column
    h3
      align-items: baseline
      display: flex

      +lte_ipad
        flex-direction: column
        gap: rem(8px)

      +gte_laptop
        gap: 16px

      .auth-link
        color: var(--text-base)
        font-style: normal
        font-weight: 400
        line-height: 1.3

        +lte_ipad
          font-size: rem(14px)

        +gte_laptop
          font-size: 16px

        a
          +link_underline
          color: inherit

    .b-input input,
    .b-input select,
    .b-input.custom-select,
    input[type='submit']
      +gte_laptop
        width: 350px

    .submit-container
      +lte_ipad
        display: none

      input
        +gte_laptop
          margin: 0

      &.is-mobile-only
        +gte_laptop
          display: none

  .order-column
    +gte_laptop
      flex-basis: 40%
      flex-shrink: 0

  .b-privacy_note,
  .agreement-confirmation
    label
      white-space: normal

    a
      color: var(--text-base)
      text-decoration: underline

      +gte_laptop
        &:hover
          color: var(--primary-color-hover)
          text-decoration: none

      &:active
        color: var(--primary-color-active)
        text-decoration: none

  .additional
    align-items: center
    border-radius: rem(12px)
    border: 2px solid #f1f1f1
    display: flex
    gap: rem(12px)
    padding: rem(16px)

    .circle
      align-items: center
      background: #fde9e1
      border-radius: 100%
      color: var(--primary-color)
      display: flex
      font-size: rem(14px)
      font-weight: 700
      height: rem(56px)
      justify-content: center
      width: rem(56px)

      +lte_ipad
        flex-shrink: 0

    .inner
      +gte_laptop
        &:not(:last-child)
          +margin-right_ltr(auto)

      .label
        align-items: center
        display: flex
        margin-bottom: rem(4px)

      .note
        color: var(--text-base)
        font-size: rem(14px)
        line-height: 1.3

        a
          +link_underline(var(--text-base))

  .button-next
    +button_red

    +lte_ipad
      width: 100%

    +gte_laptop
      width: 350px
