.p-devise
  #container_wrapper
    text-align: center
    margin-bottom: rem(30px)

    .container
      max-width: rem(380px)

      +lte_ipad
        padding: rem(20px)

      +gte_laptop
        padding: 120px 0 0 0

    .title
      color: var(--text-headline)
      font-size: rem(24px)
      font-weight: 700
      line-height: rem(28px)
      margin-bottom: rem(56px)

      &.small-margin
        margin-bottom: rem(16px)

    .title-hint
      color: var(--text-base)
      font-size: rem(14px)
      line-height: rem(18px)
      margin-bottom: rem(56px)

  p.alert
    margin-bottom: rem(20px)

  a.forgot
    margin-bottom: rem(36px)

  abbr[title=required]
    display: none

  .b-input .hint
    text-align: left

  input[type=submit]
    width: 100%
    margin-bottom: rem(24px)
