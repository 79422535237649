.p-payment_methods
  .payment-methods
    display: flex

    +lte_ipad
      flex-direction: column

    +gte_laptop
      flex-direction: row

    .block-container
      flex-basis: rem(100px)
      flex-grow: 1
      flex-shrink: 1

      +gte_laptop
        &:not(:last-child)
          +margin-right_ltr(24px)

      .label
        font-size: rem(16px)
        font-weight: 700
        line-height: rem(19px)
        margin-bottom: rem(16px)

      .body
        color: var(--text-base)
        font-size: rem(14px)
        line-height: 1.3

  .agreement
    grid-column-gap: rem(24px)
    grid-row-gap: rem(16px)
    display: grid
    align-items: center

    +lte_ipad
      grid-template-columns: 100%

    +gte_laptop
      grid-template-columns: 40% auto auto

    .box
      .number
        color: var(--secondary-color)
        font-size: rem(20px)
        font-weight: 700
        line-height: rem(24px)
        margin-bottom: rem(8px)

      .address
        color: var(--text-base)
        font-size: rem(14px)
        line-height: 1.3

    .state
      color: var(--text-secondary)
      font-size: rem(14px)
      line-height: 1.3

    .action
      display: flex
      justify-content: flex-end
      align-items: center

      +lte_ipad
        flex-direction: column

      +gte_laptop
        flex-direction: row

      input,
      form
        margin: 0

        +lte_ipad
          width: 100%

      .remove_card
        +lte_ipad
          +link_underline
          font-size: rem(14px)
          margin-bottom: rem(16px)

        +gte_laptop
          +link
          font-size: 12px
          +margin-right_ltr(24px)

      .card
        background-color: #eff3fa
        border-radius: rem(12px)
        display: flex
        padding: rem(10px) rem(16px)
        align-items: flex-end

        +lte_ipad
          width: 100%

        .icon
          background-color: #fff
          background-position: center
          background-repeat: no-repeat
          background-size: contain
          background-size: initial
          border-radius: rem(4px)
          height: rem(28px)
          width: rem(48px)

          +gte_laptop
            +margin-right_ltr(24px)

          // Поумолчанию показываем как MasterCard, т.к. Tinkoff не возвращает
          // тип карты.
          background-image: url('../images/pages/payment_methods/mastercard.svg')

          &.mastercard
            background-image: url('../images/pages/payment_methods/mastercard.svg')
          &.visa
            background-image: url('../images/pages/payment_methods/visa.svg')
          &.mir
            background-image: url('../images/pages/payment_methods/mir.svg')

        .number
          font-size: rem(24px)
          font-weight: 700
          line-height: rem(28px)
          +margin-right_ltr(rem(24px))

        .expiry
          color: var(--text-secondary)
          font-size: rem(14px)
          line-height: 1.3

  .agreement + .agreement
    border-top: solid 2px #e6ecf7
    margin-top: rem(24px)
    padding-top: rem(24px)
