.b-store_badges
  align-items: center
  display: flex

  +lte_ipad
    flex-direction: column
    gap: rem4(18px)

  +gte_laptop
    gap: 24px

  .google-play,
  .apple-store
    background-repeat: no-repeat
    background-size: contain

    +lte_ipad
      height: rem4(64px)
      width: rem4(216px)

    +gte_laptop
      height: 47px
      width: 161px

  .google-play
    background-image: url('../images/blocks/b-store_badges/google_play_en.svg')

    &.ru
      background-image: url('../images/blocks/b-store_badges/google_play.svg')

  .apple-store
    background-image: url('../images/blocks/b-store_badges/app_store.svg')
