.b-locale_switcher-v3-modal
  h3
    text-align: center

    +lte_ipad
      margin-bottom: 14px

    +gte_laptop
      margin-bottom: 26px

  select
    border-color: #eaeaea
    border-radius: rem(12px)
    height: rem(40px)
    padding: 0 rem(10px)
    width: 100%

  p
    &.center
      color: var(--text-strong)
      margin-bottom: rem(20px)
      text-align: center

    &.privacy
      font-size: rem(14px)
      line-height: rem(18px)

      +gte_laptop
        line-height: 20px
        margin-bottom: 20px

      a
        color: var(--primary-color)

        &:hover
          text-decoration: underline

  .b-form > .b-input
    +gte_laptop
      min-width: 360px

  .phone-area
    display: flex

    +lte_ipad
      gap: rem(8px)

    +gte_laptop
      gap: 12px

    .b-input.phone
      flex-grow: 1

  .button-container
    display: flex
    justify-content: center

    .b-button
      +button_red

      +lte_ipad
        width: 100%

  .countries
    color: var(--text-base)
    +gte_laptop
      min-width: 500px

    h3
      color: #212121
      font-size: rem(24px)
      line-height: rem(28px)
      margin-bottom: rem(32px)
      +text-align_ltr(left)

      +lte_laptop
        font-size: rem(20px)
        font-weight: 400

    .list-item
      border-radius: rem(30px)
      border: 1px solid #e6ecf6
      display: inline-block
      +margin-right_ltr(rem(12px))

      +lte_laptop
        &:not(:last-child)
          margin-bottom: rem(12px)

      &:hover
        box-shadow: 0px 4px 24px 0px rgba(217, 97, 60, 0.25)
        color: var(--primary-color-hover)
        cursor: pointer

      &.selected,
      &:active
        background-color: var(--secondary-color)
        color: #fff

    .country-list
      .country

        +lte_laptop
          padding: rem(12px) rem(20px)

        +gte_desktop
          padding: 20px 30px

        .inner
          display: flex
          align-items: center

          .flag
            background-position: center center
            background-repeat: no-repeat
            background-size: contain
            +margin-right_ltr(rem(12px))

            +lte_laptop
              height: rem(18px)
              width: rem(24px)

            +gte_desktop
              height: 20px
              width: 30px

            &.russia
              background-image: url('../images/blocks/b-world-map/flag_russia.svg')
            &.uae
              background-image: url('../images/blocks/b-world-map/flag_uae.svg')
            &.estonia
              background-image: url('../images/blocks/b-world-map/flag_estonia.svg')

    .delimiter
      background-color: #e6ecf6
      height: 1px

      +lte_laptop
        margin: rem(32px) 0

      +gte_desktop
        margin: 44px 0

    .locale-list
      margin-bottom: rem(44px)

      .locale
        padding: rem(12px) rem(24px)

    .button-container
      display: flex
      justify-content: flex-start

      .b-button + .b-button
        +margin-left_ltr(rem(12px))

      .b-button.secondary
        +button_frame_red
