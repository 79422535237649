.b-yandex_rating
  display: flex
  height: rem(20px)
  justify-content: center
  margin: 0 auto
  position: relative

  +gte_laptop
    max-width: 210px

  .logo
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    background-image: url('../images/blocks/b-yandex_rating/yandex-logo.svg')
    content: ''
    height: rem(20px)
    width: rem(53px)

  .stars
    display: flex
    align-items: center

    &:not(:first-child)
      +margin-left_ltr(rem(10px))

    .star
      align-items: center
      display: inline-flex
      flex-shrink: 0
      height: rem(20px)
      justify-content: center
      +margin-right_ltr(rem(4px))
      position: relative
      width: rem(18px)

      &:before
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(18px)
        width: rem(18px)

      &.gray
        &:before
          background-image: url('../images/blocks/b-yandex_rating/star-gray.svg')

      &.orange
        &:before
          background-image: url('../images/blocks/b-yandex_rating/star-orange.svg')

    .value
      +margin-left_ltr(rem(4px))
      line-height: rem(20px)
      font-weight: 600
      font-size: rem(14px)
      color: #4d4d4d

  &.v2
    height: rem(16px)

    .stars .star
      height: rem(16px)
      width: rem(16px)
      +margin-right_ltr(0)

      &:before
        height: rem(14px)
        width: rem(14px)

      &.gray
        &:before
          background-image: url('../images/blocks/b-yandex_rating/star-gray-v2.svg')

      &.orange
        &:before
          background-image: url('../images/blocks/b-yandex_rating/star-orange-v2.svg')

a.b-yandex_rating
  &:hover
    color: var(--text-headline) !important
    text-decoration: none !important

    .value
      color: var(--text-headline)

  &:active
    opacity: 0.8
