.p-pages-dostavka article.slide-6
  h2
    +lte_ipad
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 56px

  ul
    +gte_laptop
      +fcolumn(2, 32px, 0px, 'li')

    li
      position: relative

      +lte_ipad
        margin-bottom: rem(32px)

  img
    display: block
    width: 100%

  .badge
    color: var(--text-strong)
    font-size: rem(32px)
    font-weight: 700
    left: 50%
    line-height: 1.2
    position: absolute
    text-align: center
    top: 50%
    transform: translateX(-50%) translateY(-50%)

    .submit-container
      margin-top: rem(18px)

      .submit
        +button_red
        height: rem(44px)
        width: rem(225px)

        +lte_ipad
          margin: 0 auto
