article.common_faq_questions
  +container
  position: relative
  overflow: hidden

  &:before
    background-color: var(--secondary-color)
    border-radius: rem(228px)
    content: ''
    height: rem(458px)
    +left_ltr(calc(50% - #{rem(228px)}))
    position: absolute
    width: rem(458px)
    z-index: -1

    +lte_ipad
      top: rem4(130px)

    +gte_laptop
      top: 161px

  .questions
    min-height: rem(490px)

    +gte_laptop
      display: flex

    .column
      flex-basis: rem(100px)
      flex-direction: column
      flex-grow: 1
      flex-shrink: 1
      margin-bottom: rem(20px)

      +gte_laptop
        +margin-right_ltr(15px)

        &:last-child
          +margin-left_ltr(15px)
          +margin-right_ltr(0)

      .question
        background: #fff
        border-radius: rem(12px)
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
        cursor: pointer
        padding: rem(20px) rem(24px)
        position: relative

        &:not(:last-child)
          margin-bottom: rem(12px)

        &:before
          background-image: url('../images/articles/common_faq_questions/plus.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(19px)
          position: absolute
          +right_ltr(rem(24px))
          top: rem(20px)
          width: rem(19px)

        .label
          line-height: rem(18px)
          +padding-right_ltr(rem(24px))

        .body
          color: var(--text-base)
          line-height: rem(18px)
          max-height: 0
          opacity: 0
          transition: all 150ms ease-in-out

        &.is-active
          &:before
            background-image: url('../images/articles/common_faq_questions/minus.svg')
            height: rem(1px)
            top: rem(29px)

          .body
            margin-top: rem(24px)
            max-height: rem(500px)
            opacity: 1
            overflow: scroll

  footer
    text-align: center
    margin-top: -40px

    a.btn-faq
      +button_red

      +lte_ipad
        min-width: 140px
