footer.b-footer.v1
  +lte_ipad
    padding-bottom: rem(29px)
    +padding-left_ltr(0)
    +padding-right_ltr(rem(36px))
    padding-top: rem(38px)

  +gte_laptop
    align-items: flex-start
    display: flex
    padding-bottom: 110px
    +padding-left_ltr(22px)
    +padding-right_ltr(75px)
    padding-top: 120px

  .container
    display: flex

    +lte_ipad
      flex-direction: column

  .logo
    +lte_ipad
      margin-bottom: rem(38px)
      width: rem(187px)

    +gte_laptop
      width: 25%

    +laptop
      +margin-right_ltr(scale-laptop(50px, 107px))

    +gte_desktop
      +margin-right_ltr(60px)

    a.logo-link
      background-repeat: no-repeat
      background-size: contain
      display: block

      &.logo-ru
        background-image: url(../images/blocks/b-top_menu/logo-red.svg)
      &.logo-et
        background-image: url(../images/blocks/b-top_menu/logo-red-et.svg)

      +lte_ipad
        height: rem(56px)
        width: rem(212px)

      +gte_laptop
        height: 52px
        width: 199px

  ul.links
    flex-direction: column

    +gte_laptop
      +fcolumn(2, 44px, 28px, 'li')
      width: 380px
      max-height: 500px

    +gte_desktop
      max-height: 400px

    li
      color: var(--text-base)
      flex-shrink: 0
      line-height: 1

      a
        -webkit-tap-highlight-color: transparent
        letter-spacing: -0.01em
        text-decoration: none

        +lte_ipad
          font-size: rem(12px)
          display: block
          margin-bottom: rem(10px)

        +gte_laptop
          font-size: 16px
          white-space: nowrap

  ul.social
    display: flex
    flex-direction: column

    +lte_ipad
      margin-top: rem(57px)

    +gte_laptop
      +margin-left_ltr(auto)

    li
      display: flex

      +lte_ipad
        margin-bottom: 16px

      +gte_laptop
        margin-bottom: 19px

      &:before
        content: ''
        background-repeat: no-repeat
        background-size: contain
        +margin-right_ltr(rem(16px))

        +lte_ipad
          width: rem(28px)
          height: rem(28px)

        +gte_laptop
          width: 26px
          height: 26px

    a
      +link(var(--primary-color))
      display: block
      white-space: nowrap

      +lte_ipad
        font-size: rem(12px)
        line-height: rem(16px)

      +gte_laptop
        font-size: 16px
        margin-top: -4px

    .phone
      &:before
        background-image: url('../images/blocks/b-footer/phone.svg')

        +gte_laptop
          margin-top: 3px

      a
        &:hover:not(:active)
          text-decoration: none

        +gte_laptop
          &:hover:not(:active) .number
            color: var(--primary-color-hover)
            text-decoration: underline

        &:active .number
          color: var(--primary-color-active)

      span
        display: block
        white-space: nowrap

        &.number
          color: var(--text-strong)
          font-weight: bold

          +lte_ipad
            font-size: rem(20px)
            line-height: rem(24px)
            margin-bottom: rem(4px)

          +gte_laptop
            font-size: 24px
            line-height: 28px
            margin-bottom: 7px

        &.hint
          color: var(--text-secondary)

    .email
      align-items: center

      &:before
        background-image: url('../images/blocks/b-footer/email.svg')

    .instagram
      align-items: center

      &:before
        background-image: url('../images/blocks/b-footer/instagram.svg')

  ul.storages
    margin-top: rem(6px)
    margin-left: rem(10px)

    li
      line-height: rem(16px)

      a
        font-size: rem(14px)

        +lte_ipad
          font-size: rem(10px)
          line-height: rem(12px)

  .copy-wrapper
    +gte_laptop
      position: relative
      order: -1

  .copy
    color: var(--text-secondary)
    letter-spacing: -0.01em
    line-height: 1.3

    .hint-ru
      margin-bottom: rem(8px)

    b
      color: inherit

      +lte_ipad
        font-weight: normal

      +gte_laptop
        font-size: 16px

    +lte_ipad
      font-size: rem(12px)
      margin-top: rem(47px)

    +gte_laptop
      position: absolute
      margin-top: 86px
      font-size: 14px
      width: 244px
