article.benefits
  header
    +container

    .note
      +lte_ipad
        max-width: rem(260px)

  .gradient
    background: linear-gradient(180deg, var(--primary-color) 1.16%, var(--primary-color-hover) 123.13%)
    color: #fff
    width: 100%

    +lte_ipad
      padding-bottom: rem(240px)

    ul
      color: #fff

      +lte_ipad
        padding: rem(48px) rem(4px) 0

      +laptop-new
        width: scale-laptop(550px, 565px)

      +gte_desktop-new
        width: 565px

      +gte_laptop
        display: flex
        flex-direction: column
        flex-wrap: wrap
        max-height: 400px
        padding-bottom: 0
        +padding-left_ltr(3px)
        +padding-right_ltr(0)
        padding-top: 45px
        margin-bottom: 45px

      li
        align-items: center
        display: flex
        font-size: rem(16px)
        line-height: 1.3

        +lte_ipad
          &:not(:last-child)
            margin-bottom: rem(32px)

          &:last-child
            margin-bottom: rem(13px)

        +gte_laptop
          margin-bottom: 40px
          +padding-right_ltr(20px)
          width: 50%

          &:nth-child(4n)
            margin-bottom: 0

        @each $type in clock, temperature, monitoring, security, cleaning, height, access, legal
          &.#{$type}:before
            background-image: url('../images/articles/benefits/#{$type}.svg')
            background-position: center
            background-repeat: no-repeat
            background-size: contain
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1)
            content: ''
            display: block
            flex-shrink: 0
            overflow: hidden

            +lte_ipad
              border-radius: rem(56px)
              height: rem(56px)
              +margin-right_ltr(rem(28px))
              width: rem(56px)

            +gte_laptop
              border-radius: 56px
              height: 52px
              width: 52px

            +laptop-new
              +margin-right_ltr(scale-laptop(24px, 40px))

            +gte_desktop-new
              +margin-right_ltr(40px)

        a
          +link_white
          text-decoration: underline

    picture
      position: absolute

      // hack to make loading='lazy', negative right position and
      // container overflow to hidden work with each other
      +gte_laptop
        min-width: 300px

      +laptop-new
        +right_ltr(scale-laptop(-170px, -25px))
        top: -89px

      +gte_desktop-new
        top: -89px
        +right_ltr(-25px)
