// $hierrchy: primary, secondary, brand
// $size: xl, md, sm
=button_v4($hierrchy, $size, $is_empty: false)
  -webkit-appearance: none
  -webkit-tap-highlight-color: transparent
  align-items: center
  cursor: pointer
  display: inline-flex
  justify-content: center
  text-align: center
  text-decoration: none
  transition: color var(--transition-duration), border-color var(--transition-duration), background-color var(--transition-duration), box-shadow var(--transition-duration)
  white-space: nowrap

  +lte_ipad
    border-radius: rem4(48px)

  +gte_laptop
    border-radius: 48px

  @if $size == 'xl'
    +b1

    +lte_ipad
      gap: rem4(6px)
      height: rem4(48px)
      padding: rem4(12px) rem4(20px)

      @if $is_empty
        padding: rem4(12px) 0
        width: rem4(48px)

    +gte_laptop
      gap: 6px
      height: 48px
      padding: 12px 20px

      @if $is_empty
        padding: 12px 0
        width: 48px

  @else if $size == 'md'
    +b2

    +lte_ipad
      gap: rem4(4px)
      height: rem4(40px)
      padding: rem4(10px) rem4(16px)

      @if $is_empty
        padding: rem4(10px) 0
        width: rem4(40px)

    +gte_laptop
      gap: 4px
      height: 40px
      padding: 10px 16px

      @if $is_empty
        padding: 10px 0
        width: 40px

  @else if $size == 'sm'
    +b2

    +lte_ipad
      gap: rem4(4px)
      height: rem4(36px)
      padding: var(--spacing-md) rem4(14px)

      @if $is_empty
        padding: var(--spacing-md) 0
        width: rem4(36px)

    +gte_laptop
      gap: 4px
      height: 36px
      padding: var(--spacing-md) 14px

      @if $is_empty
        padding: var(--spacing-md) 0
        width: 36px

  @else
    @error "unexpected hierrchy '#{$hierrchy}'"

  @if $hierrchy == 'primary'
    +button_v4_colors($color-default: #fff, $background-default: var(--gray-900), $background-hover: var(--gray-700), $background-active: #000, $background-inactive: var(--gray-300))
  @else if $hierrchy == 'secondary'
    +button_v4_colors($color-default: var(--gray-900), $color-inactive: var(--gray-400), $background-default: var(--gray-100), $background-hover: var(--gray-200), $background-active: var(--gray-300), $background-inactive: var(--gray-400))
  @else if $hierrchy == 'brand'
    +button_v4_colors($color-default: #fff, $background-default: var(--primary-color), $background-hover: var(--primary-color-hover), $background-active: var(--primary-color-active), $background-inactive: var(--primary-color-inactive))
  @else
    @error "unexpected hierrchy '#{$hierrchy}'"

  &:before,
  &:after
    line-height: 1

    +lte_ipad
      font-size: rem4(20px)

    +gte_laptop
      font-size: 20px

  +gte_laptop
    &:hover
      text-decoration: none

  &:active
    text-decoration: none

=button_v4_colors($color-default, $color-hover: '', $color-active: '', $color-inactive: '', $background-default, $background-hover, $background-active, $background-inactive)
  background: $background-default
  color: $color-default

  @if $color-hover == ''
    $color-hover: $color-default

  @if $color-active == ''
    $color-active: $color-default

  @if $color-inactive == ''
    $color-inactive: $color-default

  +gte_laptop
    &:hover
      background: $background-hover
      color: $color-hover

  &:active
    background: $background-active
    color: $color-active

  &.disabled,
  &[disabled],
  &.disabled:hover,
  &[disabled]:hover
    background: $background-inactive
    color: $color-inactive
    cursor: default
