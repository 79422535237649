article.v3.got_questions
  --article-background: #eff8ff

  .inner
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem(32px)

    +gte_laptop
      gap: 30px
      position: relative

  .above-headline
    color: #1570ef

  .head
    +gte_laptop
      display: flex
      flex-direction: column
      flex-basis: 50%

      &:before
        background-image: url('../images/articles/v3/got_questions/background-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        bottom: 12px
        content: ''
        height: 223px
        left: 9px
        position: absolute
        width: 224px
        z-index: 1

      &:after
        background-image: url('../images/articles/v3/got_questions/picture-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: 120px
        margin-top: auto
        position: relative
        width: 120px
        z-index: 2

  .faq
    display: flex
    flex-direction: column

    +gte_laptop
      flex-basis: 50%

    .question
      position: relative
      flex-grow: 1

      // +lte_ipad
      //   padding-right: #{rem(24px + 16px)}
      //
      // +gte_laptop
      //   padding-right: #{24px + 24px}

      &:not(:last-child)
        border-bottom: 1px solid var(--border-base)

        +lte_ipad
          padding-bottom: rem(16px)
          margin-bottom: rem(16px)

        +gte_laptop
          padding-bottom: 24px
          margin-bottom: 24px

      &.is-expanded
        .b-svg-icon
          &.plus
            display: none

          &.minus
            display: flex

      .b-svg-icon
        margin-top: rem(5px)
        pointer-events: none

        +lte_ipad
          height: rem(16px)
          width: rem(16px)

        +gte_laptop
          height: 24px
          width: 24px

        svg
          color: inherit

        &.minus
          display: none

      .label
        color: var(--text-strong)
        cursor: pointer
        display: flex
        font-weight: 700
        gap: rem(24px)
        justify-content: space-between
        transition: color var(--transition-duration)
        width: 100%

        +lte_ipad
          +p2

        +gte_laptop
          +p1

          &:hover
            color: var(--text-base-hover)

        &:active
          color: var(--text-base-active)

      .body
        margin-top: rem(8px)

        +lte_ipad
          +p3

        +gte_laptop
          +p2
