article.storable_types
  h2
    +lte_ipad
      max-width: rem(220px)

  .slides-container
    position: relative

    ul
      color: #fff

  .slides
    +gte_laptop
      display: grid
      grid-template-columns: repeat(3, 1fr)

    +laptop-new
      grid-gap: scale-laptop(24px, 33px) scale-laptop(28px, 30px)

    +gte_desktop-new
      grid-gap: 33px 30px

    &:not(.is-mobile-expanded)
      .slide
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6)
          +lte_ipad
            display: none

    .slide
      border-radius: rem(20px)
      color: #fff
      position: relative
      overflow: hidden
      display: flex

      +lte_ipad
        height: rem(200px)
        margin-bottom: rem(20px)

      +laptop-new
        height: scale-laptop(220px, 248px)

      +gte_desktop-new
        height: 248px

      picture
        bottom: 0
        line-height: 0
        position: absolute
        +right_ltr(0)

        +laptop-new
          display: flex
          align-items: flex-end
          justify-content: flex-end

          @each $i in 0,1,2,3,4,5,6,7,8,9,10,11
            $min-width: #{1024px + $i * 14px}
            $max-width: #{1024px + ($i + 1) * 14px}
            @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width})
              max-width: #{(88 + $i) * 1%}

      +gte_laptop
        &.is-ac_ive,
        &:hover
          .default
            +ltr
              transform: translateX(100%)

            +rtl
              transform: translateX(-100%)

          .active
            +ltr_rtl
              transform: translateX(0)

      .default,
      .active
        display: flex
        flex-direction: column
        height: 100%
        position: absolute
        width: 100%
        transition: transform var(--transition-duration)

        +lte_ipad
          padding: rem(21px) rem(23px)

        +laptop-new
          padding: scale-laptop(24px, 28px) scale-laptop(28px, 32px)

        +gte_desktop-new
          padding: 28px 32px

      .default
        background: var(--secondary-color)

        +ltr_rtl
          transform: translateX(0)

        .label
          +lte_ipad
            font-size: rem(16px)
            line-height: rem(20px)

          +gte_laptop
            font-weight: bold

          +laptop-new
            font-size: scale-laptop(20px, 24px)
            line-height: scale-laptop(24px, 31px)

          +gte_desktop-new
            font-size: 24px
            line-height: 31px

      .active
        background: var(--primary-color)

        +ltr
          transform: translateX(-100%)

        +rtl
          transform: translateX(100%)

        ul
          li
            font-size: rem(16px)
            font-weight: 500
            line-height: rem(29px)
            +padding-left_ltr(rem(8px))

            &:before
              background-image: url('../images/pages/root/slide_2/list_item.svg')
              background-repeat: no-repeat
              background-size: contain
              content: ''
              height: rem(8px)
              +margin-left_ltr(rem(-17px))
              margin-top: rem(10px)
              position: absolute
              width: rem(8px)

          &:after
            +rtl_flip
            background-image: url('../images/pages/root/slide_2/list_arrow.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(15px)
            +margin-left_ltr(rem(-8px))
            margin-top: rem(16px)
            position: absolute
            width: rem(26px)

  .show-more
    display: flex
    justify-content: center
    margin-top: rem(60px)

    +gte_laptop
      display: none

    button
      +button_frame_red
      +button_frame_arrow('red')
      width: rem(220px)

      &:after
        +margin-left_ltr(rem(17px))
