.b-world-map
  background-color: #eff3fa
  background-position: right
  background-repeat: no-repeat
  background-size: cover
  position: relative
  width: 100%

  +lte_ipad
    background-image: url('../images/blocks/b-world-map/world_map-v4-mobile@2x.png')
    height: rem4(540px)

  +gte_laptop
    background-image: url('../images/blocks/b-world-map/world_map-v4-desktop@2x.png')
    border-radius: 32px
    box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
    height: 662px
    overflow: hidden

  .country-badge
    +p3(700)
    align-items: center
    background-color: #fff
    display: flex
    position: absolute
    text-decoration: none

    +lte_ipad
      border-radius: rem4(32px)
      box-shadow: rem4(-4px) rem4(8px) rem4(20px) 0px rgba(0, 0, 0, 0.10)
      gap: rem4(6px)
      height: rem4(40px)
      padding: rem4(10px) rem4(16px)

    +gte_laptop
      border-radius: 32px
      box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
      gap: 6px
      height: 40px
      padding: 10px 16px

      &:hover
        box-shadow: 0px 4px 24px 0px #d9613c40
        color: var(--primary-color-hover)
        cursor: pointer
        text-decoration: none

    &:active
      box-shadow: none
      color: var(--primary-color-active)

    .flag
      background-position: center
      background-size: contain
      background-repeat: no-repeat
      height: rem4(24px)
      width: rem4(16px)

    &.estonia
      +lte_ipad
        right: rem4(184px)
        top: rem4(57px)

      +gte_laptop
        right: 529px
        top: 68px

      .flag
        background-image: url('../images/blocks/b-world-map/flag_estonia.svg')

    &.uae
      +lte_ipad
        bottom: rem4(13px)
        right: rem4(21px)

      +gte_laptop
        bottom: 69px
        right: 256px

      .flag
        background-image: url('../images/blocks/b-world-map/flag_uae.svg')
