article.orders
  margin-top: rem(18px)

  h1
    margin-bottom: rem(20px)

    +lte_ipad
      font-size: rem(32px)
      line-height: rem(36px)

    +gte_laptop
      font-size: 40px
      line-height: 48px

  .subtitle
    font-size: rem(20px)
    font-weight: 700
    line-height: rem(24px)
    margin-bottom: rem(24px)

    +lte_ipad
      text-align: center

    +gte_laptop
      +text-align_ltr(left)

    &.additional
      margin-top: rem(24px)

  a.back
    +button_frame_red

    +lte_ipad
      +margin-right_ltr(rem(8px))

    +gte_laptop
      +margin-right_ltr(30px)

  a.next
    +button_red

  a.link
    +link_underline
    +margin-left_ltr(rem(30px))

  .container-bottom
    +lte_ipad
      border-radius: rem(12px) rem(12px) 0 0
      box-shadow: 0px 0px 24px rgba(58, 63, 75, 0.4)
      position: fixed
      width: 100%
      background-color: #fff
      display: flex
      padding: rem(8px) rem(16px)
      bottom: 0
      +left_ltr(0)
      z-index: $z-index-sticky_area

      a.back,
      a.next,
      a.link,
      input
        flex-basis: rem(100px)
        flex-grow: 1
        flex-shrink: 1
        margin-top: 0

  .tags
    +lte_ipad
      text-align: center
      margin-bottom: rem(14px)

    &.mobile
      +lte_ipad
        display: block

      +gte_laptop
        display: none

    &.desktop
      +lte_ipad
        display: none

      +gte_laptop
        display: block

    .tag
      background-color: #eff3fa
      border-radius: rem(30px)
      display: inline-block
      margin-bottom: rem(8px)

      +lte_ipad
        font-size: rem(10px)
        line-height: rem(16px)
        padding: rem(4px) rem(16px)

      +gte_laptop
        font-size: 14px
        padding: 12px 20px

      &:not(:last-child)
        +margin-right_ltr(rem(12px))

  .modal-deposit,
  .modal-simple-lock,
  .modal-unavailable-box
    background-color: #fff
    height: 100%
    +left_ltr(0)
    position: fixed
    top: 0
    width: 100%
    z-index: 10

    +lte_ipad
      padding: rem(30px) rem(16px)

    +gte_laptop
      padding: 30px 46px

    .body
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center
      position: relative

      p
        font-size: rem(14px)

      a.deposit-close,
      a.modal-close
        background-image: url('../images/pages/orders/x.svg')
        background-repeat: no-repeat
        background-size: contain
        display: inline-block
        height: rem(24px)
        position: absolute
        +right_ltr(0)
        top: 0
        width: rem(24px)

        &:hover
          opacity: 0.8

        &:active
          opacity: 0.9

      .title
        font-size: rem(24px)
        font-weight: bold
        line-height: rem(28px)
        margin-bottom: rem(24px)

  .modal-deposit,
  .modal-simple-lock
    .body
      .icon
        background-image: url('../images/pages/orders/info-red.svg')
        background-repeat: no-repeat
        background-size: contain
        height: rem(56px)
        margin-bottom: rem(16px)
        width: rem(56px)

  .modal-deposit
    .body
      p
        max-width: 540px
        +text-align_ltr(left)

  .modal-unavailable-box
    .body
      .warning-big-image
        background-image: url('../images/pages/orders/warning-big-image.svg')
        background-repeat: no-repeat
        background-size: contain
        height: rem(160px)
        margin-bottom: rem(24px)
        width: rem(173px)

      .inner
        text-align: center

        p
          font-size: rem(14px)
          margin-bottom: rem(56px)

      a.goto-storage
        +button_red
        width: 100%

  .container-flex
    +gte_laptop
      display: flex

    .left-side
      flex-grow: 1

      +lte_ipad
        +margin-right_ltr(0)

      +gte_laptop
        +margin-right_ltr(28px)

      +gte_desktop
        +margin-right_ltr(30px)

    .tariffs
      .inner
        margin-bottom: rem(24px)

        .tariff
          border-radius: rem(12px)
          border: 2px solid #f1f1f1
          box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
          box-sizing: border-box
          display: flex
          flex-direction: column
          justify-content: space-between

          +lte_ipad
            margin-bottom: rem(12px)
            padding: rem(16px) 0

          +gte_laptop
            height: 146px
            margin-bottom: 16px
            padding: 16px

          &.active
            background-color: var(--secondary-color)
            border-color: var(--secondary-color)

            .name
              color: #fff

            .amounts
              color: #fff

              .price-wo-discount
                color: #eff3fa

              .benefit
                color: #fff

          &:hover:not(.active)
            cursor: pointer
            border-color: var(--secondary-color)

            .amounts
              .benefit
                color: var(--primary-color)

          .row
            display: flex
            justify-content: space-between

            +lte_ipad
              flex-direction: column
              align-items: center

            +gte_laptop
              flex-direction: row

          .name
            color: var(--secondary-color)
            font-size: rem(32px)
            font-weight: 700
            line-height: rem(36px)

            +lte_ipad
              margin-bottom: rem(16px)

          .amounts
            .price-with-discount
              display: inline-block
              font-size: rem(24px)
              font-weight: 700
              line-height: rem(28px)

              +lte_ipad
                +margin-left_ltr(rem(12px))

              +gte_laptop
                +margin-left_ltr(20px)

            .price-wo-discount
              color: var(--text-base)
              display: inline-block
              font-size: rem(16px)
              line-height: rem(20px)
              text-decoration: line-through

            .benefit
              color: var(--text-secondary)
              font-size: rem(14px)
              line-height: rem(18px)
              margin-top: rem(4px)

              +lte_ipad
                text-align: center

              +gte_laptop
                +text-align_ltr(right)

      .note
        margin-bottom: rem(44px)
        line-height: rem(20px)
        position: relative
        +padding-left_ltr(rem(72px))

        &:before
          background-image: url('../images/pages/orders/note.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(56px)
          +left_ltr(0)
          position: absolute
          top: 0
          width: rem(56px)

    .order-details
      .inner
        border-radius: rem(12px)
        border: 2px solid #f1f1f1
        box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
        box-sizing: border-box
        margin-bottom: rem(24px)

        +lte_ipad
          padding: rem(24px) rem(16px)
          width: 100%

        +gte_laptop
          padding: 24px
          width: 350px

        .box-number
          font-size: rem(32px)
          font-weight: 700
          line-height: rem(36px)
          text-align: center
          margin-bottom: rem(8px)

        .box-price
          font-size: rem(18px)
          font-weight: 700
          line-height: rem(22px)
          margin-bottom: rem(16px)
          color: var(--primary-color)
          text-align: center

        .box-image
          background-position: center
          border-radius: rem(12px)
          height: rem(140px)
          margin-bottom: rem(16px)

        ul
          li
            font-size: rem(14px)
            line-height: rem(18px)

            &:not(:last-child)
              margin-bottom: rem(12px)

            .label
              +margin-right_ltr(rem(4px))
              color: var(--text-secondary)

            .value
              font-weight: 600

        .tags
          margin-bottom: rem(16px)

      .simple-lock
        display: flex
        align-items: center

        .icon
          background-image: url('../images/pages/orders/lock.svg')
          background-repeat: no-repeat
          background-size: contain
          height: rem(56px)
          width: rem(56px)

        .description
          +margin-left_ltr(rem(16px))

          a
            +link_underline
            display: block
            font-size: rem(14px)
            line-height: 1.3
            margin-top: rem(4px)

      .modal-simple-lock
        .icon
          margin-bottom: rem(16px)

        .picture
          background-image: url('../images/pages/orders/simple-lock.png')
          background-repeat: no-repeat
          background-size: contain

          +lte_ipad
            height: rem(240px)
            width: 100%

          +gte_laptop
            height: 280px
            margin-bottom: 16px
            width: 476px

        p,
        .note
          max-width: rem(476px)
          margin-bottom: rem(20px)

        .note
          background-color: #eff3fa
          border-radius: rem(12px)
          color: var(--text-base)
          font-size: rem(14px)
          line-height: 1.3
          padding: rem(12px) rem(16px)

    .registration,
    .session
      .b-form
        +gte_ipad
          max-width: 350px

      a.forgot
        +link_underline
        margin-bottom: rem(24px)
        margin-top: rem(-10px)

      .note
        margin-bottom: rem(24px)

        +lte_ipad
          font-size: rem(14px)

      .actions.container-bottom
        a.link
          +iphone
            display: none

          +gte_ipad
            display: inline // necessary to avoid wrapping on next line
            margin-top: rem(24px)
            position: absolute

    .profile
      margin-bottom: rem(44px)

      .note
        margin-bottom: rem(24px)

        +lte_ipad
          font-size: rem(14px)

      .tabs
        display: flex
        margin-bottom: rem(24px)
        // justify-content: space-between

        .tab
          align-items: center
          background-color: #eff3fa
          border-radius: rem(12px)
          color: var(--text-base)
          display: flex
          justify-content: center
          cursor: pointer
          flex-basis: rem(100px)
          flex-grow: 1
          flex-shrink: 1
          text-decoration: none

          +lte_ipad
            font-size: rem(12px)
            height: rem(44px)
            line-height: 1.2
            text-align: center

          +gte_laptop
            height: 68px

          &:not(:last-child)
            +lte_ipad
              +margin-right_ltr(rem(8px))

            +gte_laptop
              +margin-right_ltr(30px)

          &.active,
          &:hover:not(.disabled)
            background-color: var(--secondary-color)
            color: #fff

          &.active
            +lte_ipad
              font-weight: 400

            +gte_laptop
              font-weight: 700

          &.active,
          &.disabled
            cursor: default

      .b-input
        max-width: 644px

        &.normal
          +gte_ipad
            max-width: 350px

        &.mini
          +lte_ipad
            width: 100%
            margin-bottom: rem(16px)

          +gte_laptop
            display: inline-block
            vertical-align: top
            +margin-right_ltr(28px)
            width: 160px

      .actions.container-bottom
        +gte_laptop
          margin-top: 32px

    .agreement-new
      .bill-details
        display: flex
        margin-bottom: rem(32px)

        +lte_ipad
          flex-direction: column

        +gte_laptop
          flex-direction: row

        .note
          font-size: rem(14px)
          line-height: rem(18px)

        .total
          background-color: var(--primary-color)
          border-radius: rem(12px)
          color: #fff
          min-width: rem(180px)
          padding: rem(26px)
          text-align: center

          +lte_ipad
            margin-bottom: rem(16px)

          +gte_laptop
            +margin-right_ltr(30px)

          .amount
            font-size: rem(32px)
            font-weight: 700
            line-height: rem(36px)
            margin-bottom: rem(12px)
            white-space: nowrap

          .note
            color: #eff3fa
            font-size: 14px
            line-height: 1.3

        .details.mobile
          background-color: var(--secondary-color)
          border-radius: rem(12px)
          color: #fff
          display: flex
          padding: rem(16px)
          justify-content: space-around

          +gte_laptop
            display: none

          .column
            align-items: center
            display: flex
            flex-basis: rem(100px)
            flex-direction: column
            flex-grow: 1
            flex-shrink: 1

            .item
              font-size: rem(14px)
              line-height: 1.3
              text-align: center

              &:not(:last-child)
                margin-bottom: rem(24px)

              .value
                font-size: rem(20px)
                font-weight: 700
                line-height: rem(24px)
                margin-bottom: rem(8px)

              a
                +link_underline
                color: #eff3fa

        .details.desktop
          background-color: var(--secondary-color)
          border-radius: rem(12px)
          color: #fff
          justify-content: space-around
          padding: rem(24px) rem(20px)

          +lte_ipad
            display: none

          +gte_laptop
            display: flex
            flex-grow: 1

          .item
            font-size: rem(14px)
            line-height: 1.3
            text-align: center
            padding: 0 rem(4px)

            +lte_ipad
              display: inline-block

            +gte_laptop
              flex-basis: 100px
              flex-grow: 1
              flex-shrink: 1

            .value
              font-size: rem(20px)
              font-weight: 700
              line-height: rem(24px)
              margin-bottom: rem(12px)

            a
              +link_underline
              color: #eff3fa

      .payment-months
        display: flex
        margin-bottom: rem(24px)

        +iphone
          column-gap: rem(8px)

        +lte_ipad
          flex-wrap: wrap

        +gte_ipad
          column-gap: rem(30px)

        .b-input
          margin-bottom: 0

          &.paid-months
            +iphone
              flex-grow: 1
              min-width: 100%

          &.coupon-code
            flex-grow: 1

            .error-message
              display: none

            &.field_with_errors .error-message
              display: block

        input
          +iphone
            width: 100%

          +gte_ipad
            min-width: 180px

        .fast-links
          display: flex
          // Временно прячем эти ссылки, т.к. они путают пользователей при
          // выборе тарифа на 6 или 12 месяцев. Если не понадобятся
          // до 01.11.2023 можно убдет удалить.
          display: none

          .item
            align-items: center
            background-color: #eff3fa
            border-radius: rem(12px)
            box-sizing: border-box
            color: var(--text-base)
            display: flex
            height: rem(48px)

            &.mobile
              padding: rem(14px) rem(16px)

              +gte_laptop
                display: none

            &.desktop
              padding: 14px 20px

              +lte_ipad
                display: none

            &.months_12
              +lte_ipad
                +margin-left_ltr(rem(8px))

              +gte_laptop
                +margin-left_ltr(12px)

            &.active,
            &:hover:not(.active)
              background-color: var(--secondary-color)
              color: #fff

            &:hover:not(.active)
              cursor: pointer

        .coupon-code,
        .submit-coupon-code
          +iphone
            margin-top: rem(16px)

        .submit-coupon-code
          +button_red
          align-items: center
          display: flex
          height: 40px
          justify-content: center
          min-width: 40px !important
          width: 40px

          +iphone
            margin-left: rem(8px)
            margin-top: rem(16px + 6px)

          +gte_ipad
            margin-left: rem(-16px)
            margin-top: rem(4px)

          &:after
            background-image: url('../images/pages/orders/check.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(16px)
            width: rem(16px)

      .payment-methods
        .row
          display: flex
          margin-bottom: rem(24px)

          +lte_ipad
            flex-direction: column

          +gte_laptop
            flex-direction: row

        .block,
        .block-product
          border-radius: rem(12px)
          border: 2px solid #f1f1f1
          box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
          box-sizing: border-box
          flex-basis: rem(100px)
          flex-grow: 1
          flex-shrink: 1
          padding: rem(24px)

        .block
          &:hover:not(.active)
            cursor: pointer
            border-color: var(--secondary-color)

          &:not(.active)
            .recurrent-confirm
              display: none

          &.active
            border-color: var(--secondary-color)
            background-color: var(--secondary-color)
            color: #fff

            .note,
            p
              color: #fff

          &.card
            +lte_ipad
              margin-bottom: rem(12px)

            +gte_laptop
              +margin-right_ltr(30px)

          .title
            font-size: rem(16px)
            font-weight: 700
            line-height: rem(19px)
            margin-bottom: rem(16px)

          .note
            font-size: rem(14px)
            line-height: 1.3
            color: var(--text-secondary)

        .block-product.simple-lock
          display: flex
          justify-content: space-between
          align-items: center

          +lte_ipad
            flex-direction: column

          +gte_laptop
            flex-direction: row

          &.active
            border-color: var(--secondary-color)
            background-color: var(--secondary-color)
            color: #fff

            a.more-info
              color: #fff !important

            .left
              .info
                .icon
                  background-image: url('../images/pages/orders/lock-active.svg')

          .left
            display: flex
            align-items: center
            flex-grow: 1
            justify-content: space-between

            +lte_ipad
              width: 100%

            .info
              align-items: center
              display: flex
              justify-content: space-between

              .icon
                background-image: url('../images/pages/orders/lock.svg')
                background-repeat: no-repeat
                background-size: contain
                height: rem(56px)
                +margin-right_ltr(rem(16px))
                width: rem(56px)

                +lte_ipad
                  display: none

              a.more-info
                +link_underline
                display: block
                font-size: rem(14px)
                line-height: 1.3
                margin-top: rem(4px)

            .price
              float: right
              font-size: rem(24px)
              font-weight: 700
              line-height: rem(28px)

          .right
            display: flex
            align-items: center

            +lte_ipad
              margin-top: rem(24px)
              width: 100%

            +gte_laptop
              +padding-left_ltr(60px)

            .add-product
              +button_red
              padding: 0 rem(14px)

              +lte_ipad
                width: 100%

            .remove-product
              +button_white
              padding: 0 rem(14px)

              +lte_ipad
                width: 100%

        .info-blocks
          .item
            color: var(--text-base)
            flex-basis: rem(100px)
            flex-grow: 1
            flex-shrink: 1
            font-size: rem(16px)
            line-height: 1.3
            +padding-left_ltr(rem(72px))
            position: relative

            +lte_ipad
              &:not(:last-child)
                margin-bottom: rem(16px)

            +gte_laptop
              &:not(:last-child)
                +margin-right_ltr(30px)

            &:before
              background-image: url('../images/pages/orders/note.svg')
              background-repeat: no-repeat
              background-size: contain
              content: ''
              height: rem(56px)
              +left_ltr(0)
              pointer-events: none
              position: absolute
              top: 0
              width: rem(56px)

        .agreement-confirm,
        .recurrent-confirm
          align-items: center
          display: flex
          +margin-left_ltr(0)
          +text-align_ltr(left)

          +lte_ipad
            flex-direction: row

          input
            cursor: pointer
            margin-bottom: 0
            +margin-left_ltr(0)
            +margin-right_ltr(rem(12px))
            margin-top: 0
            min-height: rem(20px)
            min-width: rem(20px)

          p
            color: var(--text-secondary)
            font-size: rem(12px)
            line-height: rem(16px)

          a
            +link_underline
            +margin-left_ltr(rem(2px))

        .recurrent-confirm
          margin-top: rem(12px)

      .actions.container-bottom
        margin-top: rem(44px)
