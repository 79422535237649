.b-gallery
  display: flex

  &:not(.vertical)
    +lte_ipad
      flex-wrap: wrap
      justify-content: center

  &.vertical
    justify-content: center
    flex-wrap: wrap

    .b-collection_image_item
      +margin-right_ltr(0)

  .b-collection_image_item
    align-self: flex-start
    margin-bottom: 1rem
    +margin-left_ltr(0)
    +margin-right_ltr(1rem)
    margin-top: 0

  .swiper-inner
    box-sizing: content-box
    display: flex
    height: 100%
    position: relative
    transform: translate3d(0px, 0, 0)
    transition-property: transform
    width: 100%
    z-index: 1
