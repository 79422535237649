article.headline
  +container
  padding-top: 0
  gap: 0

  header
    .note
      +text-align_ltr(left)
      max-width: 100%

    .note-image
      display: flex
      +text-align_ltr(left)

      +lte_ipad
        flex-direction: column
        gap: rem4(56px)

      +gte_laptop
        gap: 56px

      .image
        align-items: flex-start
        display: flex
        flex-shrink: 0
        justify-content: center

        img
          width: 100%

          +lte_ipad
            border-radius: rem4(20px)

          +gte_laptop
            border-radius: 20px
