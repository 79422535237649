.b-feature_v2
  align-items: center
  display: inline-flex
  justify-content: center

  +lte_ipad
    height: rem4(24px)
    width: rem4(24px)

  +gte_laptop
    height: 24px
    width: 24px

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''
      flex-shrink: 0
      width: 100%
      height: 100%

  @each $kind in cargo_carts, platform_ladders, freight_passenger_elevator, freight_elevator, passenger_elevator, mezzanine_boxes, access_24_7, timed_access, access_via_app, access_by_pin_code, access_by_pin_code_and_via_app, padlocks, N_minutes_from_metro, administrator, wi_fi, wc, vehicle_entry_up_to_N_meters, city_parking_nearby, courtyard_parking, kladovkin_parking, paid_parking, storage_on_floor_N, storage_on_multi_level_parking, video_surveillance, additional_feature_1, additional_feature_2, additional_feature_3, additional_feature_4
    &.#{$kind}:before
      background-image: url('../images/blocks/b-feature_v2/#{$kind}.svg')

.b-feature_v2-is_excluded
  color: #a0a0ab
  text-decoration: line-through
