.p-pages-how_to_choose_box article.slide_7
  position: relative

  & > .container:after
    +gte_laptop
      background-image: url('../images/pages/how_to_choose_box/slide_7/circles-desktop.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      display: block
      height: 142px
      +margin-left_ltr(66px)
      margin-top: 84px
      width: 118px

  .subtitle-note
    +lte_ipad
      text-align: center
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 80px

  .headline
    color: var(--text-strong)
    font-weight: bold

    +lte_ipad
      font-size: rem(16px)
      line-height: 1.3
      text-align: center
      margin-bottom: rem(20px)

    +gte_laptop
      font-size: 20px
      line-height: 1.2
      margin-bottom: 28px

  ul
    li
      align-items: center
      color: var(--text-base)
      display: flex
      font-size: rem(16px)
      line-height: 1.3

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''
        flex-shrink: 0
        height: rem(28px)
        width: rem(28px)

        +lte_ipad
          +margin-right_ltr(rem(16px))

        +gte_laptop
          +margin-right_ltr(23px)

      @each $i in 1,2,3,4
        &[data-number='#{$i}']:before
          background-image: url('../images/pages/how_to_choose_box/slide_7/#{$i}.svg')

      &:not(:last-child)
        margin-bottom: rem(16px)

  picture
    +lte_ipad
      display: block
      +mx-auto
      margin-top: rem(60px)

    +laptop-new
      +right_ltr(scale-laptop(-149px, -29px))

    +gte_desktop-new
      +right_ltr(-29px)

    +gte_laptop
      position: absolute
      top: 220px

      // hack to make loading='lazy', negative right position and
      // container overflow to hidden work with each other
      min-width: 613px
