.p-pages-how_to_choose_box article.slide_4
  .slide-vertical-outer
    +gte_laptop
      &:last-child
        margin-bottom: 32px

      &:not(:last-child)
        margin-bottom: 20px

  .slide-vertical
    .content
      +lte_ipad
        margin-bottom: 0

    ul
      +gte_laptop
        display: flex

      li
        +lte_ipad
          align-items: center
          +text-align_ltr(left)

          &:not(:last-child)
            margin-bottom: rem(40px)

        +gte_laptop
          align-items: center

    &.slide-1
      +lte_ipad
        padding-bottom: rem(270px)

      picture
        +laptop-new
          +margin-left_ltr(scale-laptop(100px, 10px))

      ul
        +gte_laptop
          width: 550px

        li
          flex-shrink: 0

          +lte_ipad
            flex-direction: row

          +gte_laptop
            &:first-child p
              width: 180px

            &:last-child
              +margin-left_ltr(auto)

              p
                width: 211px

          p
            +lte_ipad
              font-size: rem(12px)
              line-height: 1.2

            +gte_laptop
              font-size: 14px
              line-height: 1.3

          &.icon:before
            height: rem(44px)
            width: rem(44px)

            +lte_ipad
              +margin-right_ltr(rem(24px))

          &.icon-elevator:before
            background-image: url('../images/pages/how_to_choose_box/slide_4/icon-elevator.svg')

          &.icon-stairs:before
            background-image: url('../images/pages/how_to_choose_box/slide_4/icon-stairs.svg')

    &.slide-2
      +lte_ipad
        padding-bottom: rem(248px)

      .content
        +laptop-new
          padding-top: scale-laptop(60px, 77px)
          +margin-left_ltr(scale-laptop(108px, 128px))

        +gte_desktop-new
          padding-top: 77px
          +margin-left_ltr(128px)

        +gte_laptop
          +padding-left_ltr(0)

      ul li
        +lte_ipad
          flex-direction: column

          p
            font-weight: 300
            text-align: center

            b
              font-weight: normal

        &.icon:before
          height: rem(52px)
          width: rem(52px)

          +lte_ipad
            margin-bottom: rem(15px)
            margin-top: rem(14px)

        &.icon-note:before
          background-image: url('../images/pages/how_to_choose_box/slide_4/icon-note.svg')

  .summary-block
    +gte_laptop
      +padding-left_ltr(88px)

    .summary-text
      +gte_laptop
        &:before
          background-image: url('../images/pages/how_to_choose_box/slide_4/summary_height-desktop.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          width: 32px
          height: 32px
          position: absolute
          +margin-left_ltr(-56px)
          margin-top: -4px
