.b-storage-map-details
  +lte_ipad
    margin-bottom: rem(30px)

  +gte_laptop
    position: absolute
    top: 280px
    width: 290px
    z-index: 2

  +laptop
    +left_ltr(calc(50% + 200px))

  +gte_desktop
    +left_ltr(calc(50% + 300px))
