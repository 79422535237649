.p-vacancies-show
  h1
    +lte_ipad
      margin-bottom: rem(8px)

    +gte_laptop
      margin-bottom: 12px

  .note
    color: var(--text-base)
    font-size: 16px
    line-height: 1.3
    text-align: center

    +gte_laptop
      +mx-auto
      max-width: 474px

  .salary
    color: var(--primary-color)
    font-weight: 700
    text-align: center

    +lte_ipad
      font-size: rem(24px)
      line-height: rem(28px)
      margin-top: rem(16px)

    +gte_laptop
      font-size: 32px
      line-height: 36px
      margin-top: 24px

  .sections
    +lte_ipad
      margin-top: rem(36px)

    +gte_laptop
      margin-top: 65px

  .section
    position: relative

    +gte_laptop
      padding-top: 44px
      min-height: 152px

    &:first-child
      +gte_laptop
        margin-top: -44px

    &:not(:first-child)
      +lte_ipad
        padding-top: rem(32px)

    &:last-child
      +gte_laptop
        padding-top: 32px

    &:not(:last-child)
      border-bottom: 3px solid #eaeaea

      +lte_ipad
        padding-bottom: rem(32px)

      +gte_laptop
        padding-bottom: 44px

    &:before
      +gte_laptop
        background-size: contain
        content: ''
        position: absolute

    &.requirements:before
      +gte_laptop
        background-image: url('../images/pages/vacancies/circles-requirements.svg')
        height: 65px
        +right_ltr(153px)
        top: -54px
        width: 73px

    &.responsibilities:before
      +gte_laptop
        background-image: url('../images/pages/vacancies/circles-responsibilities.svg')
        background-size: contain
        height: 158px
        +right_ltr(59px)
        top: 22px
        width: 111px

    &.terms:before
      +gte_laptop
        background-image: url('../images/pages/vacancies/circles-terms.svg')
        background-size: contain
        bottom: 45px
        height: 59px
        +left_ltr(98px)
        width: 64px

    .headline
      font-weight: 700

      +lte_ipad
        font-size: rem(16px)
        line-height: rem(24px)
        margin-bottom: rem(24px)

      +gte_laptop
        font-size: 24px
        line-height: 28px
        margin-top: 10px
        max-width: 266px
        position: absolute

      span
        color: var(--primary-color)

    ul
      +gte_laptop
        +margin-left_ltr(385px)

      li
        color: var(--text-base)
        font-size: rem(16px)
        line-height: 1.3

      &.lines li
        +padding-left_ltr(rem(36px))

        &:not(:last-child)
          margin-bottom: rem(17px)

        &:before
          background-image: url('../images/pages/vacancies/circle.svg')
          background-size: contain
          content: ''
          height: rem(16px)
          +margin-left_ltr(rem(-36px))
          margin-top: rem(3px)
          position: absolute
          width: rem(16px)

      &.mosaic
        display: flex
        flex-wrap: wrap

        li
          position: relative

          +lte_ipad
            min-height: 50px
            align-items: center
            display: flex

            &:not(:last-child)
              +padding-left_ltr(76px)
              margin-bottom: rem(30px)

          $block-width-desktop: 190px
          +gte_laptop
            width: $block-width-desktop

          &:before
            background-size: contain
            content: ''
            +left_ltr(0)
            position: absolute

            +gte_laptop
              top: 0

          &.term-1
            +gte_laptop
              padding-top: 64px

            &:before
              background-image: url('../images/pages/vacancies/term_1.svg')
              height: rem(50px)
              width: rem(50px)

          &.term-2
            +gte_laptop
              margin-bottom: 28px
              +margin-right_ltr(calc(100% - #{$block-width-desktop * 2}))
              padding-top: 64px

            &:before
              background-image: url('../images/pages/vacancies/term_2.svg')
              height: rem(52px)
              width: rem(48px)

          &.term-3
            +gte_laptop
              padding-top: 58px

            &:before
              background-image: url('../images/pages/vacancies/term_3.svg')
              height: rem(48px)
              width: rem(50px)

          &.term-4
            +gte_laptop
              padding-top: 58px
              margin-bottom: 61px
              +margin-right_ltr(calc(100% - #{$block-width-desktop * 2}))

            &:before
              background-image: url('../images/pages/vacancies/term_4.svg')
              height: rem(46px)
              width: rem(46px)

          &.submit
            a
              +button_red

              +lte_ipad
                width: rem(160px)
