.b-error-page
  align-items: center
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  padding: rem(40px)

  .error-title
    color: #000
    font-size: rem(24px)
    font-weight: bold
    line-height: rem(28px)
    margin-bottom: rem(24px)

  .error-subtitle
    color: var(--text-base)
    font-size: rem(16px)
    line-height: rem(19px)
    margin-bottom: rem(16px)

  .error-phone
    color: var(--primary-color)
    font-weight: 700
    margin-bottom: rem(56px)

    +lte_ipad
      font-size: rem(28px)
      line-height: rem(30px)

    +gte_laptop
      font-size: 44px
      line-height: 48px

  .error-404,
  .error-500
    width: 100%
    max-width: rem(530px)

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''
      display: block
      padding-bottom: 72%
      width: 100%

  .error-404:before
    background-image: url('../images/pages/errors/404.svg')

  .error-500:before
    background-image: url('../images/pages/errors/500.svg')

  .button
    +button_red
    min-width: rem(160px)
