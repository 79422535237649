article.how_to_choose_box
  position: relative

  &:before
    background-image: url('../images/articles/how_to_choose_box/background.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    content: ''
    position: absolute

  +lte_ipad
    overflow: hidden

    &:before
      bottom: 0
      height: rem(248px)
      +left_ltr(50%)
      width: rem(248px)

  +gte_laptop
    &:before
      background: none
      height: 0
      width: 0

  +gte_desktop
    &:before
      background-image: url('../images/articles/how_to_choose_box/background.svg')
      height: 376px
      +right_ltr(-32px)
      top: 252px
      width: 376px

  .tabs
    display: flex

    +lte_ipad
      justify-content: space-between

    button
      align-items: baseline
      background: #e6ecf7
      box-sizing: border-box
      cursor: pointer
      display: flex
      font-size: rem(32px)
      font-weight: 700
      transition: background var(--transition-duration)

      +lte_ipad
        flex-grow: 1
        border-radius: rem(8px)
        padding: rem(8px) rem(12px) rem(8px)

        &:not(:last-child)
          +margin-right_ltr(rem(12px))

      +gte_laptop
        flex-shrink: 1
        border-radius: 12px
        padding: 18px 32px
        width: 192px

        &:not(:last-child)
          +margin-right_ltr(16px)

      &.is-active,
      &:hover
        background: var(--secondary-color)
        color: #fff

      .note
        font-weight: 400
        white-space: nowrap

        +lte_ipad
          display: block
          font-size: rem(10px)
          margin-top: rem(2px)
          +margin-left_ltr(4px)

        +gte_laptop
          font-size: 16px
          +margin-left_ltr(12px)

  .tabs-container
    .tab
      padding-top: rem(44px)

      .inner
        position: relative

        +lte_ipad
          padding-bottom: rem(320px)

          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(288px)
            position: absolute
            bottom: 0
            +left_ltr(calc(50% - #{rem(144px)}))
            width: rem(288px)

        +laptop
          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 400px
            position: absolute
            top: -110px
            +right_ltr(30px)
            width: 400px

        +gte_desktop
          +padding-right_ltr(500px)

          &:before
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 438px
            position: absolute
            top: -200px
            +right_ltr(20px)
            width: 438px

        p
          font-size: rem(16px)
          line-height: rem(22px)

        ul
          color: var(--text-base)
          font-size: rem(16px)
          line-height: rem(22px)
          margin-top: 0
          margin-top: rem(4px)
          vertical-align: top

          +lte_ipad

            &:last-child
              margin-bottom: rem(28px)

          +gte_laptop
            display: inline-block
            margin-bottom: 32px

            &:not(:last-child)
              +margin-right_ltr(40px)

        .read-more
          a
            +link_color
            display: block
            font-size: rem(12px)
            font-weight: 700

    .tab
      // generate images from originals
      // cp app/packs/images/articles/how_to_choose_box/original/* app/packs/images/articles/how_to_choose_box/; for image in app/packs/images/articles/how_to_choose_box/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
      @each $i in 1,2,3
        &-#{$i} .inner:before
          background-image: url('../images/articles/how_to_choose_box/slide_#{$i}.png')
          +retina_media
            background-image: url('../images/articles/how_to_choose_box/slide_#{$i}@2x.png')

        .webp &-#{$i} .slide-inner:before
          background-image: url('../images/articles/how_to_choose_box/slide_#{$i}.webp')
          +retina_media
            background-image: url('../images/articles/how_to_choose_box/slide_#{$i}@2x.webp')
