.b-tabs
  align-self: center
  background: var(--gray-100)
  border-radius: 64px
  max-width: 100%
  width: auto !important

  +lte_ipad
    // max-width: 100%
    // overflow-y: scroll
    padding: rem4(4px)

  +gte_laptop
    padding: 4px

  &.is-dark,
  &.is-gray
    background: rgba(#fff, 0.10)

    +lte_ipad
      backdrop-filter: blur(rem4(44px))

    +gte_laptop
      backdrop-filter: blur(44px)

  &.is-dark
    .tab
      color: #fff

      &.is-active
        color: var(--gray-25)

    .marker
      background: var(--gray-900)

  &.is-gray
    .tab
      color: var(--gray-25)

      &.is-active
        color: var(--gray-900)

  .inner
    display: flex
    overflow: hidden
    position: relative
    // fix swiper styles
    bottom: auto
    left: auto
    right: auto
    top: auto
    width: auto

  .tab,
  .marker
    +lte_ipad
      padding: rem4(8px) rem4(16px)

    +gte_laptop
      padding: 8px 16px

  .tab
    +p3(700)
    color: var(--gray-400)
    cursor: pointer
    display: flex
    transition: color var(--transition-duration)
    z-index: 2

    +lte_ipad
      gap: rem4(6px)

    +gte_laptop
      gap: 6px

    &.is-active
      color: var(--gray-900)

    span
      white-space: nowrap

  +lte_ipad
    &:not(.is-mobile-text) .tab:not(.is-active) span
      display: none

  .marker
    background: var(--gray-25)
    border-radius: 64px
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05)
    left: 0
    position: absolute
    top: 0
    z-index: 1
    transition: transform var(--transition-duration), width var(--transition-duration)

    +lte_ipad
      height: rem4(40px)

    +gte_laptop
      height: 40px
