.b-privacy_note
  align-items: flex-start
  margin: 0 0 rem(32px) 0 !important  // added !important to override .b-form label
  +text-align_ltr(left)

  &.disabled
    opacity: 0.86

    a
      color: var(--text-secondary) !important

  a
    +margin-left_ltr(rem(2px))

  label
    display: flex !important // added !important to override .b-form label
    white-space: normal
