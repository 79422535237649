.p-pages-dostavka article.slide-4
  h2
    +lte_ipad
      margin-bottom: rem(8px)

    +gte_laptop
      margin-bottom: 18px

  .note
    color: var(--text-base)
    font-size: rem(16px)
    line-height: 1.3
    text-align: center

    +lte_ipad
      margin-bottom: rem(44px)

    +gte_laptop
      margin-bottom: 68px

  ul
    +gte_laptop
      +fcolumn(2, 32px, 0px, 'li')

    li
      position: relative

      +lte_ipad
        margin-bottom: rem(32px)

    img
      display: block
      width: 100%

    .badge
      align-items: center
      background: #5096ff
      bottom: 0
      color: #ffffff
      display: flex
      font-weight: 700
      justify-content: center
      left: 50%
      line-height: 1.2
      position: absolute
      text-align: center
      transform: translateX(-50%)

      +lte_ipad
        border-radius: rem(10px) rem(10px) 0 0
        font-size: rem(24px)
        height: rem(51px)
        width: rem(184px)

      +gte_laptop
        border-radius: 20px 20px 0 0
        font-size: 32px
        height: 64px
        width: 324px
