=mobile_divider_top
  +lte_ipad
    border-top: rem(8px) solid #eaeaea
    padding-top: rem(24px)

=syncing
  position: relative

  &:before
    background: #fff
    content: ''
    height: 100%
    left: 0
    pointer-events: none
    position: absolute
    top: 0
    transition: opacity var(--transition-duration) ease
    width: 100%
    opacity: 0
    z-index: 1

  &.is-syncing
    pointer-events: none

    &:before
      opacity: 0.5

.p-orders-v2 .order-column
  +syncing
  display: flex
  flex-direction: column

  +lte_ipad
    gap: rem(24px)

  +gte_laptop
    gap: 30px

  .divider
    background: #eaeaea
    height: 1px

  .order-details
    +mobile_divider_top

    +lte_ipad
      padding-left: var(--container-padding-x)
      padding-right: var(--container-padding-x)

    +gte_laptop
      border-radius: 12px
      border: 2px solid #f1f1f1
      padding: 24px

    & > .divider
      margin: rem(24px) 0

  .box-info
    display: flex
    column-gap: rem(12px)

    .image
      background-position: center
      background-size: cover
      border-radius: rem(8px)
      flex-shrink: 0
      height: rem(104px)
      width: rem(125px)

    .inner
      display: flex
      flex-direction: column

      .name
        +link(var(--text-secondary))
        font-size: rem(14px)
        margin-bottom: rem(2px)

      .size
        align-items: center
        color: var(--text-strong)
        display: flex
        font-size: rem(16px)
        font-weight: 700
        line-height: 1.3

      .address
        align-items: center
        color: var(--text-base)
        display: flex
        font-size: rem(14px)
        gap: rem(8px)
        margin-top: auto

        +lte_ipad
          flex-wrap: wrap

        .address-divider
          background: #d7e0ef
          border-radius: 100%
          flex-shrink: 0
          height: rem(4px)
          width: rem(4px)

        .star
          align-items: center
          background-image: url('../images/pages/orders-v2/star.svg')
          background-repeat: no-repeat
          background-size: contain
          cursor: pointer
          display: flex
          flex-shrink: 0
          height: rem(16px)
          justify-content: center
          width: rem(16px)

        .rating
          white-space: nowrap

  .bill-details,
  .bill-details-inner
    display: flex
    flex-direction: column
    gap: rem(16px)

  .bill-details
    &.not-final
      gap: 0

  .bill-details
    .total-headline
      align-items: baseline
      color: var(--text-strong)
      display: flex

      .amount
        font-size: rem(24px)
        font-weight: 500
        line-height: rem(28px)
        margin-right: rem(8px)

    .payment_method-is_recurrent
      display: flex
      flex-direction: column
      gap: rem(8px)

      .payment_method:not(:last-child),
      .b-input
        margin-bottom: 0

    .submit
      .submit-inner
        +lte_ipad
          background: #fff
          border-top: 1px solid #f1f1f1
          bottom: 0
          display: flex
          gap: rem(16px)
          justify-content: space-between
          left: 0
          padding: rem(16px)
          position: fixed
          width: 100%
          z-index: $z-index-sticky_area

        &.is-mobile-only
          +gte_laptop
            display: none

        .mobile-details
          color: var(--text-strong)

          +gte_laptop
            display: none

          .amount
            font-size: rem(20px)
            font-weight: 700
            line-height: rem(24px)

          .paid_months-note
            cursor: pointer
            font-size: rem(14px)
            line-height: 1.3
            text-decoration-line: underline
            white-space: nowrap

            &:active
              text-decoration-line: none

      input[type='submit']
        +gte_laptop
          margin: 0 0 rem(8px) 0
          width: 100%

        +lte_ipad
          margin: 0
          flex-basis: 50%

      .submit-note
        color: var(--text-secondary)
        font-size: rem(14px)
        line-height: 1.3

    .bill-details-headline
      align-items: center
      color: var(--text-strong)
      cursor: pointer
      display: flex
      font-size: rem(20px)
      font-weight: 700
      gap: rem(8px)
      line-height: rem(24px)
      margin-top: rem(8px)
      user-select: none

      &.is-expanded:after
        transform: rotate(0deg)

      &:after
        background-image: url('../images/pages/orders-v2/chevron.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(16px)
        transform: rotate(180deg)
        transition: transform var(--transition-duration) ease
        width: rem(16px)

  .bill-details-inner
    & > .divider
      margin: rem(8px) 0

    .tariff-duration
      +lte_ipad
        display: flex
        flex-direction: column
        gap: rem(12px)

      +gte_laptop
        +fcolumn(2, rem(12px), 0, '.b-input')

      .b-input
        +lte_ipad
          margin-bottom: 0

    .b-input.tafiff_id
      scroll-margin-top: rem(10px)

    .b-input.tafiff_id
      .dropdown
        +gte_laptop
          width: calc(200% + rem(12px))

      .option
        display: flex
        gap: rem(4px)

        .name-discount
          +gte_laptop
            display: flex
            gap: rem(4px)

        .discount
          color: var(--text-secondary)
          font-weight: 400

        .price
          display: flex
          font-weight: 700
          gap: rem(4px)
          margin-left: auto

          .price-wo-discount
            color: var(--text-secondary)
            font-weight: 400
            text-decoration: line-through

    .detailing
      display: flex
      flex-direction: column
      gap: rem(12px)

      .item
        display: flex
        justify-content: space-between

        .name-benefit
          color: var(--text-base)
          font-size: rem(16px)
          line-height: 1.3

          .name
            display: flex
            align-items: center

          .benefit
            color: var(--text-secondary)
            font-size: rem(14px)
            margin-top: rem(2px)

          .b-tooltip-icon
            display: inline-flex

        .amount
          align-items: center
          color:  var(--text-base)
          display: flex
          font-size: rem(16px)
          gap: rem(8px)
          line-height: 1.3

          &.is-green
            color: #65bd9b

          .wo-discount
            color: var(--text-secondary)
            text-decoration: line-through

          .remove-coupon,
          .remove-simple_lock
            cursor: pointer
            height: rem(20px)
            width: rem(20px)

            svg
              width: rem(16px)
              height: rem(16px)

    .coupon-code
      .show-input
        color: var(--secondary-color)
        cursor: pointer
        font-size: rem(14px)
        font-weight: 700
        line-height: 1.3
        transition: color var(--transition-duration) ease

        +gte_laptop
          &:hover
            color: var(--secondary-color-active)

        &:active
          color: #3178e2

      .b-input
        flex-grow: 1
        margin: 0

        &.is-disabled
          pointer-events: none

          input
            color: var(--text-secondary) !important

      .input-container
        display: flex
        column-gap: rem(20px)

        #order_coupon_code
          text-transform: uppercase

        .button-container
          display: flex
          align-items: center
          justify-content: center
          width: rem(75px)

          .apply
            +link_color
            cursor: pointer
            font-size: rem(14px)
            font-weight: 700
            line-height: 1.3

          .spinner,
          .check-success
            background-repeat: no-repeat
            background-size: contain
            height: rem(24px)
            width: rem(24px)

          .spinner
            animation: loader 500ms infinite linear
            background-image: url('../images/pages/orders-v2/spinner.svg')

          .check-success
            background-image: url('../images/pages/orders-v2/check-success.svg')

        .error-message
          flex-basis: 100%

    .total
      color: var(--text-base)
      display: flex
      font-weight: 700
      justify-content: space-between
      line-height: 1.3

  .cancellation
    +mobile_divider_top

    +lte_ipad
      padding-left: var(--container-padding-x)
      padding-right: var(--container-padding-x)

    .note
      line-height: 1.3
      color: var(--text-base)

  .additionals
    +mobile_divider_top
    display: flex
    flex-direction: column
    gap: rem(16px)

    +lte_ipad
      padding-left: var(--container-padding-x)
      padding-right: var(--container-padding-x)

    h4
      margin: 0

  .additional
    +lte_ipad
      flex-wrap: wrap

    .circle.lock
      &:before
        background-image: url('../images/pages/orders-v2/lock.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(24px)
        width: rem(24px)

    .add
      +button_frame_red

      +lte_ipad
        flex-basis: 100%

      +gte_laptop
        min-width: rem(112px) !important

  .box-tooltip
    ul
      background: var(--text-base)
      border-radius: rem(8px)
      color: #fff
      display: flex
      justify-content: space-around
      line-height: inherit
      margin: rem(8px) 0
      padding: rem(8px)

      +lte_ipad
        font-size: rem(14px)

      +gte_laptop
        font-size: inherit

      li
        text-align: center

        b
          display: block

    & > p:not(:last-child)
      margin-bottom: rem(4px)
