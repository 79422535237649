article.boxes_grid
  .boxes
    display: grid
    justify-items: center
    row-gap: rem(30px)

    +iphone
      grid-template-columns: auto

    +laptop
    +ipad-portrait
      grid-template-columns: auto auto

    +gte_laptop
      grid-template-columns: auto auto auto auto
