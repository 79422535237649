.p-pages-root.p-v3 article.slide_5
  position: relative
  --article-background: #d92d20

  .qr-area
    +iphone
      display: none

    +gte_ipad
      align-items: center
      background: #fff
      border-radius: 100%
      bottom: 41px
      display: flex
      height: rem(144px)
      justify-content: center
      left: 50%
      margin-left: -63px
      position: absolute
      width: rem(144px)
      z-index: 1

    svg
      transform: rotate(-10deg)

  .inner
    display: flex

    +iphone
      flex-direction: column
      gap: rem(52px)
      overflow: hidden

    +gte_ipad
      gap: 30px
      padding-bottom: 109px !important
      padding-top: 109px !important
      position: relative

  .above-headline,
  h2,
  .below-headline
    color: #fff

  .below-headline
    +iphone
      margin-bottom: rem(24px)

    +gte_ipad
      margin-bottom: 32px

  .stores
    display: flex
    gap: rem(12px)

    .app-store
      background-image: url('../images/pages/root-v3/slide_5/app_store.svg')
      background-repeat: no-repeat
      background-size: contain

      +iphone
        height: rem(40px)
        width: rem(120px)

      +gte_ipad
        height: 44px
        width: 132px

    .google-play
      background-image: url('../images/pages/root-v3/slide_5/google_play.svg')
      background-repeat: no-repeat
      background-size: contain

      +iphone
        height: rem(40px)
        width: rem(135px)

      +gte_ipad
        height: 44px
        width: 149px

  picture
    +iphone
      margin-left: calc(var(--container-padding-x) * -1)
      margin-right: calc(var(--container-padding-x) * -1)

    img
      +iphone
        margin-bottom: calc(rem(-48px) - 2px)
        margin-left: rem(5px)
        width: 100%

      +gte_ipad
        position: absolute
        right: 0
        bottom: 0
