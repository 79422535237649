.p-pages-root.p-v4 article.slide_2,
.p-pages-root.p-v4 article.slide_12
  padding-bottom: 0
  padding-top: 0

  .swiper-v8
    overflow: hidden
    width: 100%

  .slides
    display: flex
    padding-left: var(--container-padding-x)
    padding-right: var(--container-padding-x)
    transition-timing-function: linear

    .slide
      align-items: center
      color: #d2d2d2
      display: flex
      font-weight: 700
      line-height: 1.25
      white-space: nowrap
      +padding_right_ltr(var(--spacing-6xl))
      // gap: var(--spacing-6xl)

      +lte_ipad
        font-size: rem4(23px)
        gap: rem4(12px)
        letter-spacing: rem4(-0.48px)

      +gte_laptop
        font-size: 23px
        gap: 12px
        letter-spacing: -0.48px

      &.slide:before
        background-repeat: no-repeat
        background-size: contain
        content: ''

        +lte_ipad
          height: rem4(24px)
          width: rem4(24px)

        +gte_laptop
          height: 24px
          width: 24px

.p-pages-root.p-v4 article.slide_2
  @each $i in 1,2,3,4,5,6,7,8
    .slide.feature_#{$i}:before
      background-image: url('../images/pages/root-v4/slide_2/feature_#{$i}.svg')

.p-pages-root.p-v4 article.slide_12
  @each $i in 1,2,3,4,5,6,7,8
    .slide.feature_#{$i}:before
      background-image: url('../images/pages/root-v4/slide_12/feature_#{$i}.svg')
