.p-pages-dostavka article.slide-1
  +lte_ipad
    padding-top: rem(14px)
    margin-bottom: rem(100px)

  +gte_laptop
    padding-top: 10px
    margin-bottom: 188px

  & > .container
    +lte_ipad
      padding: 0

    +gte_laptop
      display: flex

  .first
    +lte_ipad
      margin-bottom: rem(136px)
      padding-left: var(--container-padding-x)
      padding-right: var(--container-padding-x)

    h1
      +text-align_ltr(left)

      +lte_ipad
        font-size: rem(24px)
        line-height: 1.3
        margin-bottom: rem(8px)

      +gte_laptop
        font-size: 44px
        line-height: 48px
        margin: 0 0 16px 0

    .note
      color: var(--text-strong)
      font-size: rem(16px)
      line-height: 1.3

      +lte_ipad
        margin-bottom: rem(24px)

      +gte_laptop
        margin-bottom: 40px

    ul
      font-size: rem(16px)
      line-height: 1.3

      li
        +padding-left_ltr(rem(24px))
        position: relative

        &:not(:last-child)
          margin-bottom: rem(8px)

        &:before
          background-image: url('../images/pages/dostavka/slide_1/item_circle.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(12px)
          +left_ltr(0)
          position: absolute
          top: 50%
          transform: translateY(-50%)
          width: rem(12px)

  .second
    background: #5096ff
    box-shadow: rem(0px) rem(22px) rem(44px) rem(-12px) rgba(0, 0, 0, 0.1)
    position: relative

    +lte_ipad
      padding-bottom: rem(44px)
      +padding-left_ltr(var(--container-padding-x))
      +padding-right_ltr(var(--container-padding-x))
      padding-top: rem(44px)

    +gte_laptop
      +margin-left_ltr(auto)
      border-radius: 32px
      max-width: 412px
      padding: 56px 44px

    .title
      color: #ffff
      font-weight: 700
      text-align: center
      margin-bottom: rem(32px)

      +lte_ipad
        font-size: rem(20px)
        line-height: rem(28px)

      +gte_laptop
        font-size: 24px
        line-height: 28px

    input[type=text]
      text-align: center

    input[type=submit]
      +button_red
      width: 100%

      +lte_ipad
        margin-bottom: rem(24px)

      +gte_laptop
        margin-bottom: 32px

    .note
      color: #fff
      font-size: rem(12px)
      line-height: 1.3
      text-align: center

    img
      +left_ltr(0)
      position: absolute

      +lte_ipad
        top: rem(-92px)
        z-index: -1

      +laptop_new
        width: scale-laptop(367px * math.div(1024, 1200), 367px)

      +gte_laptop
        top: 129px
        transform: translateX(-100%)
        z-index: -1
