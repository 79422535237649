.b-faq
  & > ul > li
    border-bottom: 1px solid #eaeaea
    padding: rem(20px) 0
    position: relative

    &:last-child
      margin-bottom: rem(32px)

    &:before
      background-image: url(../images/blocks/b-faq/plus.svg)
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: rem(12px)
      +left_ltr(rem(4px))
      position: absolute
      top: rem(24px)
      width: rem(12px)

    &.active:before
      background-image: url(../images/blocks/b-faq/minus.svg)
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: rem(12px)
      +left_ltr(rem(4px))
      position: absolute
      top: rem(29px)
      width: rem(12px)

    &.active
      .body
        margin-top: rem(16px)
        // display: block
        // Animation - мне кажется или подтормаживает?
        opacity: 1
        max-height: rem(500px)
        overflow: scroll

  .label,
  .body
    +padding-left_ltr(rem(36px))

  .label
    +text_font
    cursor: pointer

  .body
    color: var(--text-base)
    font-size: rem(14px)
    line-height: rem(18px)
    // display: none
    // Animation - мне кажется или подтормаживает?
    max-height: 0
    opacity: 0
    transition: all 150ms ease-in-out

    a
      color: var(--primary-color)
      font-weight: 700
      text-decoration: underline

      &:hover
        text-decoration: none

    ul
      margin-top: rem(12px)

      li
        margin-bottom: rem(12px)
        +padding-left_ltr(rem(18px))
        position: relative

        &:before
          border-radius: rem(8px)
          border: 1px solid var(--primary-color)
          content: ''
          display: inline-block
          height: rem(8px)
          +left_ltr(0)
          position: absolute
          top: rem(6px)
          width: rem(8px)

        &:last-child
          margin-bottom: 0
    p
      margin-top: rem(12px)
      color: var(--text-base)
      font-size: rem(14px)
      line-height: rem(18px)
