.b-modal-alert
  .alert-container
    background-color: #fff
    padding: 24px 56px
    position: absolute
    top: 0
    width: 100%
    z-index: 9

    +lte_ipad
      padding: rem4(24px) rem4(16px)

    form
      display: flex
      align-items: center

      +lte_ipad
        flex-direction: column
        align-items: flex-start

    .message
      font-size: 20px

      +lte_ipad
        font-size: rem4(18px)
        line-height: rem4(26px)
        margin-bottom: rem4(24px)

      +gte_laptop
        +margin-right_ltr(20px)

      a
        color: var(--primary-color)

      b
        white-space: nowrap

    button.confirm
      +button_v4('primary', 'md')

  .overlay
    background-color: rgba(0,0,0,0.5)
    bottom: 0
    height: 100%
    +left_ltr(0)
    position: fixed
    +right_ltr(0)
    top: 0
    width: 100%
    z-index: 8
