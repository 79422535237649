.b-collection_image_item
  margin: 0 auto 1.5rem

  &:last-child
    margin-bottom: 0

  &.sortable-ghost
    opacity: 0.3

  .inner
    +clearfix
    position: relative
    display: inline-block

    a.image,
    a.pdf
      span.background-image
        background-color: #ebebeb
        background-position: center
        background-size: cover
        border-radius: 12px
        display: inline-block
        min-height: 148px
        min-width: 148px

      &.pdf span.background-image
        background-image: url('../images/blocks/b-collection_image_item/pdf.svg')
        background-repeat: no-repeat
        background-size: cover

    $size: 24px
    .delete,
    .drag-handle
      background: #fff
      border-radius: 100%
      box-shadow: 0px 0px 2px 0px rgba(#000, 0.5)
      font-size: 13px
      height: $size
      line-height: $size
      position: absolute
      text-align: center
      top: 5px
      width: $size

      &.delete
        color: #cd201f
        +right_ltr(5px)

        &:hover
          color: #a11918
          text-decoration: none

      &.drag-handle
        color: rgba(#000, 0.6)
        cursor: pointer
        cursor: -webkit-grab
        +right_ltr(40px)

        &:hover
          color: #000

        .draggable &,
        .sortable-ghost &
          color: rgba(#000, 0.3)
