=retina_media
  @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)
    @content

=background-inline-retina($path, $ext, $w: auto, $h: auto, $repeat: no-repeat)
  background: url($path + "." + $ext) $repeat
  background-size: $w $h

  +retina_media
    background: url($path + "@2x" + "." + $ext) $repeat
    background-size: $w $h
