.p-pages-root article.slide_seo
  +lte_ipad
    margin-top: rem(-20px)

  +gte_laptop
    margin-top: rem(-96px)

  margin-bottom: rem(64px)

  p
    margin-bottom: rem(10px)

  a
    +link(var(--primary-color))
