=button_new
  -webkit-tap-highlight-color: transparent
  -webkit-appearance: none
  align-items: center
  border-radius: rem(10px)
  border: 1px solid transparent
  cursor: pointer
  font-weight: bold
  height: rem(40px)
  justify-content: center
  text-align: center
  transition: color var(--transition-duration), border-color var(--transition-duration), background-color var(--transition-duration), box-shadow var(--transition-duration)

  +lte_ipad
    display: flex
    font-size: rem(12px)

  +gte_laptop
    display: inline-flex
    font-size: 14px
    min-width: 160px

  &.button-v3
    border-radius: rem(8px)
    padding: 0 rem(20px)

    +lte_ipad
      font-size: rem(14px)
      height: rem(44px)
      letter-spacing: rem(0.14px)

    +gte_laptop
      font-size: 16px
      height: 48px
      letter-spacing: 0.16px

  &:hover
    text-decoration: none

  &:active
    box-shadow: none

  &.disabled,
  &[disabled]
    background: #afb5c9
    cursor: default

    &:hover
      background: #afb5c9
      box-shadow: none
      color: #fff

=button_small_v3
  .root-v3 &
    // media queries are necessary here in order to add +1 nesting level
    // so styles of button_small_v3 have necesasry priority
    +lte_ipad
      height: rem(40px)
      letter-spacing: rem(0.14px)
      line-height: rem(14px)

    +gte_laptop
      height: 40px
      letter-spacing: 0.14px
      line-height: 14px

=button_width_auto
  // media queries are necessary here in order to add +1 nesting level
  // so styles of button_small_v3 have necesasry priority
  +lte_ipad
    min-width: auto
    width: auto

  +gte_laptop
    min-width: auto
    width: auto

=button_white
  +button_new
  background: #fff
  color: var(--primary-color)

  +gte_laptop
    &:hover
      box-shadow: 0px 8px 24px rgba(194, 56, 0, 0.5)
      color: var(--text-strong)

  &:active
    color: var(--primary-color)

  .root-v3 &
    background: #fff
    color: var(--text-base)

    +gte_laptop
      &:hover
        background: #f5f5f4
        box-shadow: none
        color: var(--text-base-hover)

    &:active
      background: #fff
      box-shadow: 0px 0px 0px 4px #e7e5e4
      color: var(--text-base-active)

=button_red
  +button_new
  background: var(--primary-color)
  color: #fff

  +gte_laptop
    &:hover
      background: var(--primary-color-hover)
      box-shadow: 0px 3px 10px rgba(244, 79, 12, 0.35)
      color: #fff

  &:active
    background: var(--primary-color-active)

  .root-v3 &
    color: var(--text-on-color)

    +gte_laptop
      &:hover
        color: var(--text-on-color-hover)
        box-shadow: none

    &:active
      color: var(--text-on-color-active)
      background: var(--primary-color)
      box-shadow: 0px 0px 0px 4px rgba(240, 68, 56, 0.24)

=button_blue
  +button_new
  background: var(--secondary-color)
  color: #fff

  +gte_laptop
    &:hover
      background: var(--secondary-color-hover)
      color: #fff

  &:active
    background: var(--secondary-color-active)

=button_frame_white
  +button_new
  background: transparent
  border-color: #fff
  color: #fff

  +gte_laptop
    &:hover
      background: var(--primary-color-hover)
      box-shadow: 0px 8px 24px rgba(194, 56, 0, 0.5)
      color: #fff

  &:active
    background: #ff8655
    color: #fff

  .root-v3 &
    color: var(--text-on-color)
    border-color: var(--border-on-color)

    +gte_laptop
      &:hover
        color: var(--text-on-color-hover)
        background: rgba(#fff, 0.2)
        box-shadow: none

    &:active
      color: var(--text-on-color-active)
      background: rgba(#fff, 0.3)

=button_frame_red
  +button_new
  background: transparent
  border-color: var(--primary-color)
  color: var(--primary-color)

  +gte_laptop
    &:hover
      box-shadow: 0px 4px 12px rgba(255, 124, 51, 0.24)
      border-color: var(--primary-color-hover)
      color: var(--primary-color-hover)

  &:active
    border-color: var(--primary-color-active)
    color: var(--primary-color-active)

=button_frame_white_inner
  +button_new
  background: transparent
  border: 2px solid #f1f1f1
  box-shadow: 0px 16px 32px -16px rgba(0, 0, 0, 0.15)
  box-sizing: border-box
  font-size: rem(14px)
  height: auto
  padding: rem(24px)

  &:hover
    border: 2px solid var(--secondary-color)
    box-shadow: 0px 16px 32px -16px rgba(80, 150, 255, 0.44)
    color: var(--text-headline)

  &:active
    border: 2px solid var(--secondary-color)
    box-shadow: none
    color: var(--text-headline)

=button_frame_arrow($color)
  @if $color != 'white' and $color != 'red'
    @error "unexpected color '#{$color}' in button_frame_arrow"

  &:after
    +rtl_flip
    background-image: url('../images/blocks/b-button/arrow_#{$color}.svg')
    background-repeat: no-repeat
    background-size: cover
    content: ''
    height: rem(9px)
    +margin-left_ltr(rem(8px))
    width: rem(22px)

=button_frame_arrow_back($color)
  +lte_ipad
    display: none

  +gte_laptop
    display: inline-flex
    font-size: 12px
    min-width: 100px

  @if $color != 'white' and $color != 'red'
    @error "unexpected color '#{$color}' in button_frame_arrow"

  &:before
    background-repeat: no-repeat
    background-size: cover
    background-image: url('../images/blocks/b-button/arrow_#{$color}.svg')
    content: ''
    height: rem(9px)
    +margin-right_ltr(rem(8px))
    width: rem(22px)

    +ltr
      transform: rotate(180deg)

=button_round($size: 0px)
  align-items: center
  font-size: rem(12px)

  +lte_ipad
    @if $size == 0px
      $size: rem(48px)
    border-radius: $size
    height: $size
    min-width: $size
    width: $size

  +gte_laptop
    @if $size == 0px
      $size: rem(52px)
    border-radius: $size
    height: $size
    min-width: $size
    width: $size
