article.questions
  +container

  .slides
    overflow: hidden

    .slides-wrapper
      display: flex

      +gte_laptop
        justify-content: center

      .blue-slide
        flex-shrink: 0

        +lte_ipad
          width: 100vw

        a.slide-inner
          align-items: center
          background-color: var(--secondary-color)
          background-position: center rem(22px)
          background-repeat: no-repeat
          border-radius: rem(12px)
          color: #fff
          display: block
          flex-shrink: 0
          height: rem(324px)
          margin: 0 rem(16px)
          overflow: hidden
          padding: 0 rem(30px)
          text-align: center
          text-decoration: none

          +lte_ipad
            padding: 0 rem(20px)
            height: rem(290px)

          +gte_laptop
            width: 348px

          &.find-box
            background-image: url('../images/articles/questions/find-box.svg')

          &.faq
            background-image: url('../images/articles/questions/faq.svg')

          &.variants
            background-image: url('../images/articles/questions/variants.svg')

          &:hover
            background-color: var(--primary-color)

          .title
            font-weight: 600
            margin: rem(156px) 0 rem(22px)

            +lte_ipad
              font-size: rem(24px)

            +gte_laptop
              font-size: 28px

          .description
            +p2

  .b-slider-pagination
    +lte_ipad
      margin-top: rem(11px)

    +gte_laptop
      display: none

  button.learn-more
    +button_red
    padding: 0 44px

    +lte_ipad
      display: inline-block
