.p-pages-root.p-v4 article.slide_8
  .container,
  .swiper
    +lte_ipad
      gap: var(--spacing-6xl)

    +gte_laptop
      gap: 72px

  h2.ru
    max-width: 825px

  .swiper
    +mx-auto
    display: flex
    flex-direction: column
    height: 100%
    width: 100%

    .swiper-wrapper
      overflow: hidden

  .b-tabs
    .tab
      &.slide_1:before
        +font-icon('\E81E')
      &.slide_2:before
        +font-icon('\E820')
      &.slide_3:before
        +font-icon('\E81F')
      &.slide_4:before
        +font-icon('\E806')

  ul
    +lte_ipad
      +fcolumn(2, rem4(12px), $offset: rem4(24px), $selector: 'li')

      li:nth-child(odd):last-child
        width: 100%

    +gte_laptop
      +fcolumn(5, 16px, $selector: 'li')
      justify-content: center

      &.size-6
        +fcolumn(6, 16px, $selector: 'li')

    li
      align-items: center
      color: var(--gray-25)
      display: flex
      flex-direction: column
      text-align: center

      +lte_ipad
        +p3(600)
        gap: rem4(12px)

      +gte_laptop
        +p2(600)
        gap: rem4(12px)

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''

      +lte_ipad
        &:before
          height: rem4(28px)
          width: rem4(28px)

      +gte_laptop
        &:before
          height: 32px
          width: 32px

    @each $i in 1, 2, 3, 4, 5
      &.slide_1 li.item_#{$i}:before
        background-image: url('../images/pages/root-v4/slide_8/slide_1/item_#{$i}.svg')

    @each $i in 1, 2, 3, 4, 5, 6
      &.slide_2 li.item_#{$i}:before
        background-image: url('../images/pages/root-v4/slide_8/slide_2/item_#{$i}.svg')

    @each $i in 1, 2, 3
      &.slide_3 li.item_#{$i}:before
        background-image: url('../images/pages/root-v4/slide_8/slide_3/item_#{$i}.svg')

    @each $i in 1, 2, 3, 4
      &.slide_4 li.item_#{$i}:before
        background-image: url('../images/pages/root-v4/slide_8/slide_4/item_#{$i}.svg')
