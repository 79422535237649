.p-pages-how_to_choose_box article.slide_6
  +gte_laptop
    margin-top: -40px

  .slide-vertical
    +lte_ipad
      padding-bottom: rem(275px)

    .headline
      +gte_laptop
        max-width: none

    .content
      +gte_laptop
        width: 470px

    ul
      li
        align-items: center

        +lte_ipad
          flex-direction: column
          +text-align_ltr(left)

        &.icon:before
          height: rem(52px)
          width: rem(52px)

          +lte_ipad
            margin-top: rem(15px)
            margin-bottom: rem(16px)

        &.icon-note:before
          background-image: url('../images/pages/how_to_choose_box/slide_4/icon-note.svg')

    picture
      +lte_ipad
        border-radius: 0 0 rem(30px) rem(30px)
