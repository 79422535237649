.b-phone_area
  margin-bottom: rem(16px)

  .phone-country_code
    +gte_laptop
      display: flex
      gap: 12px

    .b-input
      flex-shrink: 0
      margin-bottom: 0

    .country_code
      +lte_ipad
        margin-bottom: rem(16px)

    .tel
      +gte_laptop
        flex-grow: 1

  .send-confirmation:not(.is-order_registration-v2)
    +button_red
    width: 100%

  .code-area
    display: flex
    flex-direction: column
    margin-bottom: rem(16px)

    input#code
      width: rem(156px)

    .switch-to-phone
      margin-top: rem(16px)
