.p-pages .expandable-list-block,
.p-info_pages .expandable-list-block
  +lte_ipad
    margin-bottom: rem(68px)

  +gte_laptop
    margin-bottom: 62px

  h2
    +lte_ipad
      margin-bottom: rem(44px)
      text-align: center

    +gte_laptop
      margin-bottom: 74px

  .fc-outer
    +gte_laptop
      display: flex

  =toggler
    align-items: center
    border: 1px solid #e2e4e9
    box-shadow: 4px 4px 15px rgba(175, 181, 201, 0.6)
    cursor: pointer
    display: flex
    justify-content: center
    user-select: none

    +lte_ipad
      border-radius: rem(4px)
      font-size: rem(18px)
      height: rem(66px)

    +gte_laptop
      border-radius: 10px
      font-size: 24px
      height: 91px

    &.active
      cursor: default

    &:not(.active):active
      background: #e2e4e9

  .f-navigation
    +margin-right_ltr(8.95%)
    width: 32.21%

    +lte_ipad
      display: none

    li
      +toggler

      +gte_laptop
        border-radius: 0
        border: none
        box-shadow: none

      &:not(:first-child)
        border-top: 1px solid rgba(175, 181, 201, 0.5)

      &:active,
      &.active
        border-radius: rem(10px)
        border: 1px solid #e2e4e9
        box-shadow: 4px 4px 15px rgba(175, 181, 201, 0.6)

        & + li
          border-top-color: transparent

  .mobile-toggler
    +toggler
    +lte_ipad
      background: #fff
      margin-top: rem(-1px)

    +gte_laptop
      display: none

  .f-content
    +lte_ipad
      background: #f6f6f6
      border-radius: rem(4px)
      min-height: auto !important

    +gte_laptop
      width: 58.84%

    li
      &:not(.active) > .inner
        display: none
