.p-agreements,
.p-users-boxes.p-boxes,
.p-bills,
.p-dashboards,
.p-payment_methods,
.p-photos,
.p-delete_accounts,
.p-users
  .container-profile
    position: relative

    +gte_laptop
      width: 100%
      +padding-left_ltr(264px)

    .main.container
      background-color: #f4f4f4
      min-height: 100vh
      width: 100%
      max-width: 100%

      +lte_ipad
        padding-top: rem(10px)

      +gte_laptop
        padding: 40px 48px

      h1
        font-size: rem(32px)
        font-weight: 700
        line-height: rem(36px)
        +text-align_ltr(left)
        margin-bottom: rem(40px)

      .block-container
        background: #fff
        border-radius: rem(12px)
        border: 1px solid #fff
        box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
        box-sizing: border-box
        margin-bottom: rem(24px)

        +lte_ipad
          padding: rem(16px)

        +gte_laptop
          padding: 24px

        &.hovered
          &:hover
            cursor: pointer
            text-decoration: none
            border: 1px solid var(--secondary-color)

          &:active
            box-shadow: none
            border: 1px solid #fff

        h2
          font-size: rem(20px)
          font-weight: bold
          line-height: rem(24px)
          margin-bottom: rem(24px)

      a.back-inner
        +button_frame_red
        +button_frame_arrow_back('red')
        margin-bottom: rem(24px)

  aside.left
    background-color: #fff

    +lte_ipad
      display: none

    +gte_laptop
      +left_ltr(0)
      position: absolute
      top: 0
      width: 264px

    nav
      box-sizing: border-box
      padding: rem(24px)

      ul
        a
          color: var(--text-base)
          border-radius: rem(12px)
          display: block
          font-size: rem(16px)
          line-height: 1.3
          padding: rem(20px) rem(24px)
          position: relative
          text-decoration: none

          .root-v1 &
            --current-background: #{rgba(#ff7c33, 0.1)}
          .root-v3 &
            --current-background: #{rgba(#b42318, 0.1)}

          &:hover
            background: var(--current-background)
            color: var(--primary-color)

          &:active
            background: var(--primary-color-hover)
            color: #fff

        li
          &.active > a
            background: var(--primary-color)
            color: #fff

  // ----------------------------------
  // Simple Form
  // ----------------------------------
  label.form-label
    color: var(--text-base)
    font-size: rem(14px)
    margin: 0 0 rem(6px) 0

  select.form-control
    border-radius: rem(12px)
    border: 1px solid #eaeaea
    font-size: rem(14px)
    max-width: 100%
    padding: rem(10px)
