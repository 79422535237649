.p-pages-root.p-v3 article.slide_3
  .above-headline
    margin-bottom: var(--padding-md)
    text-align: center

  h2
    text-align: center

  .below-headline
    color: var(--text-strong)
    text-align: center

    +gte_laptop
      +mx-auto
      max-width: 450px

  .grid
    display: grid

    +lte_ipad
      gap: 8px
      grid-template-areas: "a c" "b b" "d e"
      grid-template-columns: repeat(2, 2fr)
      margin: rem(32px) 0

    +gte_laptop
      gap: 30px
      grid-template-areas: "a b c" "d b e"
      grid-template-columns: repeat(3, 3fr)
      margin: 64px 0

    .cell
      border-radius: rem(20px)
      display: flex
      flex-direction: column
      overflow: hidden
      position: relative

      +lte_ipad
        gap: rem(8px)
        padding: rem(12px) rem(12px) rem(16px) rem(12px)

      +gte_laptop
        gap: 16px
        padding: 16px 20px 20px 20px

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''
        flex-shrink: 0
        position: relative
        z-index: 2

        +lte_ipad
          height: 88px
          width: 88px

        +gte_laptop
          height: 120px
          width: 120px

      &:after
        background-repeat: no-repeat
        background-size: contain
        content: ''
        flex-shrink: 0
        left: 0
        position: absolute
        top: 0
        z-index: 1

      @each $cell in cell_1, cell_2, cell_3, cell_4, cell_5
        &.#{$cell}
          &:before
            background-image: url('../images/pages/root-v3/slide_3/#{$cell}/image.svg')

          &:after
            +lte_ipad
              background-image: url('../images/pages/root-v3/slide_3/#{$cell}/background-mobile.svg')

            +gte_laptop
              background-image: url('../images/pages/root-v3/slide_3/#{$cell}/background-desktop.svg')

      &.cell_1
        background: #f5f3ff
        grid-area: a

        &:after
          +lte_ipad
            height: rem(123px)
            width: rem(140px)

          +gte_laptop
            height: 161px
            width: 217px

      &.cell_2
        background: #fef3f2
        grid-area: b

        +lte_ipad
          flex-direction: row
          padding: rem(16px)

        +gte_laptop
          gap: 40px
          justify-content: center

        &:before
          +gte_laptop
            height: 224px
            width: 224px

        &:after
          +lte_ipad
            height: rem(119px)
            width: rem(150px)

          +gte_laptop
            height: 273px
            top: 88px
            width: 330px

      &.cell_3
        background: #eff8ff
        grid-area: c

        &:after
          +lte_ipad
            height: rem(128px)
            width: rem(140px)

          +gte_laptop
            height: 167px
            width: 184px

      &.cell_4
        background: #fef6ee
        grid-area: d

        &:after
          +lte_ipad
            height: rem(119px)
            width: rem(121px)

          +gte_laptop
            height: 161px
            width: 166px

      &.cell_5
        background: #f5fbee
        grid-area: e

        &:after
          +lte_ipad
            height: rem(132px)
            width: rem(123px)

          +gte_laptop
            height: 173px
            width: 166px

    .inner
      display: flex
      flex-direction: column
      gap: var(--padding-sm)

    .title
      +w1
      color: var(--text-strong)
      position: relative
      z-index: 3

    .text
      position: relative
      z-index: 3

      +lte_ipad
        +p4

      +gte_laptop
        +p2

  .button-container
    +gte_laptop
      display: grid
      gap: 30px
      grid-template-areas: "a b c"
      grid-template-columns: repeat(3, 3fr)

    a
      +button_red

      +gte_laptop
        grid-area: b

      b
        font-weight: 700
