$types: children country_house documents furniture other relocation repair season sport summer temp tires vacation valuables winter
.b-service
  overflow: hidden
  position: relative

  +lte_ipad
    background: transparent
    border: 1px solid #e2e4e9
    height: rem(70px)

    &:not(:last-child)
      border-bottom: none

    &:active
      background: radial-gradient(40.65% 40.65% at 55.16% 59.35%, rgba(255, 168, 17, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #f2642b
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
      color: #fff

  +gte_laptop
    background: #fafbfd
    border-radius: 10px
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)

    &:hover
      background: radial-gradient(40.65% 40.65% at 55.16% 59.35%, rgba(255, 168, 17, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #f2642b
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
      color: #fff

  .outer
    display: block

    &:hover
      color: inherit

    +gte_laptop
      padding-top: 60%

      .inner
        display: flex
        height: 100%
        +left_ltr(0)
        position: absolute
        top: 0
        width: 100%

  img
    +rtl_flip
    position: absolute
    +right_ltr(0)
    bottom: 0

    &.mobile
      +gte_laptop
        display: none

    &.desktop
      +lte_ipad
        display: none

  .title
    +lte_ipad
      font-size: rem(16px)
      font-weight: 500
      line-height: rem(18px)
      padding-bottom: 0
      +padding-left_ltr(rem(12px))
      +padding-right_ltr(0)
      padding-top: rem(18px)

    +laptop
      font-size: scale-between(17px, 20px, 1024px, 1280px)
      line-height: scale-between(22px, 28px, 1024px, 1280px)
      padding: scale-between(26px, 44px, 1024px, 1280px) 16px 0

    +gte_desktop
      font-size: 20px
      line-height: 28px
      padding: 30px 16px 0

  &.archive .title
    +gte_laptop
      max-width: 200px
