.b-spell_check
  align-items: center
  background: var(--primary-color)
  border-radius: rem(34px)
  bottom: rem(20px)
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.25)
  color: #fff
  cursor: pointer
  display: none
  font-size: rem(14px)
  height: rem(34px)
  left: 50%
  padding: 0 rem(16px)
  position: fixed
  transform: translateX(-50%)
  transition: background var(--transition-duration)
  user-select: none
  z-index: 999

  +gte_laptop
    &:hover
      background: var(--primary-color-hover)

  &:active
    background: var(--primary-color-active)

  &:before
    background-image: url('../images/blocks/b-spell_check/icon.svg')
    background-repeat: no-repeat
    background-size: contain
    color: inherit
    content: ''
    display: inline-block
    height: rem(18px)
    +margin-right_ltr(rem(8px))
    width: rem(18px)

  &.is-active
    display: flex
