.b-stat_line
  display: flex
  flex-wrap: nowrap
  font-size: rem(14px)
  line-height: rem(14px)

  &:not(:last-child)
    +lte_ipad
      margin-bottom: rem(10px)

    +gte_laptop
      margin-bottom: 20px

  .label
    color: var(--text-secondary)
    flex-shrink: 0

    +lte_ipad
      +padding-right_ltr(rem(20px))
      width: rem(180px)

    +lte_laptop
      max-width: rem(236px)

    +gte_laptop
      +padding-right_ltr(30px)

  .value
    font-weight: 700
    +margin-left_ltr(auto)
    +text-align_ltr(right)

  .hint
    color: #b3b8bf
    flex-basis: 100%
    font-size: rem(12px)
    line-height: rem(16px)
    margin-top: rem(9px)
