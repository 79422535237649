.p-pages-how_to_choose_box article.slide_2
  +lte_ipad
    margin-bottom: rem(60px)

  +gte_laptop
    margin-bottom: 110px

  .slides
    +lte_ipad
      padding-top: rem(26px)

    +gte_ipad
      padding-top: 48px

  .slides-outer
    position: relative

    +lte_ipad
      padding-top: rem(80px)
      // height: 156px

    +gte_laptop
      padding-bottom: 294px
      padding-top: 249px
      // height: 560px

    &:before,
    &:after
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute
      z-index: 1

      +laptop
        background-image: url('../images/pages/how_to_choose_box/slide_2/ellipse-laptop.svg')
        width: 1200px

      +gte_laptop
        height: 91px
        left: 50%
        transform: translateX(-50%)

      +gte_desktop
        background-image: url('../images/pages/how_to_choose_box/slide_2/ellipse-desktop.svg')
        width: 1920px

    &:before
      +lte_ipad
        background-image: url('../images/pages/how_to_choose_box/slide_2/ellipse-mobile.svg')
        background-size: cover
        height: 8.75vw
        top: -4.375vw
        width: 100vw

      +gte_laptop
        top: -45px

    &:after
      +lte_ipad
        display: none

      +gte_laptop
        bottom: -45px

    & > picture
      left: 50%
      position: absolute
      transform: translateX(-50%)
      z-index: -1

      +laptop
        width: 1200px

      +gte_desktop
        width: 1920px

      img
        width: 100%

      &.background-top
        top: 0

        +lte_ipad
          width: 100%

      &.background-bottom
        bottom: 0

        +lte_ipad
          display: none

  .check-list
    +lte_ipad
      +button_red
      transition: transform var(--transition-duration)

    +gte_laptop
      +button_white
      bottom: -142px
      left: 50%
      position: absolute
      transform: translateX(-50%)
      width: 240px

  .slides
    +lte_ipad
      overflow: hidden
      padding-bottom: rem(60px) // offset for check-list button

      .slides-wrapper
        display: flex

      .b-slider-pagination
        position: relative
        transition: transform var(--transition-duration), margin-bottom var(--transition-duration)
        z-index: 1

        &[data-index='0']
          +iphone
            margin-bottom: rem(-89px)
            transform: translateY(#{rem(-89px)})

          +ipad
            margin-bottom: rem(-118px)
            transform: translateY(#{rem(-118px)})

        &[data-index='2']
          margin-bottom: rem(-106px)
          transform: translateY(#{rem(-106px)})

    +gte_laptop
      +mx-auto
      max-width: 1112px

    .slide-vertical-outer
      +lte_ipad
        flex-shrink: 0
        +padding-left_ltr(var(--container-padding-x))
        +padding-right_ltr(var(--container-padding-x))

  .slide-vertical
    &.slide-1
      +lte_ipad
        padding-bottom: rem(284px)

    &.slide-2
      +lte_ipad
        padding-bottom: 280px

      .content
        +laptop-new
          padding-top: scale-laptop(50px, 77px)

      ul
        +laptop-new
          padding-top: scale-laptop(14px, 28px)

    &.slide-3
      +lte_ipad
        padding-bottom: rem(296px)

    ul
      li
        &.icon:before
          height: rem(56px)
          width: rem(56px)

        &.icon-code:before
          background-image: url('../images/pages/how_to_choose_box/slide_2/icon-code.svg')

          +lte_ipad
            margin: rem(6px) auto rem(11px)

        &.icon-lock:before
          background-image: url('../images/pages/how_to_choose_box/slide_2/icon-lock.svg')

          +lte_ipad
            margin: rem(20px) auto rem(13px)

        +gte_laptop
          &:not(:last-child)
            margin-bottom: 20px

    .picture
      +gte_laptop
        position: relative
        flex-grow: 1

      picture
        position: absolute
        line-height: 0

    +gte_laptop
      &.slide-1 .content,
      &.slide-3 .content
        +margin-right_ltr(auto)

      &.slide-2 .content
        +margin-left_ltr(auto)

    &.slide-1 .picture,
    &.slide-2 .picture,
    &.slide-3 .picture
      &:before
        background-image: url('../images/pages/how_to_choose_box/slide_2/background_circle.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        position: absolute

    .picture-1
      &:before
        +lte_ipad
          bottom: rem(80px)
          height: rem(177px)
          +right_ltr(rem(76px))
          width: rem(177px)

        +gte_laptop
          height: 348px
          +right_ltr(95px)
          top: 60px
          width: 348px

      picture
        +lte_ipad
          bottom: rem(12px)
          +left_ltr(auto)
          +right_ltr(rem(35px))

        +gte_laptop
          bottom: 0
          +right_ltr(38px)

    .picture-2
      &:before
        +lte_ipad
          bottom: rem(73px)
          height: rem(174px)
          +right_ltr(rem(63px))
          width: rem(174px)

        +gte_laptop
          height: 377px
          +left_ltr(41px)
          top: -86px
          width: 377px

      picture
        +lte_ipad
          +left_ltr(auto)
          +right_ltr(48px)
          bottom: 0

        +gte_laptop
          +left_ltr(121px)
          bottom: 0

    .picture-3
      &:before
        +lte_ipad
          bottom: rem(34px)
          height: rem(190px)
          +left_ltr(rem(50px))
          width: rem(190px)

        +gte_laptop
          height: 400px
          +right_ltr(51px)
          top: 28px
          width: 400px

      picture
        +lte_ipad
          +left_ltr(0)
          bottom: 0

        +gte_laptop
          +right_ltr(0)
          bottom: 0
