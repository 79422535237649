.animated-collapse
  overflow: hidden
  transition: height var(--transition-duration) ease-out, margin-bottom var(--transition-duration) ease-out, padding-bottom var(--transition-duration) ease-out, padding-top var(--transition-duration) ease-out, margin-top var(--transition-duration) ease-out !important
  // transition: height 1s ease-out, margin-bottom 1s ease-out, padding-bottom 1s ease-out, padding-top 1s ease-out, margin-top 1s ease-out !important
  height: auto
  min-height: 0 !important

.animated-expand
  overflow: hidden
  transition: height var(--transition-duration) ease-in, margin-bottom var(--transition-duration) ease-in, padding-bottom var(--transition-duration) ease-out, padding-top var(--transition-duration) ease-out, margin-top var(--transition-duration) ease-out !important
  // transition: height 1s ease-in, margin-bottom 1s ease-in, padding-bottom 1s ease-out, padding-top 1s ease-out, margin-top 1s ease-out !important
  height: auto
  min-height: 0 !important
