.p-sitemap
  h1
    margin-bottom: rem(30px)

  .sitemap
    ul
      margin: 0
      a
        +link(var(--primary-color))

      li
        margin-bottom: rem(8px)

        ul
          list-style: disc
          +margin-left_ltr(rem(20px))
          margin-top: rem(8px)
