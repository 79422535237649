.p-vacancies-apply
  h1
    +lte_ipad
      margin-bottom: rem(36px)

    +gte_laptop
      margin-bottom: 58px

  .b-form
    background: var(--secondary-color)

    +lte_ipad
      padding: rem(24px) 0 rem(48px)

    +gte_laptop
      padding: 51px 0 53px
      position: relative

    .container
      &:before
        +gte_laptop
          background-image: url('../images/pages/vacancies/apply-background-desktop.svg')
          background-size: contain
          content: ''
          height: 950px
          +margin-left_ltr(-374px)
          pointer-events: none
          position: absolute
          top: 52px
          width: 951px

  $input-offset: 285px
  .b-input
    +lte_ipad
      margin-bottom: rem(16px)

    +gte_laptop
      display: flex
      flex-wrap: wrap
      margin-bottom: 48px

    &:first-child label,
    &.textarea label
      +lte_ipad
        display: block

    label
      align-self: center
      color: #fff
      white-space: normal

      +lte_ipad
        display: none
        font-size: rem(20px)
        line-height: rem(28px)
        margin-bottom: rem(16px)
        text-align: center
        width: 100%

      +gte_laptop
        font-size: 24px
        line-height: 28px
        +margin-right_ltr(65px)
        width: #{$input-offset - 65px}

    input,
    select,
    textarea
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
      color: var(--text-base)
      font-size: rem(16px)
      line-height: 1.3

      +lte_ipad
        border-radius: 30px
        padding: rem(12px) rem(20px)

      +gte_laptop
        border-radius: 20px
        padding: 20px 38px

        +placeholder
          color: var(--text-secondary)

    input,
    select
      height: 64px

      +gte_laptop
        width: 555px

    select
      font-weight: bold

    textarea
      +lte_ipad
        height: rem(141px)

      +gte_laptop
        flex-grow: 1
        height: 258px
        width: auto

    .error-message
      +gte_laptop
        +margin-left_ltr($input-offset)

  .b-input.textarea
    +lte_ipad
      margin: rem(32px) 0

    label
      align-self: flex-start

  .upload
    border-radius: rem(20px)
    border: 3px solid #fff
    color: #fff
    cursor: pointer
    font-size: rem(16px)
    line-height: 1.2
    position: relative
    transition: border-color var(--transition-duration)

    +lte_ipad
      margin-bottom: rem(32px)
      padding: rem(12px) rem(24px)
      text-align: center

    +gte_laptop
      margin-bottom: 48px
      +margin-left_ltr($input-offset)
      +margin-right_ltr(0)
      margin-top: -18px
      padding: 53px 190px 56px
      position: relative

      &:before
        background-image: url('../images/pages/vacancies/upload-icon.svg')
        background-size: contain
        content: ''
        height: 67px
        +left_ltr(57px)
        position: absolute
        top: 48px
        width: 78px

      &:hover
        border-color: var(--primary-color-hover)

    &:active
      border-color: var(--primary-color-active)

    .filename
      font-weight: 700
      position: absolute
      top: 50%
      transform: translateY(-50%)

    .first
      font-weight: 700

    .second
      +lte_ipad
        font-size: rem(12px)

    input[type=file]
      display: none

  .privacy
    color: #fff
    cursor: pointer
    display: flex
    line-height: rem(18px)

    +lte_ipad
      font-size: rem(12px)
      margin-bottom: rem(32px)

    +gte_laptop
      font-size: 16px
      margin-bottom: 40px
      +margin-left_ltr($input-offset)
      max-width: 600px

    input
      display: none

      &:checked + .icon
        background-image: url('../images/pages/vacancies/checkmark-checked.svg')

    .icon
      background-image: url('../images/pages/vacancies/checkmark.svg')
      background-repeat: no-repeat
      background-size: contain
      display: inline-block
      flex-shrink: 0

      +lte_ipad
        height: rme(32px)
        +margin-right_ltr(rem(25px))
        width: rem(35px)

      +gte_laptop
        height: 24px
        +margin-right_ltr(20px)
        width: 26px

    a
      text-decoration: underline

  .submit-container
    +lte_ipad
      justify-content: center
      display: flex

      input
        width: rem(160px)

    +gte_laptop
      +margin-left_ltr($input-offset)

  .error-message
    color: #fff

    +gte_laptop
      +margin-left_ltr($input-offset)
