@import './responsive';

@mixin fcolumn-responsive(
  $columns: '', // one argument to rule all columns
  $portrait-columns: '',
  $landscape-columns: '',
  $ipad-columns: '',
  $laptop-columns: '',
  $desktop-columns: '',
  $gutter: '',
  $offset: '',
  $gutter-mobile: 0px,
  $offset-mobile: 0px,
  $gutter-tablet: '',
  $offset-tablet: '',
  $gutter-laptop: '',
  $offset-laptop: '',
  $gutter-desktop: 0px,
  $offset-desktop: 0px,
  $selector: '.f-column'
) {
  @if ($columns != '') {
    @if ($portrait-columns == '') {
      $portrait-columns: $columns;
    }
    @if ($landscape-columns == '') {
      $landscape-columns: $columns;
    }
    @if ($ipad-columns == '') {
      $ipad-columns: $columns;
    }
    @if ($laptop-columns == '') {
      $laptop-columns: $columns;
    }
    @if ($desktop-columns == '') {
      $desktop-columns: $columns;
    }
  } @else {
    @if ($portrait-columns == '') {
      $portrait-columns: 1;
    }
    @if ($landscape-columns == '') {
      $landscape-columns: 2;
    }
  }

  @if ($gutter != '') {
    $gutter-mobile: $gutter;
    $gutter-desktop: $gutter;
  }
  @if ($offset != '') {
    $offset-mobile: $offset;
    $offset-desktop: $offset;
  }
  @if ($offset-tablet == '') {
    $offset-tablet: $offset-desktop;
  }
  @if ($gutter-tablet == '') {
    $gutter-tablet: $gutter-desktop;
  }
  @if ($offset-laptop == '') {
    $offset-laptop: $offset-desktop;
  }
  @if ($gutter-laptop == '') {
    $gutter-laptop: $gutter-desktop;
  }
  @if ($laptop-columns == '') {
    $laptop-columns: $ipad-columns;
  }
  @if ($desktop-columns == '') {
    $desktop-columns: $laptop-columns;
  }

  @if ($portrait-columns != 0) {
    @include iphone-portrait {
      @include fcolumn($portrait-columns, $gutter-mobile, $offset-mobile, $selector);
    }
  }

  @if ($landscape-columns != 0) {
    @include iphone-landscape {
      @include fcolumn($landscape-columns, $gutter-mobile, $offset-mobile, $selector);
    }
  }

  @if ($ipad-columns != 0) {
    @include ipad {
      @include fcolumn($ipad-columns, $gutter-tablet, $offset-tablet, $selector);
    }
  }

  @if ($laptop-columns != $desktop-columns) {
    @include laptop {
      @include fcolumn($laptop-columns, $gutter-laptop, $offset-laptop, $selector);
    }
    @include gte_desktop {
      @include fcolumn($desktop-columns, $gutter-desktop, $offset-desktop, $selector);
    }
  } @else  {
    @include gte_laptop {
      @include fcolumn($desktop-columns, $gutter-desktop, $offset-desktop, $selector);
    }
  }
}

@mixin fcolumn($columns, $gutter, $offset: 0, $selector: '.f-column') {
  // background: rgba(green, 0.5);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$offset;
  @include margin-left_ltr(-$gutter);

  & > #{$selector} {
    // background: rgba(blue, 0.5);
    margin-bottom: $offset;
    @include margin-left_ltr($gutter);
    @include fcolumn-size(math.div(1, $columns), $gutter);
  }
}

@mixin fcolumn-size($ratio, $gutter) {
  width: calc(#{100% * $ratio} - #{$gutter});
}
