// TODO: remove and set in application.sass when old design is completely removed
=font-v1
  font-family: Lato, sans-serif

=font-cursive
  font-family: Caveat, sans-serif

=font-v4
  font-family: Manrope, sans-serif

=font-icon($content: '')
  font-family: minisklad_icons
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased
  /* Support for Safari and Chrome. */
  // text-rendering: optimizeLegibility
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale
  /* Support for IE. */
  flex-shrink: 0
  font-feature-settings: 'liga'
  font-weight: normal
  letter-spacing: normal
  text-transform: none

  @if length($content) != 0
    content: $content
