.p-pages-root.p-v4 article.slide_10_2
  +container
  padding-bottom: 0

  header
    h2,
    .note
      +lte_ipad
        +text-align_ltr(left)
        width: 100%

      +gte_laptop
        max-width: 580px

  .columns
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem4(24px)

    +gte_laptop
      gap: 24px

    .slides,
    .text
      flex-basis: 50%

  .slides
    display: flex
    flex-direction: column

    +lte_ipad
      gap: rem4(16px)

    +gte_laptop
      gap: 16px

  .slide
    align-items: center
    background: var(--gray-100)
    display: flex
    font-weight: 600

    +lte_ipad
      border-radius: rem4(24px)
      font-size: rem4(18px)
      gap: rem4(16px)
      line-height: rem4(22px)
      padding: rem4(24px)

    +gte_laptop
      border-radius: 24px
      font-size: 18px
      gap: 16px
      line-height: 22px
      padding: 32px

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''
      flex-shrink: 0

      +lte_ipad
        height: rem4(32px)
        width: rem4(32px)

      +gte_laptop
        height: 32px
        width: 32px

    @each $slide in slide_1, slide_2, slide_3
      &.#{$slide}:before
        background-image: url('../images/pages/root-v4/slide_11//#{$slide}.svg')

  .text
    +p3
    letter-spacing: 0.16px
