.b-breadcrumbs
  color: var(--text-secondary)
  font-size: rem(14px)
  text-align: center

  .root-v1 &
    padding-bottom: rem(24px)
    padding-top: rem(11px)
    width: auto

  .root-v3 &
    padding-bottom: rem(4px)

  +lte_ipad
    display: none

  & > span:not(:last-child)
    &:after
      content: '/'
      padding: 0 rem(4px)
