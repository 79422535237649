.b-top_menu.v1
  &:not(.is-mobile-active)
    +lte_ipad
      position: sticky
      top: 0
      z-index: $z-index-top-menu
      // Добавляем отступ вниз, т.к. теперь в мобильной версии
      // хлебные крошки не отображаем.
      margin-bottom: rem(8px)

    +gte_laptop
      padding: 14px 0 14px

    .container
      +lte_ipad
        background: #fff
        padding-top: rem(0px)

    .mobile-trigger
      +lte_ipad
        +button_red
        +button_round(rem(43px))

        &:before
          background-image: url(../images/blocks/b-top_menu/mobile-trigger.svg)
          height: rem(15px)
          width: rem(20px)

    .logo
      +gte_laptop
        height: 50px
        width: 189px

      &.logo-ru
        background-image: url(../images/blocks/b-top_menu/logo-red.svg)

      &.logo-et
        background-image: url(../images/blocks/b-top_menu/logo-red-et.svg)

    .b-locale_switcher-v3
      +lte_ipad
        display: none

    .container
      align-items: center
      display: flex
      max-width: 100%

    .phone
      +lte_ipad
        display: none

      +gte_laptop
        .number
          color: var(--text-strong)
          display: inline-block
          font-size: 18px
          font-weight: 700
          margin-bottom: 3px

        .hint
          color: var(--text-base)
          font-size: 10px
          font-weight: 400

    .b-switcher
      display: none

    .navigation
      +lte_ipad
        display: none

    .sign-in
      +gte_laptop
        +button_red
        +button_round

        &:before
          color: inherit
          content: attr(data-text-desktop)

  &.is-mobile-active
    .menu-background
      background-repeat: no-repeat
      background-size: cover
      height: 100%
      +left_ltr(0)
      opacity: 1
      position: fixed
      top: 0
      width: 100%
      z-index: $z-index-popup

    &[data-background='red']
      .menu-background
        background-image: url('../images/blocks/b-top_menu/red/menu_background.svg')

      .mobile-trigger
        &:before
          background-image: url('../images/blocks/b-top_menu/red/menu_hide.svg')

        &:active:before
          background-image: url('../images/blocks/b-top_menu/red/menu_hide-active.svg')

    &[data-background='blue']
      .menu-background
        background-image: url('../images/blocks/b-top_menu/blue/menu_background.svg')

      .mobile-trigger
        &:before
          background-image: url('../images/blocks/b-top_menu/blue/menu_hide.svg')

        &:active:before
          background-image: url('../images/blocks/b-top_menu/blue/menu_hide-active.svg')

    .container
      position: relative
      z-index: $z-index-popup + 1

    .logo-container
      margin-bottom: rem(14px)

    .mobile-trigger
      +button_white
      +button_round(rem(43px))

      &:before
        height: rem(15px)
        width: rem(15px)

    .phone
      color: #fff

      +lte_ipad
        text-align: center
        margin-bottom: 24px

      .number
        display: inline-block

        +lte_ipad
          font-size: rem(16px)
          font-weight: bold
          line-height: rem(21px)
          margin-bottom: rem(8px)

        +laptop
          font-size: 32px
          line-height: 42px

      .hint
        +lte_ipad
          font-size: rem(10px)
          line-height: rem(12px)

        +gte_laptop
          font-size: 16px
          line-height: 19px

    .b-locale_switcher-v3
      bottom: rem(-30px)
      left: 50%
      position: absolute
      transform: translateX(-50%)
      width: auto

    .navigation
      flex-direction: column
      text-align: center
      z-index: $z-index-popup

      .link
        +link_white
        display: inline
        font-weight: bold

        +lte_ipad
          font-size: rem(20px)
          line-height: rem(24px)
          margin-bottom: rem(23px)

        +gte_laptop
          font-size: 20px
          line-height: 24px
          margin-bottom: 45px

    .sign-in
      display: none

  .menu-background
    opacity: 0
    transition: var(--transition-duration) opacity

  .logo-container
    display: flex

    +lte_ipad
      align-items: center
      flex-grow: 1
      height: rem(64px)
      margin-top: rem(2px)

  .logo
    +logo
    flex-shrink: 0

    +lte_ipad
      margin-top: rem(4px)

  .mobile-trigger
    margin-top: rem(-4px)

    +lte_ipad
      +margin-left_ltr(auto)

    +gte_desktop-new
      display: none

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''

  .navigations
    +gte_laptop
      +margin-left_ltr(auto)

      .navigation:first-child
        display: flex! important

      .navigation:not(:first-child)
        display: none! important

  .navigation
    align-items: center
    display: flex

    .link
      +gte_laptop
        font-size: 14px
        font-weight: 700
        white-space: nowrap

        &[data-mobile-only]
          display: none

  .logo,
  .navigation .link,
  .b-locale_switcher-v3
    @media screen and (min-width: 1024px) and (max-width: 1140px)
      +margin-right_ltr(scale-between(18px, 30px, 1024px, 1140px))

    @media screen and (min-width: 1140px)
      +margin-right_ltr(44px)
