$primary: #f44f0c
$primary-hover: #ff7c33
$primary-active: #cf4c17

$secondary: #5096ff
$secondary-active: #3178e2
$secondary-hover: #50b5ff

html
  // colors
  --background-page-body: #fff
  --primary-color: #{$primary}
  --primary-color-hover: #{$primary-hover}
  --primary-color-active: #{$primary-active}
  --primary-color-inactive: #fc9a74
  --secondary-color: #{$secondary}
  --secondary-color-hover: #{$secondary-hover}
  --secondary-color-active: #{$secondary-active}
  --secondary-color-inactive: #84caff
  --link-color: var(--primary-color)
  --link-color-hover: var(--primary-color-hover)
  --link-color-active: var(--primary-color-active)
  --link-decoration: none
  --link-decoration-hover: underline
  --link-decoration-active: none
  --text-base: #5f5f5f
  --text-strong: #333
  --text-secondary: #939393
  --gray-25: #fcfcfc
  --gray-50: #fafafa
  --gray-100: #f4f4f5
  --gray-200: #e4e4e7
  --gray-300: #d1d1d6
  --gray-400: #a0a0ab
  --gray-500: #70707b
  --gray-700: #3f3f46
  --gray-800: #26272b
  --gray-900: #18181b
  --transition-duration: .3s

  &.root-v3
    --background-page-body: #fdfdfc
    --primary-color: #d92d20
    --primary-color-hover: #b42318
    --primary-color-active: #f04438
    --primary-color-inactive: #fda29b
    --text-base: #292524
    --text-base-hover: #57534e
    --text-base-active: #79716B
    --text-strong: #1c1917
    --text-on-color: #fff
    --text-on-color-hover: #{rgba(#fff, 0.9)}
    --text-on-color-active: #{rgba(#fff, 0.8)}
    --text-secondary: #79716b
    --border-base: #44403c
    --border-secondary: #e7e5e4
    --border-on-color: #fdfdfc

  &.root-v4
    --primary-color: #ff4f00
    --primary-color-hover: #d94300
    --primary-color-active: #b23700
    --primary-color-inactive: #ffcab2
    --text-base: var(--gray-900)
    --text-strong: var(--gray-900)
    --text-secondary: var(--gray-400)
    --link-color: var(--primary-color)
    --link-color-hover: var(--primary-color)
    --link-color-active: #ff7c33
    --link-decoration: none
    --link-decoration-hover: underline
    --link-decoration-active: underline
    --default-background: linear-gradient(96deg, #414147 -0.76%, #70707b 100%)

  // containers
  --container-max-width: 1200px
  +lte_ipad
    --container-padding-x: #{rem(16px)}
  +laptop-new
    --container-padding-x: #{scale-laptop(22px, 45px)}
  +gte_desktop-new
    --container-padding-x: 45px

  &.root-v4
    --container-max-width: #{1296px + 32px * 2}
    +lte_ipad
      --container-padding-x: #{rem4(16px)}
    +gte_laptop
      --container-padding-x: 32px

  // headlines
  --headline-font-weight: bold
  --headline-margin: #{rem(34px)}
  --h1-letter-spacing: normal
  --h4-letter-spacing: normal
  +lte_ipad
    --h1-font-size: #{rem(32px)}
    --h1-line-height: #{rem(36px)}
    --h2-font-size: #{rem(20px)}
    --h2-line-height: #{rem(24px)}
    --h3-font-size: #{rem(18px)}
    --h3-line-height: #{rem(22px)}
    --h4-font-size: #{rem(16px)}
    --h4-line-height: #{rem(20px)}
  +gte_laptop
    --h1-font-size: 40px
    --h1-line-height: 48px
    --h2-font-size: 32px
    --h2-line-height: 36px
    --h3-font-size: 24px
    --h3-line-height: 28px
    --h4-font-size: 28px
    --h4-line-height: 32px

  &.root-v4
    --h1-font-size: var(--h2-font-size)
    --h1-font-weight: var(--h2-font-weight)
    --h1-line-height: var(--h1-line-height)
    --h1-letter-spacing: var(--h1-letter-spacing)

    +lte_ipad
      --headline-margin: #{rem4(34px)}
      --h2-font-size: #{rem4(32px)}
      --h2-font-weight: 700
      --h2-line-height: 1.2
      --h2-letter-spacing: #{rem4(-0.64px)}

    +gte_laptop
      --headline-margin: 34px
      --h2-font-size: 48px
      --h2-font-weight: 700
      --h2-line-height: 1.2
      --h2-letter-spacing: -1.44px

  &.root-v3
    --p1-font-size: #{rem(20px)}
    --p1-font-weight: 400
    --p1-line-height: #{rem(32px)}
    --p1-letter-spacing: #{rem(0.4px)}

    --p2-font-size: #{rem(16px)}
    --p2-font-weight: 400
    --p2-line-height: #{rem(24px)}
    --p2-letter-spacing: #{rem(0.32px)}

    --p3-font-size: #{rem(14px)}
    --p3-font-weight: 400
    --p3-line-height: #{rem(20px)}
    --p3-letter-spacing: #{rem(0.28px)}

    --p4-font-size: #{rem(12px)}
    --p4-font-weight: 400
    --p4-line-height: #{rem(16px)}
    --p4-letter-spacing: #{rem(0.24px)}

    --p5-font-size: #{rem(10px)}
    --p5-font-weight: 400
    --p5-line-height: #{rem(14px)}
    --p5-letter-spacing: #{rem(0.2px)}

    // --b1-font-size: #{rem(16px)}
    --b1-font-weight: 700
    // --b1-line-height: 1
    --b1-letter-spacing: #{rem(0.16px)}

    // --b2-font-size: #{rem(14px)}
    // --b2-font-weight: 600
    // --b2-line-height: 1
    --b2-letter-spacing: #{rem(0.14px)}

  &.root-v4
    --p1-font-weight: 500
    --p1-letter-spacing: normal
    +lte_ipad
      --p1-font-size: #{rem4(20px)}
      --p1-line-height: #{rem4(28px)}
    +gte_laptop
      --p1-font-size: 20px
      --p1-line-height: 28px

    --p2-font-weight: 500
    --p2-letter-spacing: normal
    +lte_ipad
      --p2font-size: #{rem4(18px)}
      --p2-line-height: #{rem4(26px)}
    +gte_laptop
      --p2-font-size: 18px
      --p2-line-height: 26px

    --p3-font-weight: 500
    --p3-letter-spacing: normal
    +lte_ipad
      --p3-font-size: #{rem4(16px)}
      --p3-line-height: #{rem4(24px)}
    +gte_laptop
      --p3-font-size: 16px
      --p3-line-height: 24px

    --p4-font-weight: 500
    --p4-letter-spacing: normal
    +lte_ipad
      --p4-font-size: #{rem4(14px)}
      --p4-line-height: #{rem4(20px)}
    +gte_laptop
      --p4-font-size: 14px
      --p4-line-height: 20px

    --p5-font-weight: 500
    --p5-letter-spacing: normal
    +lte_ipad
      --p5-font-size: #{rem4(12px)}
      --p5-line-height: #{rem4(18px)}
    +gte_laptop
      --p5-font-size: 12px
      --p5-line-height: 18px

    --b1-font-weight: 600
    --b1-line-height: 1
    --b1-letter-spacing: normal
    +lte_ipad
      --b1-font-size: #{rem4(16px)}
    +gte_laptop
      --b1-font-size: 16px

    --b2-font-weight: 600
    --b2-line-height: 1
    --b2-letter-spacing: normal
    +lte_ipad
      --b2-font-size: #{rem4(14px)}
    +gte_laptop
      --b2-font-size: 14px

    +lte_ipad
      // no mobile styles yet
    +gte_laptop
      --h4-font-size: 24px
      --h4-line-height: 28px
      --h4-letter-spacing: -0.48px

  // paddings
  &.root-v3
    +lte_ipad
      --padding-between-blocks: #{rem(56px)}
      --padding-sm: #{rem(4px)}
      --padding-md: #{rem(8px)}
      --padding-lg: #{rem(12px)}
      --padding-xl: #{rem(16px)}
      --padding-2xl: #{rem(24px)}
      --padding-5xl: #{rem(48px)}

    +gte_laptop
      --padding-between-blocks: 96px
      --padding-sm: 8px
      --padding-md: 12px
      --padding-lg: 16px
      --padding-xl: 24px
      --padding-2xl: 32px
      --padding-5xl: 56px

  &.root-v4
    +lte_ipad
      --padding-between-blocks: #{rem4(64px)}
      --spacing-md: #{rem4(8px)}
      --spacing-6xl: #{rem4(48px)}
    +gte_laptop
      --padding-between-blocks: 120px
      --spacing-md: 8px
      --spacing-6xl: 48px

  // inputs
  --input-height: #{rem(48px)}
  --input-line-height: 1.3
  --input-letter-spacing: normal
  --input-background-color: #fff
  --input-background-color-hover: #fff
  --input-background-color-focus: #fff
  --input-border-radius: #{rem(12px)}
  --input-border-color: #eaeaea
  --input-border-color-hover: var(--primary-color-hover)
  --input-border-color-focus: var(--secondary-color)
  --input-border-color-error: var(--primary-color-active)
  --input-border-width-focus: 1px
  --input-label-color: var(--text-secondary)
  --input-label-color-focus: var(--secondary-color)
  --input-padding-x: #{rem(16px)}
  +lte_ipad
    --input-font-size: #{rem(16px)}
  +gte_laptop
    --input-font-size: 14px

// local v3 styles applied to specific elements only (marked by v3 class)
.v3
  --input-line-height: #{rem(24px)}
  --input-letter-spacing: #{rem(0.32px)}
  --input-font-size: #{rem(16px)}
  --input-border-radius: #{rem(8px)}
  --input-border-color-focus: var(--text-base-hover)
  --input-border-color-hover: #fda29b
  --input-border-color-error: var(--primary-color)
  --input-label-color-focus: var(--text-secondary)

.v4
  --input-font-size: var(--p3-font-size)
  --input-line-height: var(--p3-line-height)

  +lte_ipad
    --input-letter-spacing: #{rem4(0.16px)}
    --input-height: #{rem4(56px)}
    --input-border-radius: #{rem4(12px)}
    --input-padding-x: #{rem4(20px)}

  +gte_laptop
    --input-letter-spacing: 0.16px
    --input-height: 56px
    --input-border-radius: 12px
    --input-padding-x: 20px

  --input-background-color: var(--gray-50)
  --input-background-color-hover: var(--gray-50)
  --input-background-color-focus: #fff
  --input-border-color: var(--gray-200)
  --input-border-color-hover: var(--gray-300)
  --input-border-color-focus: var(--gray-900)
  --input-border-width-focus: 2px
  --input-label-color-focus: var(--gray-500)

$webkit-tap-highlight-color: rgba(201, 224, 253, 0.7)

$metro_line_socolnicheskaya_color: rgb(218, 33, 40)
$metro_line_zamoskvoretskaya_color: rgb(72, 184, 94)
$metro_line_arbatsko_pokrovskaya_color: rgb(0, 120, 191)
$metro_line_filyovskaya_color: rgb(0, 193, 243)
$metro_line_koltsevaya_color: rgb(137, 78, 53)
$metro_line_kaluzhsko_rizhskaya_color: rgb(245, 130, 32)
$metro_line_tagansko_krasnopresnenskaya_color: rgb(142, 71, 155)
$metro_line_kalininskaya_color: rgb(255, 198, 26)
$metro_line_solntsevskaya_color: rgb(255, 198, 26)
$metro_line_serpukhovsko_timiryazevskaya_color: rgb(161, 162, 163)
$metro_line_lyublinsko_dmitrovskaya_color: rgb(180, 212, 69)
$metro_line_big_circle_line_color: rgb(106, 201, 200)
$metro_line_butovskaya_color: rgb(172, 191, 227)
$metro_line_monorail_color: rgb(0, 120, 191)
$metro_line_mcc_color: rgb(255, 168, 175)
$metro_line_nekrasovskaya_color: rgb(230, 106, 192)
$metro_line_first_diameter_color: rgb(250, 164, 0)
$metro_line_second_diameter_color: rgb(234, 65, 132)

$z-index-top-menu: 10
$z-index-sticky_area: $z-index-top-menu + 10
$z-index-popup: $z-index-sticky_area + 10
$z-index-modal: $z-index-popup + 10
