.b-kramdown
  font-size: rem(16px)
  line-height: 1.5

  h1,
  h2,
  h3,
  h4,
  h5
    color: var(--text-strong) !important

  p,
  ul,
  ol,
  blockquote
    color: var(--text-strong) !important
    margin-bottom: rem(10px) !important

  code
    background-color: #eff3fa
    border-radius: 4px
    padding: rem(2px) rem(4px)

  ul
    list-style: disc
    +margin-left_ltr(rem(18px))

  ol
    list-style: decimal
    +margin-left_ltr(rem(18px))

  a
    color: var(--primary-color)

    &:hover
      text-decoration: underline
