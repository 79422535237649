.b-cookies-privacy
  background-color: var(--primary-color)
  bottom: 0
  color: #fff
  +left_ltr(0)
  padding: rem(16px)
  position: fixed
  width: 100%
  z-index: 1000
  text-align: center

  a.button
    +button_white

    +lte_ipad
      margin: rem(20px) 0

    +gte_laptop
      margin: 0 20px

  a.link
    +link_white
    font-weight: 600
