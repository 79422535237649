=button-large
  -webkit-appearance: none
  border-radius: rem(5px)
  cursor: pointer
  display: inline-block
  font-size: rem(22px)
  line-height: rem(54px)
  min-width: rem(200px)
  padding: 0 rem(24px)
  text-align: center
  user-select: none
  white-space: nowrap

=button-mini
  font-size: rem(17px)
  line-height: rem(34px)

=button-orange-hover
  background: #fa7912
  box-shadow: 0px 4px 25px rgba(250, 121, 18, 0.25)
  color: #fff

=button-orange-active
  background: darken(#f2642b, 2%)
  color: #fff

=button-orange($is-mini: false)
  +button-large
  background: #f2642b
  box-shadow: 0px 4px 25px rgba(242, 100, 43, 0.25)
  color: #fff
  transition: border-color var(--transition-duration), background-color var(--transition-duration), color var(--transition-duration), box-shadow var(--transition-duration)

  @if $is-mini
    +button-mini

  +gte_laptop
    &:hover
      +button-orange-hover

  &:active
    +button-orange-active

  &.disabled,
  &[disabled]
    // do not use transparent background.
    // it breaks background of fixed positioned buttons
    background: #afb5c9
    box-shadow: none
    cursor: not-allowed

=button-frame($is-mini: false)
  +button-large
  border: 1px solid #f2642b
  color: #f2642b

  @if $is-mini
    +button-mini

  +gte_laptop
    &:hover
      border-color: #fa7912
      color: #fa7912

  &:active
    border-color: #f15425
    color: #f15425
