.p-pages-delivery
  #container_wrapper
    padding-bottom: 0
    position: relative

  #map
    height: 100%
    min-height: 750px
    width: 100%

    +lte_ipad
      display: none

  .delivery
    background: #fff
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 3px 4px -3px rgba(0,0,0,.3)
    padding: rem(26px) rem(24px) rem(22px)

    h1
      color: #3e3f43
      margin-bottom: rem(10px)
      font-size: rem(26px)

    +lte_ipad
      min-height: calc(100vh - #{rem(230px)})

    +gte_laptop
      +left_ltr(52px)
      position: absolute
      top: 18px
      z-index: 2

  .ya-taxi-widget
    display: none

  .b-input.storage_id
    margin-bottom: rem(20px)

    #storage_id
      +lte_ipad
        max-width: 100%

      +gte_laptop
        max-width: 350px
