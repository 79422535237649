.p-storages_with_staffs
  .container-modal
    background-color: #fff
    +left_ltr(0)
    min-height: 100vh
    position: absolute
    text-align: center
    top: 0
    width: 100vw
    z-index: 1001

    +lte_ipad
      padding: rem(48px) rem(16px) rem(10px)

    +gte_laptop
      padding: 120px 0 40px

    .icon
      background-image: url('../images/pages/storages_with_staffs/icon-map.svg')
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      margin-bottom: rem(16px)
      width: 100%

      +lte_ipad
        height: rem(44px)

      +gte_laptop
        height: 56px

    h1
      font-weight: 700
      margin-bottom: rem(24px)

      +lte_ipad
        font-size: rem(20px)
        line-height: rem(24px)

      +gte_laptop
        font-size: 24px
        line-height: 28px

    .note
      color: var(--text-base)
      line-height: 1.3
      margin: 0 auto rem(40px)
      max-width: 540px

      +lte_ipad
        font-size: rem(14px)

    .storage
      border-radius: rem(12px)
      border: 2px solid #f1f1f1
      box-shadow: 0px 22px 44px -22px rgba(0, 0, 0, 0.15)
      box-sizing: border-box
      display: flex
      max-width: 540px
      margin: 0 auto rem(16px)
      padding: rem(24px)

      +lte_ipad
        flex-direction: column
        align-items: flex-start

      +gte_laptop
        flex-direction: row
        justify-content: space-between
        align-items: center

      .info
        +text-align_ltr(left)

        .address
          font-weight: 700
          line-height: rem(19px)
          margin-bottom: rem(4px)

        .worktime
          color: var(--text-secondary)
          font-size: rem(14px)
          line-height: 1.3

          +lte_ipad
            margin-bottom: rem(24px)

      .phone
        color: var(--text-base)

    a.back
      background-image: url('../images/pages/storages_with_staffs/close.svg')
      background-repeat: no-repeat
      background-size: contain
      display: block
      position: absolute

      +lte_ipad
        height: rem(16px)
        +right_ltr(rem(16px))
        top: rem(16px)
        width: rem(16px)

      +gte_laptop
        height: 24px
        +right_ltr(60px)
        top: 60px
        width: 24px
