.b-map
  position: relative
  width: 100%

  +lte_ipad
    height: rem4(648px)

  +gte_ipad
    border-radius: 32px
    box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
    height: 662px
    overflow: hidden
