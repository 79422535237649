.b-tags
  display: flex
  flex-wrap: wrap
  justify-content: center
  row-gap: rem(8px)

  &.seo-page
    margin-top: rem(30px)

  .tag
    background-color: #eff3fa
    border-radius: rem(30px)
    display: inline-flex
    +margin-right_ltr(rem(12px))
    align-items: center

    +lte_ipad
      font-size: rem4(10px)
      height: rem4(28px)
      padding: 0 rem4(16px)

    +gte_laptop
      font-size: 14px
      padding: 0 20px
      height: 40px

    &.is-active
      background: var(--secondary-color)
      color: #fff

  a.tag[href]
    transition: background-color var(--transition-duration), color var(--transition-duration) 
    text-decoration: none

    +gte_laptop
      &:hover
        background-color: var(--primary-color-hover)
        color: #fff

    &:active
      background-color: var(--primary-color-active)
      color: #fff

    &.is-active
      +gte_laptop
        &:hover
          background-color: var(--secondary-color-hover)
          color: #fff

      &:active
        background-color: var(--secondary-color-active)
        color: #fff
