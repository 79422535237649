footer.b-footer.v4
  +p3(600)
  color: var(--gray-500)
  font-weight: 600
  padding: var(--spacing-6xl) 0px
  margin-top: var(--padding-between-blocks)

  +gte_laptop
    padding-top: 64px

  & > .container
    display: flex
    flex-direction: column

    +lte_ipad
      gap: rem4(39px)

    +gte_laptop
      gap: 64px

  .cc-links
    display: grid

    +lte_ipad
      grid-template-columns: repeat(2, 1fr)
      gap: rem4(32px)

      ul:nth-child(5):last-child
        grid-column: 1 / -1 // makes the last item span both columns 

    +gte_laptop
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
      gap: 24px

    ul
      display: flex
      flex-direction: column

      +lte_ipad
        flex-basis: 50%
        gap: 12px

      +gte_laptop
        flex-basis: 16.6666666667%
        gap: var(--spacing-md)

    a,
    button
      +link_color(var(--gray-200))

      &.franchise
        border-radius: 24px
        border: 1px solid rgba(255, 255, 255, 0.30)
        background: rgba(255, 255, 255, 0.10)
        margin-left: calc(var(--spacing-md) * -1)

        +lte_ipad
          padding: rem4(2px) var(--spacing-md) rem4(3px) var(--spacing-md)

        +gte_laptop
          padding: 2px var(--spacing-md) 3px var(--spacing-md)

  .cc-logo-copy
    border-top: 1px solid var(--gray-800)
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem4(24px)
      padding-top: rem4(26px)

    +gte_laptop
      padding-top: 32px
      justify-content: space-between

    .logo
      background-repeat: no-repeat
      background-size: contain
      display: block

      // 155px - ширина лого на десктопе, 127px - ширина лого для телефонов
      $scale: math.div(127px, 155px)
      &.logo-ru
        background-image: url(../images/blocks/b-footer-v4/logo-ru.svg)

        +lte_ipad
          height: rem4(32px * $scale)
          width: rem4(155px * $scale)

        +gte_laptop
          height: 32px
          width: 155px

      &.logo-et
        background-image: url(../images/blocks/b-footer-v4/logo-et.svg)

        +lte_ipad
          height: rem4(32px * $scale)
          width: rem4(137px * $scale)

        +gte_laptop
          height: 32px
          width: 137px

    .copy
      font-weight: 500
      +lte_ipad
        letter-spacing: rem(0.16px)
      +gte_laptop
        letter-spacing: 0.16px
