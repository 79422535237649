article.no_available_boxes
  background-color: var(--secondary-color)
  color: #fff
  position: relative

  +lte_ipad
    &:before
      background-image: url('../images/articles/no_available_boxes/background-mobile.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: rem(400px)
      position: absolute
      top: rem(-170px)
      +left_ltr(calc(50% - #{rem(200px)}))
      width: rem(400px)

  +gte_laptop
    &:before
      background-image: url('../images/articles/no_available_boxes/background.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: 500px
      position: absolute
      top: 0
      +left_ltr(calc(50% - 860px))
      width: 1450px

  .container
    display: flex
    align-items: center

    +lte_ipad
      padding: rem(56px) 0
      flex-direction: column

    +gte_laptop
      height: 498px

    .side
      flex-basis: rem(100px)
      flex-grow: 1

    .left
      position: relative

      +lte_ipad
        padding-bottom: rem(70px)
        margin-bottom: rem(32px)

        &.sad-icon
          &:before
            background-image: url('../images/articles/no_available_boxes/icon-sad.svg')
            background-repeat: no-repeat
            background-size: contain
            bottom: 0
            content: ''
            height: rem(32px)
            +left_ltr(calc(50% - #{rem(16px)}))
            position: absolute
            width: rem(32px)

      +gte_laptop
        &.sad-icon
          &:before
            background-image: url('../images/articles/no_available_boxes/icon-sad.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: 56px
            +left_ltr(0)
            position: absolute
            top: -82px
            width: 56px

      h2
        color: #fff

        +gte_laptop
          text-align: left

      p
        color: #fff

        +lte_ipad
          max-width: 250px
          text-align: center

        +gte_laptop
          max-width: 440px

    .right
      .label
        line-height: 24px
        margin-bottom: 26px

        +lte_ipad
          max-width: 250px
          text-align: center

        b
          font-weight: 700

      button.lead
        +button_red
        +lte_ipad
          width: 100%
