.p-pages-how_to_choose_box article.slide_8
  & > .container
    position: relative

    &:before
      +gte_laptop
        background-image: url('../images/pages/how_to_choose_box/slide_8/circles-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        display: block
        height: 94px
        position: absolute
        top: 95px
        width: 83px

        +laptop-new
          +right_ltr(scale-laptop(30px, 113px))

        +gte_desktop-new
          +right_ltr(113px)

  .subtitle-note
    +lte_ipad
      text-align: center
      margin-bottom: rem(92px)

    +gte_laptop
      margin-bottom: 142px
      max-width: 655px

  .path
    display: flex
    flex-direction: column

    +lte_ipad
      margin-bottom: rem(60px)

    +gte_laptop
      margin-bottom: 128px

    &:before
      content: ''
      position: absolute

      +lte_ipad
        background: #e6ecf7
        height: rem(1100px)
        left: 50%
        transform: translateX(-50%)
        width: 50px

      +gte_laptop
        background-image: url('../images/pages/how_to_choose_box/slide_8/path-desktop.svg')
        background-repeat: no-repeat
        background-size: contain
        height: 609px
        +margin-left_ltr(55px)
        margin-top: 37px
        width: 855px

  .point
    position: relative
    z-index: 1

    +lte_ipad
      &:not(:last-child)
        margin-bottom: rem(77px)

    +gte_laptop
      &:nth-child(2n)
        +margin-left_ltr(auto)

    .inner
      background: #fff
      border-radius: rem(30px)
      display: flex
      flex-direction: column

      +lte_ipad
        border: 2px solid #e6ecf7
        box-shadow: 0px 16px 32px rgba(95, 120, 143, 0.15)
        box-sizing: content-box
        height: rem(224px)
        text-align: center
        width: rem(288px)

      +gte_laptop
        border: 2px solid #f1f1f1
        box-shadow: 0px 22px 56px -22px rgba(95, 120, 143, 0.15)
        height: 220px
        justify-content: center
        width: 348px

        &:nth-child(2n + 1)
          +padding-left_ltr(55px)

        &:nth-child(2n)
          +padding-left_ltr(72px)

    &:before
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

      +lte_ipad
        height: rem(96px)
        left: 50%
        top: rem(-32px)
        transform: translateX(-50%)
        width: rem(96px)

      +gte_laptop
        height: 114px
        top: 50%
        transform: translateY(-50%)
        width: 114px

    +gte_laptop
      &:nth-child(2n + 1)
        +margin-left_ltr(74px)

        &:before
          +left_ltr(-74px)

      &:nth-child(2n)
        +margin-right_ltr(75px)

        &:before
          +right_ltr(-75px)

    @each $i in 1,2,3,4
      &[data-number='#{$i}']:before
        +lte_ipad
          background-image: url('../images/pages/how_to_choose_box/slide_8/#{$i}-mobile.svg')

        +gte_laptop
          background-image: url('../images/pages/how_to_choose_box/slide_8/#{$i}-desktop.svg')

    &[data-number='2']
      +gte_laptop
        margin-top: -120px

      picture
        +laptop-new
          +left_ltr(scale-laptop(-130px, -163px))
          top: scale-laptop(-139px, 155px)

        +gte_desktop-new
          +left_ltr(-163px)
          top: -155px

    &[data-number='3']
      .inner
        +lte_ipad
          height: rem(412px)

      +gte_laptop
        margin-top: 48px

      .discount-container
        +lte_ipad
          margin: rem(40px) rem(16px) 0

        +gte_laptop
          margin-bottom: 0
          +margin-left_ltr(-57px)
          +margin-right_ltr(0)
          margin-top: 404px
          position: absolute
          width: 348px

      .discount-inner
        align-items: center
        background: #5096ff
        border-radius: rem(86px)
        height: rem(86px)
        display: flex
        +padding-left_ltr(rem(12px))

        +lte_ipad
          margin-bottom: rem(17px)
          +padding-right_ltr(rem(8px))

        +gte_laptop
          margin-bottom: 14px
          +padding-right_ltr(73px)

      .discount-percent
        align-items: center
        background: #fff
        border-radius: rem(64px)
        color: var(--text-strong)
        display: flex
        flex-shrink: 0
        font-size: rem(24px)
        font-weight: bold
        height: rem(64px)
        justify-content: center
        line-height: 1.2
        text-align: center
        width: rem(64px)

        +lte_ipad
          +margin-right_ltr(rem(12px))

        +gte_laptop
          +margin-right_ltr(22px)

      .discount-text
        color: #fff
        font-weight: bold

        +lte_ipad
          font-size: rem(14px)
          line-height: 1.2

        +gte_laptop
          font-size: 16px
          line-height: 1.3

      .discount-hint
        color: var(--text-secondary)
        font-size: rem(12px)
        line-height: 1.3

    &[data-number='4']
      +gte_laptop
        margin-top: -69px

      picture
        +laptop-new
          +left_ltr(scale-laptop(-167px, -207px))
          top: scale-laptop(-147px, -164px)

        +gte_desktop-new
          +left_ltr(-207px)
          top: -164px

    .title,
    .text
      color: var(--text-strong)
      line-height: 1.3

    .title
      font-weight: bold
      font-size: rem(16px)

      +lte_ipad
        padding-top: rem(81px)
        margin-bottom: rem(8px)

      +gte_laptop
        margin-bottom: 32px
        max-width: 200px

    .text
      +lte_ipad
        font-size: rem(14px)

      +gte_laptop
        font-size: 16px

    .button
      +button_red

      +lte_ipad
        +mx-auto
        margin-top: rem(12px)
        width: rem(200px)

      +gte_laptop
        max-width: 180px

    picture
      +lte_ipad
        display: none

      +gte_laptop
        position: absolute
        z-index: -1

  .summary-block .summary-text
    +gte_laptop
      font-size: 20px
