// import 'node_modules/snazzy-info-window/dist/snazzy-info-window.scss';

// Default marker
$si-default-marker-width: 60px
$si-default-marker-height: 60px
$si-default-marker-anchor-x: 0
$si-default-marker-anchor-y: 0
$si-default-marker-margin: 0

// Infowindow wrapper
.si-float-wrapper
  position: absolute
  width: 100%
  font-size: 16px

.si-wrapper-top,
.si-wrapper-bottom,
.si-wrapper-left,
.si-wrapper-right
  display: flex
  position: absolute
  align-items: center
  cursor: default

// Top-positioned infowindow
.si-wrapper-top
  flex-direction: column
  margin-top: -$si-default-marker-anchor-y - $si-default-marker-margin
  +margin-left_ltr(math.div($si-default-marker-width, 2) - $si-default-marker-anchor-x)
  transform: translate(-50%, -100%)

// Bottom-positioned infowindow
.si-wrapper-bottom
  flex-direction: column-reverse
  margin-top: $si-default-marker-height - $si-default-marker-anchor-y + $si-default-marker-margin
  +margin-left_ltr(math.div($si-default-marker-width, 2) - $si-default-marker-anchor-x)
  transform: translate(-50%, 0)

// Left-positioned infowindow
.si-wrapper-left
  margin-top: math.div($si-default-marker-height, 2) - $si-default-marker-anchor-y
  +margin-left_ltr(-$si-default-marker-anchor-x - $si-default-marker-margin)
  transform: translate(-100%, -50%)

// Right-positioned infowindow
.si-wrapper-right
  flex-direction: row-reverse
  margin-top: math.div($si-default-marker-height, 2) - $si-default-marker-anchor-y
  +margin-left_ltr($si-default-marker-width - $si-default-marker-anchor-x + $si-default-marker-margin)
  transform: translate(0, -50%)
