=text_font
  line-height: 1.3

  +lte_ipad
    font-size: rem(14px)

  +gte_laptop
    font-size: 16px

  // +lte_ipad
  //   font-size: 0.75rem

  // +gte_laptop
  //   font-size: 1rem
