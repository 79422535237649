// Возможно это уже нигде не используется
.b-important-alert
  background: darken($secondary, 5%)
  border-radius: rem(12px)
  color: #fff
  +left_ltr(rem(40px))
  line-height: 1.25
  padding: rem(20px)
  position: fixed
  top: rem(62px)
  transition: var(--transition-duration) opacity ease
  z-index: 100

  +iphone-portrait
    +left_ltr(0)
    margin: 0 rem(10px)
    top: rem(80px)
    width: calc(100% - #{rem(20px)})

  &.closing
    opacity: 0

  .close
    +lte_ipad
      align-items: center
      cursor: pointer
      display: inline-flex
      font-size: rem(20px)
      height: rem(30px)
      justify-content: center
      position: absolute
      +right_ltr(rem(5px))
      top: rem(7px)
      width: rem(30px)

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''
        display: block
        background-image: url('../images/close.svg')
        height: rem(17px)
        width: rem(17px)

    +gte_laptop
      display: none

  .title
    font-weight: 700
    font-size: rem(18px)
    margin-bottom: rem(4px)

  .subtitle
    font-weight: 500
    font-size: rem(16px)

  p
    color: #fff

  a
    +button_white
    margin: rem(14px) 0 0 0

    &:hover
      text-decoration: none !important
