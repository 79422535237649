.p-pages-root.p-v3 article.slide_4
  --article-background: #fdead7

  .inner
    position: relative

    &:before
      background-image: url('../images/pages/root-v3/slide_4/stars.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

      +lte_ipad
        height: rem(63px)
        right: rem(16px)
        top: rem(16px)
        width: rem(56px)

      +gte_laptop
        height: 97px
        right: 63px
        top: 45px
        width: 87px

  h2
    +lte_ipad
      margin-bottom: rem(21px)

    +gte_laptop
      max-width: 534px
      margin-bottom: 51px

  picture
    img
      width: 100%

      +lte_ipad
        margin-left: calc(var(--container-padding-x) * -1)
        margin-right: calc(var(--container-padding-x) * -1)

  .button-container
    display: flex
    justify-content: center

    +lte_ipad
      margin-top: rem(18px)

    +gte_laptop
      margin-top: 48px

    a
      +button_red

      +lte_ipad
        width: 100%

      +gte_laptop
        width: 314px
