=root_based_colors
  .root-v1 &
    --color-main: var(--secondary-color)
    --color-default: var(--secondary-color)
    --color-hover: var(--secondary-color-hover)
    --color-active: var(--secondary-color-active)
    --color-inactive: var(--secondary-color-inactive)

  .root-v3 &
    --color-main: var(--primary-color-active)
    --color-default: var(--primary-color)
    --color-hover: var(--primary-color-hover)
    --color-active: var(--primary-color-active)
    --color-inactive: var(--primary-color-inactive)
