.p-guest_access,
.p-guest_access_new

  .container
    position: relative

  a.close
    background-image: url('../images/pages/guest_access/close.svg')
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    display: block
    height: rem(16px)
    position: absolute
    +right_ltr(rem(24px))
    width: rem(16px)

    +lte_ipad
      top: rem(20px)

    +gte_laptop
      top: 30px

  h1.special
    font-size: rem(24px)
    line-height: rem(28px)
    margin-bottom: rem(24px)
    text-align: center

    +lte_ipad
      padding-top: rem(100px)

    +gte_laptop
      padding-top: 200px

    &.smile
      position: relative

      &:before
        width: rem(120px)
        height: rem(120px)
        background-image: url('../images/pages/guest_access/smile.svg')
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        display: block
        content: ''
        margin: 0 auto rem(24px)

  .container
    &.inner,
    &.success
      p
        color: var(--text-base)
        font-size: rem(14px)
        line-height: rem(18px)
        margin-bottom: rem(12px)

    &.form,
    &.inner,
    &.success
      text-align: center
      max-width: rem(440px)

      .btn,
      input[type='submit']
        +button_red
        width: 100%
        margin-bottom: rem(32px)

    &.success
      max-width: rem(450px)

  .hint
    display: none !important

  .due-date
    color: var(--primary-color)
    font-size: rem(20px)
    font-weight: 700
    margin-bottom: rem(56px)
