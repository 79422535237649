.p-pages-root.p-v4 article.slide_6
  +container

  h2.ru
    max-width: 825px

  ul
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem4(24px)

    +gte_laptop
      justify-content: center
      gap: 24px

  li
    display: flex
    flex-direction: column

    +lte_ipad
      gap: var(--spacing-md)

    +gte_laptop
      gap: 16px
      width: 25%

    img
      width: 100%

    .text
      +p3

  .picture-container
    position: relative

    .tags
      position: absolute
      display: flex

      +lte_ipad
        bottom: rem4(12px)
        gap: rem4(4px)
        left: rem4(12px)

      +gte_laptop
        bottom: var(--spacing-md)
        gap: 4px
        left: var(--spacing-md)

      .tag
        align-items: center
        display: flex
        font-weight: 600
        justify-content: center

        +lte_ipad
          border-radius: rem4(40px)
          height: rem4(32px)

        +gte_laptop
          border-radius: 40px
          height: 32px

        &:first-child
          background: #fff
          color: var(--gray-900)
          text-align: center

          +lte_ipad
            font-size: rem4(18px)
            width: rem4(48px)

          +gte_laptop
            font-size: 18px
            width: 48px

        &:last-child
          background: var(--gray-900)
          color: #fff

          +lte_ipad
            font-size: rem4(16px)
            padding: 0 rem4(16px)

          +gte_laptop
            font-size: 16px
            padding: 0 16px

  footer
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem4(24px)

    +gte_laptop
      gap: 16px

    .storages
      +button_v4('primary', 'xl')

    .lead
      +button_v4('secondary', 'xl')
