article.boxes_with_slider
  +container
  padding-bottom: 0

  .swiper-v8
    display: flex
    flex-direction: column
    overflow: hidden

    +lte_ipad
      gap: var(--spacing-6xl)

    +gte_laptop
      gap: 64px

  .boxes
    display: flex

  .box
    display: flex
    flex-direction: column
    flex-shrink: 0
    width: 25%

    +lte_ipad
      font-size: rem4(15px)
      gap: rem4(12px)
      line-height: rem4(22px)

    +gte_laptop
      font-size: 15px
      gap: 12px
      line-height: 22px

    .photo-placeholder
      background-image: var(--default-background)
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      padding-bottom: 81%
      position: relative

      +lte_ipad
        border-radius: rem4(24px)

      +gte_laptop
        border-radius: 24px

      .discount
        +p4
        background: #08cc33
        color: #fff
        display: flex
        align-items: center
        gap: var(--spacing-md)
        position: absolute

        +lte_ipad
          border-radius: rem4(16px)
          height: rem4(26px)
          left: rem4(12px)
          padding: 0 rem4(10px)
          top: rem4(14px)

        +gte_laptop
          border-radius: 16px
          height: 26px
          left: 12px
          padding: 0 10px
          top: 14px

    .square-dimension,
    .tags
      align-items: center
      display: flex

      +lte_ipad
        gap: rem4(6px)

      +gte_laptop
        gap: 6px

      .separator
        border-radius: 2px
        background: var(--gray-500)
        height: 2px
        width: 2px

    .square
      +p3(700)

    .dimension
      color: var(--gray-500)

    .info
      display: flex
      flex-direction: column

      +lte_ipad
        gap: rem4(2px)

      +gte_laptop
        gap: 2px

      .address
        font-weight: 500

      .metro
        display: flex
        align-items: center

        +lte_ipad
          gap: rem4(6px)

        +gte_laptop
          gap: 6px

        .line
          +lte_ipad
            border-radius: rem4(8px)
            height: rem4(8px)
            width: rem4(8px)

          +gte_laptop
            border-radius: 8px
            height: 8px
            width: 8px

      .tags
        color: var(--gray-500)

    .price-rating
      align-items: center
      display: flex
      justify-content: space-between
      margin-top: auto

    .price
      align-items: baseline
      display: flex
      gap: var(--spacing-md)

      .new
        +p1(700)

      .old
        color: var(--gray-500)
        text-decoration: line-through

    .rating
      align-items: center
      display: flex

      +lte_ipad
        gap: rem4(4px)

      +gte_laptop
        gap: 4px

      &:before
        +font_icon('\E829')
        font-size: 16px

    .rent
      +button_v4('primary', 'md')
  // +container
  //
  // header h2
  //   +gte_laptop
  //     max-width: 770px
  //
  // .swiper-v8
  //   overflow: hidden
  //
  //   .b-slider-pagination
  //     +gte_laptop
  //       display: none
  //
  //   &:not(.is-pagination)
  //     .slider-navigation
  //       display: none
  //
  //   &.is-pagination
  //     +gte_laptop
  //       // add padding to prevent oveflow cut of .slider-navigation button shadow
  //       padding-bottom: 10px 
  //
  //     .slider-navigation
  //       +lte_ipad
  //         display: none
  //
  //       +gte_laptop
  //         display: flex
  //         justify-content: center
  //         margin-top: 24px
  //
  //       .b-slider-prev
  //         +margin-right_ltr(rem(25px))
  //
  // .boxes
  //   display: flex
  //
  //   +gte_laptop
  //     justify-content: space-between
  //
  //     &.is-centered
  //       justify-content: center
  //
  //   .b-box
  //     flex-shrink: 0
