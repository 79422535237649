=button_navigation($is_next)
  +button_red

  +gte_laptop
    border-radius: 60px
    border: 4px solid #fff
    box-sizing: content-box
    height: 60px
    min-width: 60px
    width: 60px

  &:before
    background-image: url('../images/blocks/b-button/navigation.svg')
    background-repeat: no-repeat
    background-size: contain
    content: ''
    height: rem(25px)
    width: rem(16px)

    @if $is_next == false
      +margin-left_ltr(rem(-2px))

      +ltr
        transform: scaleX(-1)

    @else
      +margin-left_ltr(rem(2px))
      +rtl_flip

  &.is-disabled
    background-color: var(--text-secondary)
    box-shadow: none
    cursor: default

.b-slider-prev
  +button_navigation(false)

.b-slider-next
  +button_navigation(true)

.b-slider-navigation-v4
  display: flex
  justify-content: center

  +lte_ipad
    gap: rem4(24px)

  +gte_laptop
    gap: 24px

  // the same colors as primary button-v4 has
  .prev,
  .next
    color: var(--gray-900)
    cursor: pointer
    transition: color var(--transition-duration)

    +gte_laptop
      &:hover
        color: var(--gray-700)

    &:active
      color: #000

    &.is-disabled
      color: var(--gray-300)


  .prev
    transform: rotate(180deg)

    &:before
      +font-icon('\E82E')
      font-size: 48px

  .next
    &:before
      +font-icon('\E82E')
      font-size: 48px

.b-slider-pagination
  display: flex

  +lte_ipad
    justify-content: center

  .slider-pagination-bullet
    -webkit-tap-highlight-color: transparent
    -webkit-appearance: none
    background: var(--secondary-color)
    border-radius: rem(12px)
    cursor: pointer
    flex-shrink: 0
    height: rem(12px)
    transition: background var(--transition-duration)
    width: rem(12px)

    &-active
      background: var(--primary-color)

    &:not(:last-child)
      +margin-right_ltr(rem(11px))

    +gte_laptop
      &:hover
        background: var(--primary-color-hover)

    &:active
      background: var(--primary-color-active)
