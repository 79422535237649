// do not add rem here since in maps icons positioned in pixels
.b-map-marker_price
  background: #5096ff
  border-radius: 20px
  color: #fff
  font-size: 12px
  font-weight: bold
  padding: 0 8px
  pointer-events: none
  position: absolute
  white-space: nowrap

  &.google
    height: 26px
    line-height: 26px
    margin-left: 28px
    margin-top: -7px

  &.yandex
    font-size: 11px
    height: 22px
    line-height: 22px

  &:hover
    background: red
