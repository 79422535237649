.p-faqs-show
  .root-v3 &
    article.v3.got_questions
      +lte_ipad
        padding-top: rem(24px)

      +gte_laptop
        padding-top: 60px

  .top-header
    background: linear-gradient(180deg, var(--primary-color) -7.88%, var(--primary-color-hover) 88.46%), var(--primary-color)
    box-sizing: border-box
    height: rem(260px)
    margin-bottom: rem(56px)
    overflow: hidden
    padding-top: rem(52px)
    position: relative
    text-align: center

    &:before
      background-image: url(../images/pages/faq/background.svg)
      background-repeat: no-repeat
      background-size: contain
      content: ''
      height: rem(326px)
      +left_ltr(calc(50% - #{rem(390px)}))
      position: absolute
      top: 0
      width: rem(440px)

    h1
      color: #fff
      font-size: rem(44px)
      line-height: rem(48px)

  .questions.container
    display: flex
    margin-bottom: rem(100px)

    ul.contents
      flex-shrink: 0
      +margin-right_ltr(rem(56px))
      width: rem(256px)

      &.desktop
        +lte_ipad
          display: none

        +gte_laptop
          display: block

      li
        background-color: #e6ecf7
        border-radius: rem(12px)
        cursor: pointer
        font-weight: 700
        line-height: rem(18px)
        margin-bottom: rem(28px)
        padding: rem(12px) rem(20px)

        &.active,
        &:hover
          background-color: var(--secondary-color)
          color: #fff

  .b-faq
    +gte_laptop
      display: none

      &.active
        display: block

    .title
      font-weight: 700
      margin-bottom: rem(18px)

      +lte_ipad
        font-size: rem(20px)
        line-height: rem(18px)

      +gte_laptop
        font-size: 24px
        line-height: 28px
