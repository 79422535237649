article.reviews
  h2
    +gte_ipad
      margin-bottom: 54px

  .clients
    display: flex

    +iphone
      align-items: center
      flex-direction: column
      margin-bottom: rem(70px)

    +gte_ipad
      align-items: center
      gap: 22px
      justify-content: center
      margin-bottom: 24px

    .digits
      align-items: center
      color: var(--primary-color)
      display: flex
      font-weight: 700

      +iphone
        font-size: rem(44px)
        gap: rem(8px)
        line-height: rem(48px)
        margin-bottom: rem(12px)

      +gte_ipad
        font-size: 43px
        gap: 7px
        line-height: 47px

      &:before
        background-image: url('../images/articles/reviews/smile.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(35px)
        width: rem(36px)

    p
      +iphone
        font-size: rem(12px)
        max-width: rem(248px)
        text-align: center

      +gte_ipad
        max-width: 317px

  .inner
    position: relative

    &:before,
    &:after
      +gte_ipad
        content: ''

    &:before,
    &:after,
    .blue-circle,
    .slide_1-background,
    .slide_2-background,
    .slide_3-background
      +rtl_flip
      background-repeat: no-repeat
      background-size: contain
      position: absolute
      z-index: 1

    .blue-circle
      background-image: url('../images/articles/reviews/blue_circle.svg')
      left: 50%
      transform: translateX(-50%)

      +iphone
        width: rem(308px)
        height: rem(308px)

      +gte_ipad
        width: 423px
        height: 423px

    .slide_1-background,
    .slide_2-background,
    .slide_3-background
      left: 50%
      opacity: 0
      transform: translateX(-50%)
      transition: opacity var(--transition-duration)

      +iphone
        top: rem(23px)
        width: rem(290px)

        img
          max-width: 100%

      +gte_ipad
        top: 23px

    &[data-slide='1'] .slide_3-background,
    &[data-slide='2'] .slide_2-background,
    &[data-slide='3'] .slide_1-background
      opacity: 1

    .swiper-wrapper
      +iphone
        padding-top: rem(98px)

      +gte_ipad
        padding-top: 156px

    .swiper-outer
      background: #fff
      border-radius: rem(20px)
      box-shadow: 0px rem(22px) rem(44px) rem(-12px) rgba(0, 0, 0, 0.1)
      margin-left: auto
      margin-right: auto
      position: relative
      z-index: 1

      +iphone
        padding: rem(26px) rem(13px)

      +gte_ipad
        padding: 37px
        min-height: 237px
        width: 920px

  .swiper-v8
    overflow: hidden

    +iphone
      width: 100%

    .slides
      display: flex

      .slide
        flex-shrink: 0
        width: 100%

        +gte_ipad
          align-items: flex-start
          display: flex
          gap: 30px

      .author
        font-weight: 700

        +iphone
          align-items: center
          display: flex
          flex-direction: column
          font-size: rem(20px)
          line-height: rem(24px)
          margin-bottom: rem(23px)

        +gte_ipad
          flex-shrink: 0
          font-size: 30px
          line-height: 34px

        .stars
          display: flex
          gap: rem(3px)

          +iphone
            margin-top: rem(8px)

          +gte_ipad
            margin-top: 10px

        .star
          background-repeat: no-repeat
          background-size: contain
          flex-shrink: 0
          height: rem(17px)
          width: rem(17px)

          &-filled
            background-image: url('../images/articles/reviews/star-filled.svg')

          &-empty
            background-image: url('../images/articles/reviews/star-empty.svg')

      .text,
      .text p
        +iphone
          font-size: rem(12px)

    .b-slider-prev
      +left_ltr(-1 * (30px + 68px))

    .b-slider-next
      +right_ltr(-1 * (30px + 68px))

    .b-slider-prev,
    .b-slider-next
      margin-top: 16px
      position: absolute
      top: 50%
      transform: translateY(-50%)
      z-index: 1

      +iphone
        display: none

    .b-slider-pagination
      +iphone
        margin-top: rem(16px)

      +gte_ipad
        display: none
