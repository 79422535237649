.b-profile-progress
  background-color: var(--secondary-color)
  border-radius: rem(12px)
  margin-bottom: rem(20px)
  padding: rem(16px)

  .title
    color: #fff
    font-size: rem(14px)
    font-weight: 700
    line-height: rem(17px)
    margin-bottom: rem(4px)

  .body
    color: #eff3fa
    font-size: rem(12px)
    line-height: rem(14px)
    margin-bottom: rem(16px)

  .progress-bar
    background-color: #d7e0ef
    border-radius: rem(6px)
    height: rem(12px)
    position: relative
    width: 100%

    .bar
      background-color: #fff
      border-radius: rem(6px)
      height: rem(12px)
      +left_ltr(0)
      position: absolute
      top: 0

      &.w-90
        width: 90%
