article.security
  overflow: hidden

  .description
    margin-bottom: rem(76px)

    +lte_ipad
      line-height: rem(24px)
      +margin-left_ltr(rem(66px))

    +gte_laptop
      font-size: 20px
      line-height: 28px
      +margin-left_ltr(94px)
      width: 324px

    &:before
      background-image: url('../images/articles/security/24-hours.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

      +lte_ipad
        height: rem(44px)
        +margin-left_ltr(rem(-58px))
        width: rem(44px)

      +gte_laptop
        height: 64px
        +margin-left_ltr(-94px)
        width: 64px

    b
      color: var(--primary-color)
      font-weight: 600

  .container.white
    position: relative

    +lte_ipad
      padding-top: rem(6px)
      padding-bottom: rem(100px)

    +gte_laptop
      padding-top: 46px

    &:before
      +rtl_flip
      background-image: url('../images/articles/security/background.png')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute
      z-index: 1

      +lte_ipad
        height: rem(160px)
        top: rem(164px)
        width: rem(288px)

      +gte_laptop
        height: 271px
        +right_ltr(calc(50% - 500px))
        top: 26px
        width: 540px

      +retina_media
        background-image: url('../images/articles/security/background@2x.png')

    // cd app/packs/images/articles/security
    // convert background.png -quality 50 background.webp
    // convert background@2x.png -quality 50 background@2x.webp
    .webp &:before
      background-image: url('../images/articles/security/background.webp')

      +retina_media
        background-image: url('../images/articles/security/background@2x.webp')

    .background-circle-blue
      +gte_laptop
        &:before
          background-image: url('../images/articles/security/background-blue.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          position: absolute
          width: 120px
          height: 120px
          +right_ltr(calc(50% - 28px))
          top: 90px

    .background-circle-red
      +gte_laptop
        &:before
          background-image: url('../images/articles/security/background-red.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          position: absolute
          width: 288px
          height: 288px
          +right_ltr(calc(50% - 690px))
          top: 90px

  .features
    background-color: var(--secondary-color)
    line-height: 1.2

    +lte_ipad
      padding: rem(36px) 0

    +gte_laptop
      padding: 62px 0

    ul
      display: flex
      flex-wrap: wrap
      // justify-content: space-between

      +iphone
        row-gap: rem(29px)

      +gte_ipad
        gap: rem(40px)

      li
        align-items: center
        color: #fff
        display: flex
        height: rem(64px)
        width: rem(200px)

        +lte_ipad
          +margin-left_ltr(rem(84px))

        +gte_laptop
          +margin-left_ltr(rem(96px))

        &:before
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(64px)
          position: absolute
          width: rem(64px)

          +lte_ipad
            +margin-left_ltr(rem(-86px))

          +gte_laptop
            +margin-left_ltr(-94px)

        &.item-1
          &:before
            background-image: url('../images/articles/security/item-1.svg')

        &.item-2
          &:before
            background-image: url('../images/articles/security/item-2.svg')

        &.item-3
          &:before
            background-image: url('../images/articles/security/item-3.svg')

        &.item-4
          &:before
            background-image: url('../images/articles/security/item-4.svg')

        &.item-5
          &:before
            background-image: url('../images/articles/security/item-5.svg')

        &.item-6
          &:before
            background-image: url('../images/articles/security/item-6.svg')
