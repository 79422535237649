.b-back
  +lte_ipad
    align-items: center
    background-color: #f5f6fa
    border-bottom: 1px solid #c5cbd3
    color: #505763
    display: flex
    height: rem(40px)
    justify-content: center
    user-select: none

    &:before
      background-image: url('../images/blocks/b-back/normal.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      display: inline-block
      height: rem(12px)
      +margin-right_ltr(rem(8.5px))
      width: rem(20.5px)

    &:active
      color: #3e3f43
      background-color: darken(#f5f6fa, 3%)

      &:before
        background-image: url('../images/blocks/b-back/active.svg')

  +gte_laptop
    display: none
