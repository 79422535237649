.b-box
  background-color: #a5a5a5
  background-repeat: no-repeat
  background-size: cover
  border-radius: rem(20px)
  color: #fff
  display: flex
  flex-direction: column
  overflow: hidden
  padding: rem(16px) rem(16px) rem(24px)
  position: relative

  +iphone
    +mx-auto

  +lte_ipad
    height: rem(364px)
    width: rem(288px)

    &:not(:last-child)
      margin-bottom: rem(24px)

  +gte_laptop
    height: 364px
    padding: 16px

  +laptop-new
    width: scale-laptop(230px, 256px)

  +gte_desktop-new
    width: 256px

  &:after
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
    bottom: 0
    content: ''
    height: rem(180px)
    +left_ltr(0)
    opacity: 0.6
    position: absolute
    width: 100%

  .discount
    align-items: center
    background: var(--primary-color)
    border-radius: rem(60px)
    display: flex
    font-size: rem(20px)
    font-weight: 700
    height: rem(60px)
    justify-content: center
    line-height: 1.2
    width: rem(60px)

  .row
    display: flex
    margin-top: auto
    position: relative
    z-index: 1

    +lte_ipad
      margin-bottom: rem(25px)

    +gte_laptop
      margin-bottom: 16px

  .c-info
    align-self: flex-end
    +margin-right_ltr(4px)

    .title
      font-weight: bold
      font-size: rem(32px)
      line-height: rem(36px)

    .address,
    .city
      font-size: 12px
      line-height: 1.3

    .city
      font-weight: bold

  .c-price
    align-self: flex-end
    background: #fff
    flex-shrink: 0
    line-height: 1
    +margin-left_ltr(auto)
    +margin-right_ltr(rem(-16px))
    padding: rem(14px) 0
    text-align: center

    +ltr
      border-radius: rem(20px) 0 0 rem(20px)

    +rtl
      border-radius: 0 rem(20px) rem(20px) 0

    +lte_ipad
      width: rem(136px)

    +gte_laptop
      width: 104px

    .new
      color: var(--primary-color)
      white-space: nowrap
      font-weight: bold

      +lte_ipad
        font-size: rem(30px)
        line-height: rem(34px)

      +gte_laptop
        font-size: 22px

      &:not(:last-child)
        margin-bottom: 4px

    .old,
    .no-price
      color: var(--text-secondary)

      +lte_ipad
        font-size: rem(16px)

      +gte_laptop
        font-size: 14px

    .new:first-child:last-child,
    .no-price
      margin: rem(5px) 0

    .no-price
      text-align: center
      +padding-right_ltr(rem(10px))

    .old
      text-decoration: line-through

  .rent
    +button_red
    font-weight: bold
    font-size: rem(12px) !important
    position: relative
    z-index: 1
