.b-alert-top,
.b-notice-top
  background: var(--primary-color)
  color: #fff
  font-weight: 700
  left: 0
  padding: rem(26px) rem(16px)
  position: fixed
  text-align: center
  top: 0
  width: 100%
  z-index: 11 // z-index: 10 has header#header (see header.sass)

  +lte_ipad
    font-size: rem(16px)
    line-height: rem(18px)

  +gte_laptop
    font-size: 20px
    line-height: 24px

  .close
    background-image: url('../images/blocks/b-notice_top/close.svg')
    background-repeat: no-repeat
    background-size: contain
    height: 24px
    position: absolute
    +right_ltr(48px)
    top: 26px
    width: 24px

    +lte_ipad
      display: none

    +gte_laptop
      &:hover
        cursor: pointer
        opacity: 0.8

      &:active
        opacity: 0.9
