.p-pages-root.p-v3 article.slide_1
  display: flex
  margin: 0
  padding-bottom: 0
  padding-top: 0

  +iphone
    flex-direction: column-reverse

  +ipad
    +container_ipad

  +gte_laptop
    +container_desktop

  .first
    display: flex
    flex-direction: column
    gap: var(--padding-2xl)
    padding-bottom: var(--padding-between-blocks)

    +iphone
      +container-mobile
      padding-top: rem(40px)

    +gte_ipad
      flex-basis: 51.5%
      padding-top: var(--padding-between-blocks)

    h1
      +text-align_ltr(left)
      margin: 0
      position: relative

      span.blue
        color: #1570ef

      &:before
        background-image: url('../images/pages/root-v3/slide_1/line.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        position: absolute

        +iphone
          height: rem(18px)
          left: 0
          top: rem(90px)
          width: rem(232px)

        +gte_ipad
          height: 28px
          left: -16px
          top: 167px
          width: 406px
    h2
      +text-align_ltr(left)
      font-weight: 200

      +gte_ipad
        font-size: rem(20px)
        line-height: rem(28px)

      +iphone
        font-size: rem(16px)
        line-height: rem(24px)

    ul
      display: flex
      flex-direction: column
      color: var(--text-strong)
      gap: var(--padding-md)

      +iphone
        +p2

      +gte_ipad
        +p1

      li
        display: flex
        gap: var(--padding-sm)

        &:before
          background-image: url('../images/pages/root-v3/slide_1/checkmark.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''

          +iphone
            height: rem(24px)
            width: rem(24px)

          +gte_ipad
            height: 32px
            width: 32px

    a
      +button_red
      +button_width_auto

  .second
    +gte_ipad
      flex-basis: 48.5%

    picture
      +gte_ipad
        position: absolute
        line-height: 0

      img
        +iphone
          width: 100%
