.p-pages-root.p-v4 article.slide_5
  ul
    display: flex
    gap: var(--feature-gap)

    +lte_ipad
      flex-direction: column
      --feature-gap: #{rem4(64px)}

    +gte_laptop
      --feature-gap: 24px

  li
    display: flex
    position: relative

    +lte_ipad
      gap: rem4(16px)

    +gte_laptop
      flex-basis: 33.333%
      flex-direction: column
      gap: 24px

    &:before
      background: var(--gray-700)
      content: ''
      position: absolute

      +lte_ipad
        height: calc(100% + var(--feature-gap))
        left: rem4(31px)
        top: 0
        width: rem4(2px)

      +gte_laptop
        height: 2px
        top: 35px

    &.feature_1:before
      +gte_laptop
        left: 50%
        width: calc(50% + var(--feature-gap))

    &.feature_2:before
      +gte_laptop
        left: 0
        width: calc(100% + var(--feature-gap))

    &.feature_3:before
      +lte_ipad
        display: none

      +gte_laptop
        left: 0
        width: calc(50% + var(--feature-gap))

    @each $i in 1,2,3
      &.feature_#{$i} .icon:before
        background-image: url('../images/pages/root-v4/slide_5/feature_#{$i}.svg')

    .icon
      align-items: center
      background: rgba(#fff, 0.1)
      display: flex
      flex-shrink: 0
      justify-content: center

      +lte_ipad
        backdrop-filter: blur(rem4(44px))
        border-radius: rem4(13.714px)
        height: rem4(64px)
        width: rem4(64px)

      +gte_laptop
        align-self: center
        backdrop-filter: blur(44px)
        border-radius: 16px
        height: 72px
        width: 72px

      &:before
        content: ''

        +lte_ipad
          height: rem4(40px)
          width: rem4(40px)

        +gte_laptop
          height: 48px
          width: 48px

    .title
      +p1(700)
      color: var(--gray-25)

      +lte_ipad
        margin-bottom: var(--spacing-md)

      +gte_laptop
        font-size: 24px
        line-height: 1.2
        margin-bottom: 12px
        text-align: center

    .other
      +p3(500)
      letter-spacing: 0.16px

      +gte_laptop
        text-align: center

  .button
    +button_v4('secondary', 'xl')
