.p-info_pages-show
  --h1-line-height: 1.25
  --p-offset: #{rem4(14px)}

  +lte_ipad
    --h2-line-height: #{rem4(24px)}
    --headline-margin: #{rem4(28px)}

  +gte_laptop
    --h2-line-height: 36px
    --headline-margin: 28px

  article.blog.headline
    align-items: flex-start
    padding-bottom: calc(var(--p-offset) * 2)

    header
      align-items: flex-start

      h1,
      .note
        +text-align_ltr(left)
        max-width: unset

      h1,
      h2
        margin: var(--headline-margin) 0

    .note-image
      margin-bottom: var(--p-offset)

    .image img
      max-height: rem(600px)
      margin-bottom: 0
      width: 100%

    .b-tags
      justify-content: flex-start

      +lte_ipad
        margin-bottom: rem4(28px)

      +gte_laptop
        margin-bottom: 36px

  h2
    +lte_ipad
      font-size: rem4(24px)
      margin-bottom: var(--p-offset)

  h3, h4
    margin: 0
    font-size: unset

  a.button
    text-decoration: none
    +button_red

  img
    border-radius: rem(32px)
    margin-bottom: calc(var(--p-offset) * 2)
    max-height: rem(730px)
    object-fit: cover

  .img-note
    font-size: 14px
    line-height: 1.3
    color: var(--text-base)
    margin-bottom: var(--p-offset)
    display: inline-block

    +lte_ipad
      font-size: rem(12px)

  .collage
    display: grid
    row-gap: rem(20px)

    +gte_laptop
      grid-template-columns: 50% 50%
      column-gap: rem(16px)

    p
      margin-bottom: 0

    img
      height: rem(365px)

    .img-note
      margin-top: 8px // Должно быть 16px, вычитаем магических 8px после картинки

      +lte_ipad
        margin-top: rem(4px)  // Должно быть 12px, вычитаем магических 8px после картинки

  .rooms
    overflow: hidden
    padding-bottom: rem(15px)

    h2
      margin-bottom: rem(32px)

    +lte_ipad
      margin-bottom: rem(51px)

    +gte_laptop
      margin-bottom: 70px

    .fc-rooms
      +fcolumn-responsive($columns: 1, $laptop-columns: 3, $desktop-columns: 3, $gutter: rem(40px))
      flex-wrap: nowrap !important

    .f-column
      flex-shrink: 0

      +lte_ipad
        width: rem(212px) !important

    .outer
      background: #fafbfd
      border-radius: rem(10px)
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
      padding-top: 100%
      position: relative

    .inner
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      +left_ltr(0)
      position: absolute
      top: 0
      width: 100%
      text-align: center

      +lte_ipad
        padding: rem(21px)

      +gte_laptop
        padding: 16px 30px 25px

    img
      +lte_ipad
        width: rem(110px)

      +laptop
        width: scale-between(163px, 189px, 1024px, 1280px)

      +gte_desktop
        width: 189px

    .title
      font-weight: bold
      margin-top: auto

      +lte_ipad
        font-size: rem(16px)
        line-height: rem(20px)
        margin-bottom: rem(3px)

      +laptop
        font-size: scale-between(18px, 22px, 1024px, 1280px)
        line-height: scale-between(20px, 25px, 1024px, 1280px)

      +gte_laptop
        margin-bottom: 11px

      +gte_desktop
        font-size: 22px
        line-height: 25px

    .text
      +lte_ipad
        font-size: rem(14px)
        line-height: rem(16px)

      +laptop
        font-size: scale-between(16px, 20px, 1024px, 1280px)
        line-height: scale-between(23px, 28px, 1024px, 1280px)

      +gte_desktop
        font-size: 20px
        line-height: 28px

  .benefits-block
    +lte_ipad
      margin-bottom: rem(35px)

    +gte_laptop
      background: #f6f6f6
      padding-top: 43px
      padding-bottom: 7px

  .expandable-list-block
    h3
      +gte_laptop
        margin-bottom: 45px

    .f-navigation
      li
        +gte_laptop
          &:not(.active)
            font-size: 20px

          &.active
            font-size: 24px

    .f-content
      li
        .container
          +lte_ipad
            padding-top: rem(29px)
            padding-bottom: rem(20px)

  .custom-content
    &.tires
      .fc-rent
        +gte_laptop
          +fcolumn(3, 88px, 0px) 

        .f-column
          display: flex
          flex-direction: column
          position: relative
          text-align: center

          +lte_ipad
            margin-bottom: rem(40px)

          @each $type in phone, treaty, store
            &.#{$type}:before
              background-image: url('../../assets/images/static/personal_business_storage/tires-#{$type}.svg')
              background-position: center
              background-repeat: no-repeat
              background-size: contain
              content: ''
              display: block
              height: rem(70px)
              margin-bottom: rem(28px)
              width: rem(70px)
              align-self: center

          &:not(:last-child)
            +gte_laptop
              &:after
                background-image: url('../../assets/images/static/personal_business_storage/arrow.svg')
                background-repeat: no-repeat
                background-size: contain
                content: ''
                height: 23px
                position: absolute
                +right_ltr(-86px)
                top: 22px
                width: 96px

    h1,
    h2,
    h3,
    h4
      text-align: left !important

    .b-contents
      padding: rem(24px)
      border-radius: rem(24px)
      background-color: rgba(0, 0, 0, 0.05)
      margin-bottom: rem(24px)

      strong
        color: var(--text-secondary)

      ul
        list-style: none
        margin: 0

        li
          &:before
            display: none

          margin-top: rem(16px)
          margin-bottom: 0
          padding-left: 0 !important

          +lte_ipad
            margin-top: rem(12px)

      a
        font-weight: 700
        color: var(--text-strong)
        text-decoration: none

        &:hover
          color: var(--text-base)
          text-decoration: none

        &:active
          color: var(--text-secondary)

  .promotion
    .link
      margin-top: rem(70px)
      margin-bottom: rem(40px)

    .poster
      background-repeat: no-repeat
      background-size: contain
      padding-top: 25.5%
      width: 100%

    .b-button
      +button_red

  .article-image-container
    display: flex
    margin-bottom: rem(56px)
    justify-content: center

    img
      max-width: 100%
