article.landing_lead_form.v3
  .columns
    display: flex

    +lte_ipad
      flex-direction: column
      gap: rem(32px)

    +gte_laptop
      gap: 30px

    .column
      +gte_laptop
        flex-basis: 50%

  .head
    +gte_laptop
      display: flex
      flex-direction: column
      padding: 8px 0

    .below-headline
      +lte_ipad
        margin-bottom: rem(32px)

      +gte_laptop
        margin-bottom: auto

    .title
      margin-bottom: var(--padding-sm)

      +lte_ipad
        +p2
        font-weight: 700

      +gte_laptop
        +p1
        font-weight: 700

    .text
      +lte_ipad
        +p3

      +gte_laptop
        +p2

    .contact
      display: flex
      flex-direction: column
      gap: var(--padding-lg)

    .icon
      background-repeat: no-repeat
      background-size: contain
      height: 28px
      width: 28px

      @each $type in email, phone
        &.#{$type}
          background-image: url('../images/pages/root-v3/slide_7/icon-#{$type}.svg')

    a
      color: var(--primary-color)

      +lte_ipad
        +b2

      +gte_laptop
        +b1

        &:hover
          color: var(--primary-color-hover)
          // text-decoration: none

      &:active
        color: var(--primary-color-active)

  .form
    display: flex
    flex-direction: column
    gap: var(--padding-xl)
    border-radius: var(--padding-lg)
    background: #ffe6d5

    +lte_ipad
      padding: rem(32px) var(--container-padding-x)

    +gte_laptop
      padding: 32px

    .title
      +w1

    .b-input
      margin: 0

    .phone-area
      display: flex
      gap: 12px

      +lte_ipad
        gap: rem(8px)

      .b-input.phone
        flex-grow: 1

    textarea
      +gte_laptop
        min-height: 96px

    .submit-container
      display: flex
      flex-direction: column
      gap: var(--padding-md)

      input[type='submit']
        +button_red
        width: 100%

      .note
        +p3
