=logo
  background-repeat: no-repeat
  background-size: contain
  display: inline-block

  +lte_ipad
    height: rem(44px)
    width: rem(154px)

  +gte_laptop
    height: 48px
    width: 198px

  &-ru
    background-image: url(../images/blocks/b-top_menu/logo-white.svg)
  &-et
    background-image: url(../images/blocks/b-top_menu/logo-white-et.svg)
