.b-company_info
  box-sizing: border-box
  margin: rem(42px) auto 0
  max-width: 520px

  +lte_ipad
    padding: 0 rem(16px)

  .districts
    margin-bottom: rem(32px)

  h3
    font-size: rem(24px)
    margin-bottom: rem(16px)

    +ltr_rtl
      text-align: center

  .attr
    font-size: rem(14px)
    line-height: rem(18px)
    margin-bottom: rem(10px)
    +text-align_ltr(left)

    a
      +link(var(--primary-color))
