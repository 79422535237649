article.landing_lead_form.v4
  +lte_ipad
    padding-bottom: rem(28px) !important

  +gte_laptop
    padding-bottom: 0 !important

  header
    +container

  .form
    background: var(--secondary-color)
    box-shadow: 0px 22px 44px -12px rgba($secondary, 0.25)
    padding-top: rem(60px)
    position: relative
    width: 100%

    .container
      +lte_ipad
        +padding-left_ltr(rem(12px))
        +padding-right_ltr(rem(12px))
        padding-bottom: rem(296px)

      +gte_laptop
        display: flex
        padding-bottom: 60px

      &:before
        background-repeat: no-repeat
        background-size: contain
        bottom: 0
        content: ''
        position: absolute

        // cp app/packs/images/pages/root/slide_9/original/* app/packs/images/pages/root/slide_9/; for image in app/packs/images/pages/root/slide_9/*@2x.png; do; echo $image; convert -resize 50% $image ${image/@2x/}; convert -quality 95% $image ${image/\.png/.webp}; convert -resize 50% ${image/\.png/.webp} ${${image/\.png/.webp}/@2x/}; tinypng $image; tinypng ${image/@2x/}; done;
        +lte_ipad
          background-image: url('../images/pages/root/slide_9/background-mobile.png')
          height: rem(275px)
          left: 50%
          transform: translateX(-50%)
          width: rem(278px)

        @media screen and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)
          background-image: url('../images/pages/root/slide_9/background-mobile@2x.png')

        +gte_laptop
          +rtl_flip
          background-image: url('../images/pages/root/slide_9/background-desktop.png')
          height: 494px
          width: 469px

        +laptop-new
          +left_ltr(scale-laptop(25px, 37px))

        +gte_desktop-new
          +left_ltr(37px)

        @media screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)
          background-image: url('../images/pages/root/slide_9/background-desktop@2x.png')

      .webp &:before
        +lte_ipad
          background-image: url('../images/pages/root/slide_9/background-mobile.webp')

        @media screen and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)
          background-image: url('../images/pages/root/slide_9/background-mobile@2x.webp')

        +gte_laptop
          background-image: url('../images/pages/root/slide_9/background-desktop.webp')

        @media screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)
          background-image: url('../images/pages/root/slide_9/background-desktop@2x.webp')

    .desktop-offset
      +laptop-new
        +margin-left_ltr(50%)
        +padding-left_ltr(scale-laptop(14px, 17px))
        width: scale-laptop(392px, 443px)

      +gte_desktop-new
        +margin-left_ltr(50%)
        +padding-left_ltr(17px)
        width: 443px

    .note
      color: #fff
      line-height: 1.33
      margin-top: 22px

      +lte_ipad
        font-size: rem(12px)
        margin-bottom: rem(24px)
        text-align: center

      +gte_laptop
        font-size: 14px
        margin-bottom: 40px

      a
        text-decoration: underline

    .phone-area
      display: flex

      +lte_ipad
        gap: rem(8px)

      +gte_laptop
        gap: 12px

      .b-input.phone
        flex-grow: 1

    .submit-container
      +lte_ipad
        display: flex
        justify-content: center

      .submit
        +button_red

        +lte_ipad
          height: rem(44px)
          width: rem(240px)
