.p-orders-v2-sessions .content-column
  .password-container
    display: flex
    margin-bottom: rem(16px)

    .b-input
      margin-bottom: 0

    +lte_ipad
      flex-direction: column
      gap: rem(16px)

    +gte_laptop
      gap: 20px
      align-items: center

    .forgot-password
      color: var(--primary-color)
      font-size: rem(14px)
      font-weight: 700

      +lte_ipad
        display: flex
        margin-left: auto

  .additional
    margin-bottom: rem(24px)
