=svg_icon_hover
  background: var(--primary-color)

  svg
    color: #fff

=svg_icon_active
  background: var(--secondary-color)

  svg
    color: #fff

.b-svg-icon
  align-items: center
  background: #eff3fa
  border-radius: 100%
  display: flex
  flex-shrink: 0
  justify-content: center
  transition: background var(--transition-duration) ease

  svg
    color: var(--text-secondary)
    transition: transform var(--transition-duration) ease, color var(--transition-duration) ease
    width: 100%

  &:not(.is-disabled)
    +gte_laptop
      &:hover
        +svg_icon_hover

    &:active
      +svg_icon_active
