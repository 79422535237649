=gte_laptop_layout($is_height)
  flex-shrink: 0

  $laptop-width: 348px
  $desktop-width: 424px // 464px
  +laptop
    width: scale-laptop($laptop-width, $desktop-width)

    @if $is_height
      height: scale-laptop(548px, math.div(548px, math.div($laptop-width, $desktop-width)))

  +gte_laptop
    +margin-right_ltr(28px)

  +gte_desktop
    width: $desktop-width

    @if $is_height
      height: math.div(548px, math.div($laptop-width, $desktop-width))

$size-link-desktop-height: 100px

.p-storages-show
  article.slide-1
    position: relative
    margin-bottom: 0

    h1
      +lte_ipad
        margin-bottom: rem(8px)

      +gte_laptop
        margin-bottom: 12px
        padding-top: 8px

    .address
      text-align: center
      line-height: 1.2
      color: var(--text-base)

      +lte_ipad
        font-size: rem(12px)

    .rating
      +lte_ipad
        margin-top: rem(16px)

      +gte_laptop
        margin-top: 28px

    .short-links
      margin-top: rem(28px)
      text-align: center

      +lte_ipad
        display: none

      a
        color: var(--primary-color)
        font-size: rem(12px)
        margin: 0 rem(12px)
        text-decoration: underline

        &:hover
          text-decoration: none

    .b-tags
      +lte_ipad
        margin: rem(24px) 0

      +gte_laptop
        margin-top: 32px
        margin-bottom: 56px

  .slide-2
    +lte_ipad
      margin-bottom: rem(20px)

    +gte_ipad
      margin-bottom: 64px

    .container
      +gte_laptop
        display: flex

      .details
        +gte_laptop_layout(false)
        display: flex

        .temperature,
        .humidity,
        .height
          flex-shrink: 0
          padding: 0 rem(12px)
          white-space: nowrap
          width: 33.33333%

          .value
            display: inline-block
            font-weight: 900
            vertical-align: top

            +lte_ipad
              font-size: rem(16px)
              +margin-left_ltr(rem(8px))
              margin-top: rem(2px)

            +gte_laptop
              font-size: 20px
              +margin-left_ltr(12px)
              margin-top: 8px

          &.height .value
            +gte_laptop
              +margin-left_ltr(4px)

          .label
            line-height: 1.4
            margin-top: rem(6px)
            white-space: normal

            +lte_ipad
              font-size: rem(8px)

            +gte_laptop
              font-size: 12px

            .now
              color: var(--primary-color)
              font-weight: 600

          &:before
            background-repeat: no-repeat
            background-size: contain
            background-position: center
            content: ''
            display: inline-block
            top: 0
            +left_ltr(0)

            +lte_ipad
              height: rem(20px)
              width: rem(20px)

            +gte_ipad
              height: 40px
              width: 40px

        .temperature:before
          background-image: url('../images/pages/storages/show/slide_2/icon-temperature.svg')

        .humidity:before
          background-image: url('../images/pages/storages/show/slide_2/icon-humidity.svg')

        .height:before
          background-image: url('../images/pages/storages/show/slide_2/icon-height.svg')

      .actions
        +lte_ipad
          display: none

        +gte_laptop
          display: flex

        .call-me
          +button_red
          +margin-right_ltr(rem(24px))
          margin-top: rem(22px)

        .guest
          +button_blue

      .swiper-v8.photos
        display: inline-block
        height: rem(140px)
        overflow: hidden

        +lte_ipad
          +margin-left_ltr(rem(-8px))
          display: block
          margin-top: rem(20px)

        &:before
          +rtl_flip
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 79.1%)
          content: ''
          height: rem(140px)
          position: absolute
          +right_ltr(0)
          z-index: 2

          +lte_ipad
            width: rem(60px)

          +gte_laptop
            width: 240px

        .slides-wrapper
          display: flex

          .slide
            flex-shrink: 0
            width: rem(160px)
            height: rem(140px)

            +lte_ipad
              +margin-left_ltr(rem(8px))

            +gte_laptop
              +margin-right_ltr(16px)

            img
              border-radius: rem(12px)

  .slide-3
    .container
      position: relative
      display: flex

      +lte_ipad
        flex-direction: column

      .storage-promo
        +gte_laptop_layout(false)

        .inner
          border: 2px solid var(--primary-color)
          box-sizing: border-box
          line-height: 1.2
          position: relative

          +gte_laptop
            min-height: $size-link-desktop-height

          +lte_ipad
            border-radius: rem(12px)
            padding-bottom: rem(20px)
            +padding-left_ltr(rem(60px))
            +padding-right_ltr(rem(24px))
            padding-top: rem(20px)
            margin-bottom: rem(20px)

          +gte_laptop
            border-radius: 20px
            padding-bottom: 30px
            +padding-left_ltr(80px)
            +padding-right_ltr(32px)
            padding-top: 30px
            margin-bottom: 36px

          &:before
            +rtl_flip
            background-image: url('../images/pages/storages/show/warning-note-icon.svg')
            background-repeat: no-repeat
            background-size: contain
            content: ''
            height: rem(24px)
            position: absolute
            width: rem(24px)

            +lte_ipad
              top: rem(18px)
              +left_ltr(rem(20px))

            +gte_laptop
              top: 30px
              +left_ltr(32px)

      .box-filters
        width: 100%

        +lte_ipad
          // 20px - 18px (от первого бокса в списке)
          margin-bottom: rem(2px)

        +gte_laptop
          margin-bottom: 36px

        .sizes-filter
          display: flex

          .size-link
            background-color: #e6ecf6
            box-sizing: border-box
            cursor: pointer
            font-weight: 700
            flex-grow: 1
            flex-basis: 100px

            +lte_ipad
              border-radius: rem(8px)
              padding: rem(6px) rem(12px) rem(8px) rem(12px)

            +gte_laptop
              border-radius: 12px
              height: $size-link-desktop-height
              padding: 20px 32px

            &:not(:last-child)
              +margin-right_ltr(14px)

            &:hover:not(.disabled),
            &.active
              background-color: var(--secondary-color)
              color: #fff

            &.disabled
              cursor: default
              opacity: 0.3

            .title
              +lte_ipad
                font-size: rem(20px)
                line-height: 1.2

              +gte_laptop
                font-size: 32px
                margin-bottom: 6px

            .subtitle
              +lte_ipad
                font-size: rem(10px)
                line-height: 1.2

              +gte_laptop
                font-size: 16px

        .levels-filter
          display: flex

          .level-link
            background-color: #e6ecf6
            box-sizing: border-box
            cursor: pointer
            font-weight: 700
            flex-basis: rem(100px)
            flex-grow: 1
            text-align: center

            +lte_ipad
              margin-top: rem(12px)
              border-radius: rem(8px)
              font-size: rem(12px)
              padding: rem(8px) 0

            +gte_laptop
              margin-top: 22px
              border-radius: 12px
              padding: 13px 0

            &:not(:last-child)
              +margin-right_ltr(14px)

            &:hover,
            &.active
              background-color: var(--secondary-color)
              color: #fff
              text-decoration: none

    .map-boxes
      display: flex
      width: 100%

      .b-storage_map
        flex-shrink: 0

        +lte_ipad
          display: none

        .map-inner
          +gte_laptop_layout(true)
          background-color: #eff3fa
          border-radius: 12px
          border: none
          margin-bottom: 30px
          overflow: hidden

        .legend
          .item
            &:not(:last-child)
              margin-bottom: 12px

            .dot
              display: inline-block
              width: 12px
              height: 12px
              border-radius: 6px
              border: 1px solid #c4c4c4

              &.available
                background-color: var(--secondary-color)

              &.active
                background-color: var(--primary-color)

            .label
              +margin-left_ltr(12px)
              display: inline-block
              color: var(--text-base)
              font-size: 14px

      .boxes-container
        flex-grow: 1

        .boxes
          overflow-y: auto
          margin-bottom: rem(48px)

          +lte_ipad
            max-height: rem(668px)

          +laptop
            max-height: scale-laptop(549px, 668px)

          +gte_desktop
            max-height: 668px

        .find-box
          align-items: center
          background-color: var(--primary-color)
          border-radius: rem(12px)
          color: #fff
          display: flex
          padding: rem(20px) rem(24px)
          position: relative

          +lte_ipad
            flex-direction: column

          +gte_laptop
            flex-direction: row
            justify-content: space-between
            width: 100%

            &:before
              background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
              content: ''
              height: rem(62px)
              position: absolute
              +right_ltr(0)
              top: rem(-62px)
              width: 100%

          .label
            font-weight: 700
            margin-bottom: rem(4px)

          .inner
            +lte_ipad
              margin-bottom: rem(20px)

          .box-lead
            +button_white

            +lte_ipad
              width: 100%

            +gte_laptop
              width: 164px

  article.slide-6
    +gte_laptop
      position: relative

      &:before
        background-image: url('../images/pages/storages/show/slide_6/background.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(48px)
        position: absolute
        top: rem(-80px)
        +left_ltr(calc(50% - #{rem(480px)}))
        width: rem(64px)

    h2.desktop
      margin-bottom: rem(80px)

      +lte_ipad
        display: none

    h2.mobile
      +gte_ipad
        display: none

    .tour-3d
      +gte_ipad
        margin: 0 auto
        max-width: 1200px

      iframe
        +gte_ipad
          border-radius: 12px

  article.slide-7
    background-color: #ffccbc

  article.slide-8
    .b-switcher
      padding: 0 rem(16px)

    .contacts,
    .description,
    .b-fullwidth_map
      +gte_laptop
        min-height: 600px

    .contacts
      text-align: center
      position: relative

    .container.path_to_storage
      margin-top: rem(54px)

  article.slide-9
    background-color: #cfd8dc
