.p-users
  .b-input
    max-width: 100%

  input[type=submit]
    +lte_ipad
      width: 100%

  .b-phone_area
    .send-confirmation
      +gte_laptop
        width: 260px

  .block-container.photos
    .header-block
      display: flex

      +lte_ipad
        align-items: center
        flex-direction: column

        h2
          margin-bottom: rem(16px) !important

        .upload-photo
          margin-bottom: rem(24px)

      +gte_laptop
        align-items: flex-start
        flex-direction: row
        justify-content: space-between

        h2
          +margin-left_ltr(0)

    .upload-photo
      +link_underline

      &.btn-loading
        +progress_loader

  .container-profile a.sign-out
    +button_red
