.b-cookies-popup
  align-items: center
  align-self: stretch
  background: #fff
  box-shadow: -4px 8px 20px 0px rgba(0, 0, 0, 0.10)
  display: flex
  position: fixed
  transition: opacity var(--transition-duration)
  z-index: $z-index-sticky_area

  +lte_ipad
    border-radius: rem4(24px)
    bottom: rem4(45px)
    gap: rem4(24px)
    left: var(--container-padding-x)
    padding: rem4(16px) rem4(24px)
    right: var(--container-padding-x)

  +gte_laptop
    border-radius: 24px
    bottom: 45px
    gap: 24px
    left: 50%
    max-width: 416px
    padding: 16px 24px
    transform: translateX(-50%)

  &.is-closing
    opacity: 0
    pointer-events: none

  span
    +p4
    color: var(--gray-500)

  button
    +button_v4('brand', 'sm')
