=arrow_position($size)
  $px: math.div(-$size, 2)

  &[data-popper-placement^='top'] > [data-popper-arrow]
    bottom: $px
  &[data-popper-placement^='bottom'] > [data-popper-arrow]
    top: $px
  &[data-popper-placement^='left'] > [data-popper-arrow]
    right: $px
  &[data-popper-placement^='right'] > [data-popper-arrow]
    left: $px

.b-tooltip
  display: none
  font-weight: 400
  line-height: 1.3
  padding: rem(12px)
  z-index: $z-index-popup

  +lte_ipad
    background: #fff
    border-radius: rem(12px) rem(12px) 0 0
    border-top: 1px solid #f1f1f1
    color: var(--text-base)
    font-size: rem(16px)
    left: 0
    position: fixed
    width: 100%

  +gte_laptop
    background: var(--text-strong)
    border-radius: 8px
    color: #fff
    font-size: 14px
    position: absolute
    width: 320px

  &.is-visible-stage-1
    display: block

    +lte_ipad
      bottom: -100%
      transition: bottom var(--transition-duration) ease

    +gte_laptop
      opacity: 0
      transition: opacity var(--transition-duration) ease

      +lte_ipad

  &.is-visible-stage-2
    +lte_ipad
      bottom: 0%

    +gte_laptop
      opacity: 1

  p
    color: inherit
    font-size: inherit

  .headline
    font-weight: 700

    +lte_ipad
      color: var(--text-strong)
      margin-bottom: rem(12px)

    +gte_laptop
      margin-bottom: 4px

  .close-icon
    cursor: pointer
    height: rem(16px)
    position: absolute
    right: rem(12px)
    top: rem(12px)
    transition: color var(--transition-duration) ease
    width: rem(16px)

    +lte_ipad
      color: var(--text-secondary)

      &:active
        color: #000

    +gte_laptop
      color: #fff

      &:hover
        color: #afafaf

      &:active
        color: #eee

  +gte_laptop
    $size: 8px
    +arrow_position($size)

    & > [data-popper-arrow]
      height: $size
      width: $size

      &:before
        transition: background-color var(--transition-duration)
        background: var(--text-strong)
        content: ''
        height: 100%
        transform: rotate(45deg)
        width: 100%
        position: absolute
        z-index: -1

.b-tooltip-icon
  +margin-left_ltr(rem(4px))
  align-items: center
  color: var(--text-secondary)
  cursor: pointer
  display: flex
  flex-shrink: 0
  height: rem(16px)
  justify-content: center
  transition: color var(--transition-duration) ease
  user-select: none
  width: rem(16px)

  +gte_laptop
    &:hover
      color: var(--text-strong)

  &:active
    color: #666

.b-tooltip-shade
  height: 100%
  left: 0
  opacity: 0
  position: fixed
  top: 0
  transition: opacity var(--transition-duration) ease
  width: 100%
  z-index: $z-index-popup - 1

  +lte_ipad
    background: #000

    &.is-visible
      opacity: 0.4
