.b-box-header
  display: flex
  flex-direction: column
  margin-bottom: rem(57px)

  +lte_ipad
    display: none

  +gte_laptop
    min-width: 1000px

  &:after
    content: ''
    background: linear-gradient(90deg, #EF4E22 -0.07%, #FF784F 100.07%)
    height: rem(5px)

  .container
    display: flex
    width: 100%

    & > div
      border-right: 1px solid #ddd
      display: flex
      flex-direction: column
      height: rem(100px)
      justify-content: center
      padding: 0 rem(30px)

      &:first-child
        +padding-left_ltr(0)

    .storage
      flex-grow: 1
      max-width: rem(614px)

      & > a
        color: inherit
        font-size: rem(28px)
        letter-spacing: -0.28px
        line-height: rem(30px)
        margin-bottom: rem(4px)

      .info
        color: var(--text-base)
        font-size: rem(14px)
        line-height: rem(18px)

        span
          &:not(:last-child):after
            background: var(--text-base)
            content: ''
            display: inline-block
            height: rem(18px)
            margin: rem(-3px) rem(8px) 0
            vertical-align: middle
            width: 1px

    .label
      color: var(--primary-color)
      font-size: rem(16px)
      letter-spacing: -0.16px
      line-height: rem(35px)
      text-align: center

    .value
      color: #3e3f43
      font-size: rem(22px)
      line-height: rem(28px)
      text-align: center
