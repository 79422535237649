.b-locale_switcher-v3
  position: relative

  +gte_laptop
    &.b-locale_switcher-v3
      .checkbox-current-locale
        border-radius: 12px
        height: 40px
        padding: 10px 16px

        &:hover
          background-color: #e7e5e4

        &:active
          background-color: #d7d3d0

      .separator
        background-color: var(--border-on-color)
        border-radius: rem(2px)
        height: rem(20px)
        margin: 0 rem(11px)
        width: rem(2px)

      .flag
        gap: 12px

        &:before
          height: 24px
          width: 24px

      .current-country
        +margin-left_ltr(0)
        +padding-left_ltr(0)

        +ltr
          border-left: none

        +rtl
          border-right: none

        .earth-icon
          +margin-right_ltr(0)
          background-image: url('../images/blocks/b-locale_switcher/v3/earth-icon.svg')
          height: rem(20px)
          width: rem(20px)

      .country-name
        display: none

  .checkbox-current-locale
    align-items: center
    background-color: #eff3fa
    border-radius: rem(18px)
    box-sizing: border-box
    display: flex
    height: rem(28px)
    padding: 0 rem(16px)
    width: auto
    transition: background-color var(--transition-duration)

    +gte_laptop
      &:hover
        background-color: #f5c4aa
        cursor: pointer

        .current-country
          border-left: 2px solid var(--primary-color-hover)

  .current-country
    color: var(--text-base)
    font-size: rem(14px)
    line-height: rem(16px)
    +padding-left_ltr(rem(14px))
    +margin-left_ltr(rem(16px))
    display: flex

    +ltr
      border-left: 2px solid #d7e0ef

    +rtl
      border-right: 2px solid #d7e0ef

    .earth-icon
      +margin-right_ltr(rem(5px))
      background-image: url('../images/blocks/b-locale_switcher/earth-icon.svg')
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      height: rem(16px)
      width: rem(16px)

  .flag
    align-items: center
    display: flex
    flex-shrink: 0

    &:before
      content: ''
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      height: rem(16px)
      width: rem(24px)

    &.en:before
      background-image: url('../images/blocks/b-locale_switcher/en.svg')

    &.et:before
      background-image: url('../images/blocks/b-locale_switcher/et.svg')

    &.th:before
      background-image: url('../images/blocks/b-locale_switcher/th.svg')

    &.ru:before
      background-image: url('../images/blocks/b-locale_switcher/ru.svg')
