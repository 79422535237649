.p-pages-how_to_choose_box article.slide_1 .business_storage
  +laptop-new
    margin-top: scale-laptop(73px, 81px)

  +gte_desktop-new
    margin-top: 81px

  .container
    +lte_ipad
      padding: 0

  .slides
    overflow: hidden

    +lte_ipad
      padding-bottom: rem(56px) // added to compensate .slide box-shadow

    .slides-wrapper
      display: flex

    .slide-outer
      flex-shrink: 0
      padding-left: var(--container-padding-x)
      padding-right: var(--container-padding-x)
      width: 100%

      +lte_ipad
        overflow: hidden

      .slide-inner
        +mx-auto
        max-width: var(--container-max-width)

    .b-slider-pagination
      +gte_laptop
        display: none

    .slider-navigation
      +lte_ipad
        display: none

      +gte_laptop
        transform: translateY(-50%)
        display: flex
        justify-content: center
        margin-bottom: -24px

        .b-slider-prev
          +margin-right_ltr(32px)

  .b-slider-pagination
    +lte_ipad
      transition: transform var(--transition-duration), margin-bottom var(--transition-duration)
      position: relative
      z-index: 1

      &[data-index='0']
        margin-bottom: rem(-42px)
        transform: translateY(#{rem(-42px)})

      &[data-index='1']
        margin-bottom: rem(-26px)
        transform: translateY(#{rem(-26px)})

      &[data-index='2']
        margin-bottom: rem(24px)
        transform: translateY(#{rem(24px)})

  .slide
    position: relative

    +gte_laptop
      padding: 60px 90px 0
      height: 565px
      background: #ffff
      border: 2px solid #e6ecf7
      box-shadow: 0px 22px 56px -22px rgba(95, 120, 143, 0.15)
      border-radius: 30px
      max-width: 907px

    &:before
      background-image: url('../images/pages/how_to_choose_box/slide_1/business_storage/slide_background_circle.svg')
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

      +lte_ipad
        height: rem(260px)
        width: rem(260px)

      +gte_laptop
        height: 440px
        width: 440px

    picture
      position: absolute

    &.slide-1
      ul
        +lte_ipad
          padding-bottom: rem(290px)

      &:before
        +lte_ipad
          bottom: rem(18px)
          +right_ltr(calc(#{rem(-15px)} - var(--container-padding-x)))

        +gte_laptop
          +right_ltr(-271px)
          top: 73px

      picture
        +lte_ipad
          bottom: 0
          +left_ltr(var(--container-padding-x))

        +gte_laptop
          +right_ltr(-249px)
          top: 11px

    &.slide-2
      ul
        +lte_ipad
          padding-bottom: rem(294px)

      &:before
        +lte_ipad
          bottom: rem(18px)
          +right_ltr(calc(#{rem(-24px)} - var(--container-padding-x)))

        +gte_laptop
          +right_ltr(-278px)
          top: 80px

      picture
        +lte_ipad
          bottom: rem(46px)
          +left_ltr(calc(var(--container-padding-x) * -1 + #{rem(5px)}))

        +gte_laptop
          +right_ltr(-213px)
          top: 164px

    &.slide-3
      ul
        +lte_ipad
          padding-bottom: rem(286px)

      &:before
        +lte_ipad
          left: 50%
          transform: translateX(-50%)
          bottom: rem(18px)

        +gte_laptop
          +right_ltr(-245px)
          top: 68px

      picture
        +lte_ipad
          left: 50%
          transform: translateX(-50%)
          bottom: rem(18px)
          +margin-left_ltr(rem(-26px))

        +gte_laptop
          +right_ltr(-163px)
          top: 144px

    .headline
      color: var(--text-strong)
      font-weight: bold
      margin-bottom: rem(12px)

      +lte_ipad
        font-size: rem(20px)
        line-height: rem(24px)

      +gte_laptop
        font-size: 32px
        line-height: 36px
        max-width: 689px

    p
      color: var(--text-base)
      font-size: rem(16px)
      line-height: 1.3

      +lte_ipad
        margin-bottom: rem(36px)

      +gte_laptop
        margin-bottom: 48px
        max-width: 588px

    ul
      li
        display: flex
        align-items: center
        color: var(--text-base)
        font-size: rem(14px)
        line-height: 1.3
        margin-bottom: rem(20px)

        +gte_laptop
          max-width: 508px

        &:before
          background-image: url('../images/pages/how_to_choose_box/slide_1/business_storage/checkmark.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          flex-shrink: 0
          height: rem(28px)
          width: rem(28px)

          +lte_ipad
            +margin-right_ltr(rem(12px))

          +gte_laptop
            +margin-right_ltr(20px)
