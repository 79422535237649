.b-thanks-modal
  .modal-inner
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

    +lte_ipad
      gap: rem4(12px)
      padding-left: rem4(16px)
      padding-right: rem4(16px)
      width: rem4(374px)

    +gte_laptop
      gap: 12px
      padding-left: 16px
      padding-right: 16px
      width: 374px

  .title
    font-weight: 700

    +lte_ipad
      font-size: rem4(21px)
      line-height: rem4(26px)

    +gte_laptop
      font-size: 21px
      line-height: 26px

  .note
    color: var(--gray-500)

    +lte_ipad
      font-size: rem4(15px)
      line-height: rem4(20px)

    +gte_laptop
      font-size: 15px
      line-height: 20px
