.p-storages-index
  h1
    +iphone
      font-size: rem(20px)
      font-weight: 700
      line-height: rem(24px)

    +lte_ipad
      margin-bottom: rem(24px)

    +gte_laptop
      margin-bottom: 32px

  #container_wrapper
    padding-bottom: 0

    +gte_laptop
      display: flex
      flex-direction: column
      overflow: hidden

  .additional-seo
    padding-top: rem(40px)
    margin-bottom: 0

    h2.left
      +text-align_ltr(left)

    a
      +link(var(--primary-color))

      &.read-more.active
        display: none

    .spoiler:not(.active)
      display: none

    h2
      font-size: rem(24px)
      margin-bottom: rem(10px)

    h3
      font-size: rem(18px)
      margin-bottom: rem(8px)

    p,
    ul,
    ol
      color: var(--text-base)
      margin-bottom: rem(18px)

    p
      &.italic
        font-style: italic

    ul
      list-style-type: disc
      +margin-left_ltr(rem(30px))

      li
        margin-bottom: rem(10px)

    ol
      list-style-type: decimal
      +margin-left_ltr(rem(30px))

      li
        margin-bottom: rem(10px)

    table
      margin-bottom: rem(20px)

      th,
      td
        border-color: var(--text-base)
        border-style: solid
        border-width: 1px
        border-spacing: 0
        border-collapse: separate
        padding: rem(10px)
        line-height: 1.3
      th
        font-weight: 600
        text-align: center
      td
        color: var(--text-base)

  .districts
    margin-top: rem(16px)

    h3
      text-align: center

  .world-note
    +lte_ipad
      margin-top: rem(16px)

    +gte_laptop
      margin-top: 24px
