.p-orders-v2-profiles .content-column
  .tabs
    display: flex
    gap: rem(16px)
    margin: rem(24px) 0

    .tab
      align-items: center
      background: #eff3fa
      border-radius: rem(12px)
      color: var(--text-base)
      display: flex
      flex-basis: 50%
      font-weight: 700
      justify-content: center
      text-align: center

      +lte_ipad
        font-size: rem(14px)
        height: rem(48px)
        padding: 0 rem(4px)

      +gte_laptop
        font-size: 16px
        height: 59px

    .tab.is-active,
    a.tab:active
      background: #5096ff
      color: #fff

    a.tab
      +gte_laptop
        &:hover
          background: #5096ff
          color: #fff
          text-decoration: none

  .note
    line-height: 1.3
    margin: rem(24px) 0

  input#profile_address,
  input#profile_actual_address,
  input#profile_passport_who
    width: 100%

  .passport
    display: flex
    gap: rem(12px)

    .b-input
      +lte_ipad
        flex-basis: 50%

      input
        +gte_laptop
          width: 169px

  .b-input + h3
    margin-top: rem(24px)
