.b-input
  &:not(:last-child)
    margin-bottom: rem(16px)

  &.field_with_errors
    input,
    select,
    textarea
      border-color: var(--input-border-color-error)

      &:focus
        border-color: var(--input-border-color-error)

  &.animated
    position: relative

    label
      color: var(--input-label-color)
      font-size: var(--input-font-size)
      left: var(--input-padding-x)
      letter-spacing: var(--input-letter-spacing)
      line-height: var(--input-line-height)
      max-width: calc(100% - var(--input-padding-x) * 2)
      overflow: hidden
      position: absolute
      right: var(--input-padding-x)
      text-overflow: ellipsis
      top: rem(16px)
      transition: color var(--transition-duration)
      white-space: nowrap

      .v3 &
        top: rem(12px)

      .v4 &
        +lte_ipad
          top: rem4(15px)

        +gte_laptop
          top: 15px

    // multiline textarea placeholder
    &.textarea label
      white-space: normal

    input,
    select,
    textarea,
    &.custom-select .select-placeholder
      +placeholder
        opacity: 0

      &:focus,
      &:not(:placeholder-shown)
        padding-top: rem(12px)

        & + label
          +p5
          max-width: 100%
          top: 6px
          // transform multiline textarea placeholder into
          // single line wrapped placeholder
          white-space: nowrap

      .v4 &
        +lte_ipad
          padding-top: rem4(15px)

        +gte_laptop
          padding-top: 15px

    textarea
      &:focus,
      &:not(:placeholder-shown)
        padding-top: rem(20px)

      .v4 &
        &:focus,
        &:not(:placeholder-shown)
          +lte_ipad
            padding-top: rem4(34px)

          +gte_ipad
            padding-top: 37px

          & + label
            +lte_ipad
              top: rem4(15px)

            +gte_ipad
              top: 15px

    input,
    select,
    textarea,
    .select-placeholder
      color: var(--text-base) !important
      height: var(--input-height)
      line-height: 1

      &:focus
        & + label
          color: var(--input-label-color-focus)

      & + label
        transform-origin: 0% 100%
        transition: all var(--transition-duration) ease-out

      &.disabled
        & + label
          color: var(--text-secondary) !important

  label
    +text-align_ltr(left)
    color: var(--text-secondary)
    white-space: nowrap

  input,
  select,
  textarea,
  .select-placeholder
    background-color: var(--input-background-color)
    border-radius: var(--input-border-radius)
    border: 1px solid var(--input-border-color)
    color: var(--text-strong)
    font-size: var(--input-font-size)
    height: var(--input-height)
    letter-spacing: var(--input-letter-spacing)
    line-height: var(--input-line-height)
    padding: 0 var(--input-padding-x)
    transition: background-color var(--transition-duration), border-color var(--transition-duration)
    width: 100%

    +placeholder
      color: var(--text-secondary)

    +gte_laptop
      &:hover
        background-color: var(--input-background-color-hover)
        border-color: var(--input-border-color-hover)

  input:focus,
  select:focus,
  textarea:focus,
  &.is-focused .select-placeholder
    background-color: var(--input-background-color-focus)
    border-color: var(--input-border-color-focus)
    border-width: var(--input-border-width-focus)
    outline: none

  input[disabled],
  select[disabled],
  textarea[disabled],
  input.disabled,
  select.disabled,
  textarea.disabled,
  .select-placeholder.is-disabled
    border: 1px solid #eaeaea
    color: var(--text-secondary) !important
    background: #fafafa

  select
    +padding-left_ltr(rem(12px))
    outline: none

    .v4 &
      +lte_ipad
        +padding-left_ltr(rem4(12px))

      +gte_laptop
        +padding-left_ltr(12px)

  &.custom-select
    --custom-select-padding-left: var(--input-padding-x)
    // empty space for chevron
    +lte_ipad
      --custom-select-padding-right: #{rem4(60px)}
    +gte_laptop
      --custom-select-padding-right: 60px

    position: relative
    +text-align_ltr(left)

    +gte_laptop
      &:hover:not(.disabled):not(.is-focused)
        .b-svg-icon
          +svg_icon_hover

    &.is-focused
      .dropdown
        opacity: 1
        transition: opacity var(--transition-duration) ease
        z-index: 1

      .b-svg-icon
        +svg_icon_active
        svg
          transform: rotate(0deg)

      .v4 & .select-placeholder
        padding-left: calc(var(--custom-select-padding-left) - var(--input-border-width-focus) + 1px)
        padding-right: calc(var(--custom-select-padding-right) - var(--input-border-width-focus) + 1px)

    .select-placeholder
      align-items: center
      cursor: default
      display: flex
      height: var(--input-height)
      outline: none
      padding-right: rem(38px) // empty space for chevron

      &.is-disabled
        .b-svg-icon
          opacity: 0.5

      .v4 &
        +lte_ipad
          padding-right: rem4(60px) // empty space for chevron

        +gte_laptop
          padding-right: 60px // empty space for chevron

        &:after
          +font-icon('\E82D')
          color: var(--gray-400)
          position: absolute
          top: 50%
          transform: translateY(-50%)

          +lte_ipad
            font-size: rem4(20px)
            margin-top: rem4(2px)
            right: rem4(20px)

          +gte_laptop
            font-size: 20px
            margin-top: 2px
            right: 20px

    .placeholder-inner,
    label
      pointer-events: none
      user-select: none

    .placeholder-inner
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    .b-svg-icon
      height: rem(24px)
      position: absolute
      right: rem(12px)
      top: rem(12px)
      width: rem(24px)

      svg
        color: var(--text-secondary)
        height: rem(8px)
        transform: rotate(180deg)
        transition: transform var(--transition-duration) ease, color var(--transition-duration) ease
        width: rem(8px)

      .v4 &
        display: none

    .dropdown
      background-color: var(--input-background-color-focus)
      border-radius: var(--input-border-radius)
      border: 1px solid var(--input-border-color)
      box-shadow: 0px rem(4px) rem(12px) 0px rgba(176, 183, 204, 0.12)
      margin-top: rem(4px)
      opacity: 0
      overflow: scroll
      padding: rem(4px) rem(3px)
      position: absolute
      transition: none
      width: 100%
      z-index: -1

      +lte_ipad
        max-height: rem(310px)

      +gte_laptop
        max-height: 410px

      .v4 &
        box-shadow: none
        padding: var(--spacing-md)

        +lte_ipad
          margin-top: rem4(4px)

        +gte_laptop
          margin-top: 4px

      .option
        border-radius: var(--input-border-radius)
        cursor: default !important
        line-height: 1.3
        padding: rem(10px) rem(12px)
        transition: background-color var(--transition-duration) ease
        user-select: none

        &.is-current
          color: var(--primary-color)
          font-weight: 700

        &:hover
          +gte_laptop
            background-color: rgba($primary, 0.10)

        &:active
          background-color: rgba($primary, 0.30)

        .v4 &
          +lte_ipad
            font-size: rem4(15px)
            line-height: rem4(40px)
            padding: 0px rem4(12px)

          +gte_laptop
            font-size: 15px
            line-height: 40px
            padding: 0px 12px

            &:hover
              background-color: var(--gray-100)

          &:active
            background-color: var(--gray-50)

  &.boolean
    align-items: center
    display: flex
    flex-wrap: wrap

    input
      display: none

      &:checked + label:before
        background-image: url('../images/pages/orders-v2/checkmark.svg')
        background-position: center
        background-repeat: no-repeat
        background-size: contain

    label
      align-items: center
      color: var(--text-base)
      display: inline-flex
      font-size: rem(14px)
      line-height: 1.3
      margin: 0
      user-select: none

      &:before
        +margin-right_ltr(rem(12px))
        border-radius: rem(4px)
        border: 1px solid #eaeaea
        content: ''
        flex-shrink: 0
        height: rem(24px)
        width: rem(24px)

    &:not(.disabled) label
      +gte_laptop
        &:hover:before
          cursor: pointer

    &.field_with_errors
      label:before
        border-color: var(--input-border-color-error)

      .error-message
        flex-basis: 100%

        &.hidden
          display: block !important

  &.blue-background
    input,
    select,
    textarea,
    &.custom-select .select-placeholder
      background: #fff
      border-radius: rem(20px)
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
      height: rem(56px)

    input,
    select,
    textarea
      padding: 0 rem(24px)

    &.custom-select .select-placeholder
      .b-svg-icon
        top: rem(16px)

    .error
      color: #fff
      line-height: 1.33

      +lte_ipad
        font-size: rem(12px)
        margin-top: rem(7px)

      +gte_laptop
        font-size: 13px
        margin-top: 4px
