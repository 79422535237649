.b-banner
  margin-bottom: rem(24px)
  border-radius: rem(12px)
  overflow: hidden
  position: relative
  background-color: var(--secondary-color)

  &.general
    padding: rem(24px)

    .inner
      color: #fff

      .title
        font-size: rem(24px)
        font-weight: 700
        margin-bottom: rem(20px)

      .body
        font-size: rem(16px)
        line-height: 1.4

        b.tel
          white-space: nowrap

        .note
          font-style: italic
          line-height: 24px

        a
          font-weight: 600

          &:hover
            color: #fff

  &.first-box
    display: flex
    box-sizing: border-box

    +lte_ipad
      text-align: center
      padding: rem(220px) rem(16px) rem(24px)

    +gte_laptop
      padding-bottom: 60px
      +padding-left_ltr(50%)
      +padding-right_ltr(100px)
      padding-top: 60px

    &:before,
    &:after
      +rtl_flip
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

    &:before
      background-image: url('../images/blocks/b-banners/banner-first-box-left.svg')

      +lte_ipad
        top: rem(-74px)
        +left_ltr(rem(-20px))
        height: rem(280px) // gte_laptop * 60%
        width: rem(292px)  // gte_laptop * 60%

      +gte_laptop
        height: 466px
        +left_ltr(-72px)
        top: -98px
        width: 488px

    &:after
      +gte_laptop
        background-image: url('../images/blocks/b-banners/banner-first-box-right.svg')
        bottom: -112px
        height: 302px
        +right_ltr(56px)
        width: 406px

    .inner
      z-index: 1

      .title
        color: #fff
        font-weight: 700

        +lte_ipad
          font-size: rem(24px)
          line-height: rem(28px)
          margin-bottom: rem(24px)

        +gte_laptop
          font-size: 44px
          line-height: 48px
          margin-bottom: 44px

      a
        +button_white

  &.pending-identity-documents
    display: flex
    box-sizing: border-box

    +lte_ipad
      text-align: center
      padding: rem(200px) rem(16px) rem(24px)
      flex-direction: column

    +gte_laptop
      padding-bottom: 60px
      +padding-left_ltr(276px)
      +padding-right_ltr(100px)
      padding-top: 60px

    &:before,
    &:after
      +rtl_flip
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

    &:before
      background-image: url('../images/blocks/b-banners/banner-pending-identity-documents-left.svg')

      +lte_ipad
        top: rem(6px)
        +left_ltr(calc(50% - #{rem(90px)}))
        height: rem(182px) // gte_laptop * 73%
        width: rem(174px)  // gte_laptop * 73%

      +gte_laptop
        height: 250px
        +left_ltr(12px)
        top: 12px
        width: 238px

    &:after
      +gte_laptop
        background-image: url('../images/blocks/b-banners/banner-pending-identity-documents-right.svg')
        top: -30px
        height: 332px
        +right_ltr(84px)
        width: 360px

    .inner
      color: #fff
      z-index: 1

      .title
        font-size: rem(24px)
        font-weight: 700
        line-height: rem(28px)
        margin-bottom: rem(24px)

      .body
        line-height: 1.3

        +lte_ipad
          font-size: rem(14px)

        +gte_laptop
          font-size: 16px

      &.centered
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        +lte_ipad
          margin-top: rem(24px)

        +gte_laptop
          +margin-left_ltr(10%)

        a.btn
          +button_white
          margin-bottom: rem(12px)

          +lte_ipad
            width: 100%

        a.link
          +link_underline
          +link_white
          line-height: 1.3

  &.pending-verification
    display: flex

    +lte_ipad
      text-align: center
      padding: rem(200px) rem(16px) rem(24px)
      flex-direction: column

    +gte_laptop
      padding-bottom: 24px
      padding-top: 24px
      +padding-right_ltr(20%)
      +padding-left_ltr(184px)

    .inner
      z-index: 1

      .title
        color: #fff
        font-size: rem(24px)
        font-weight: 700
        line-height: rem(28px)
        margin-bottom: rem(24px)

      .body
        color: #fff
        line-height: 1.3

        +lte_ipad
          font-size: rem(14px)

        +gte_laptop
          font-size: 16px

    &:before,
    &:after
      +rtl_flip
      background-repeat: no-repeat
      background-size: contain
      content: ''
      position: absolute

    &:before
      background-image: url('../images/blocks/b-banners/banner-pending-verification-left.svg')

      +lte_ipad
        top: rem(10px)
        +left_ltr(calc(50% - #{rem(58px)}))
        height: 180px // gte_laptop * 90%
        width: 114px  // gte_laptop * 90%

      +gte_laptop
        height: 200px
        +left_ltr(18px)
        top: -16px
        width: 127px

    &:after
      +gte_laptop
        background-image: url('../images/blocks/b-banners/banner-pending-verification-right.svg')
        top: -20px
        height: 255px
        +right_ltr(36px)
        width: 190px
