.p-pages .map-block,
.p-info_pages .map-block
  +lte_ipad
    margin-top: rem(-10px)
    margin-bottom: rem(46px)

  +gte_laptop
    margin-bottom: 70px

  #map_desktop
    width: 100%

    +lte_ipad
      display: none
      height: rem(516px)

    +gte_laptop
      height: 635px

  #map_mobile
    position: relative
    height: rem(130px)

    &:before
      background: linear-gradient(0deg, rgba(242, 100, 43, 0.73), rgba(242, 100, 43, 0.73)), url('../images/pages/info_pages/ymap-mobile.jpg')
      background-blend-mode: multiply, normal
      background-position: 50% 0
      background-size: cover
      content: ''
      height: 100%
      position: absolute
      width: 100%
      z-index: 1

    .b-button
      align-items: center
      display: flex
      justify-content: center
      left: 50%
      position: absolute
      top: 50%
      transform: translateX(-50%) translateY(-50%)
      white-space: nowrap
      z-index: 2

      &:before
        background-image: url('../images/pages/info_pages/marker_white.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        display: block
        height: rem(22px)
        +margin-right_ltr(rem(6px))
        width: rem(12px)

    +gte_laptop
      display: none
