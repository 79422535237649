/* https://fontstorage.com/ru/font/ukasz-dziedzic/lato */

/* lato regular - n4 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../fonts/lato/latoregular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/lato/latoregular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato italic */
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: local('Lato Italic'), local('Lato-Italic'),
       url('../fonts/lato/latoitalic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/lato/latoitalic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato medium - b5 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Lato Medium'), local('Lato-Medium'),
       url('../fonts/lato/latomedium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/lato/latomedium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato semibold - b6 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Lato Semi Bold'), local('Lato-SemiBold'),
       url('../fonts/lato/latosemibold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/lato/latosemibold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato bold - b7 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'),
       url('../fonts/lato/latobold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/lato/latobold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato heavy - b8 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Lato Heavy'), local('Lato-Heavy'),
       url('../fonts/lato/latoheavy.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/lato/latoheavy.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* caveat regular - n4 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Caveat Regular'), local('Caveat-Regular'),
    url('../fonts/caveat/caveatregular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/caveat/caveatregular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* caveat bold - b7 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Caveat Bold'), local('Caveat-Bold'),
       url('../fonts/caveat/caveatbold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+        */
       url('../fonts/caveat/caveatbold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-500 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/manrope/manrope-v15-cyrillic_latin-500.woff2') format('woff2');
}
/* manrope-600 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/manrope/manrope-v15-cyrillic_latin-600.woff2') format('woff2');
}
/* manrope-700 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/manrope/manrope-v15-cyrillic_latin-700.woff2') format('woff2');
}

/* https://fontello.com/ */
@font-face {
  font-family: 'minisklad_icons';
  src: url('../fonts/minisklad_icons/minisklad_icons.woff2') format('woff2');
}
