=progress_loader
  color: transparent !important
  pointer-events: none
  position: relative

  &:after
    content: ''
    animation: loader 500ms infinite linear
    border: 2px solid #fb4a23
    border-radius: 50%
    border-right-color: transparent
    border-top-color: transparent
    display: block
    height: 1.4rem
    width: 1.4rem
    position: absolute
    +left_ltr(calc(50% - (1.4rem / 2)))
    top: calc(50% - (1.4rem / 2))
    transform-origin: center
    position: absolute

@keyframes loader
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
