=container-base
  +mx-auto
  max-width: var(--container-max-width)
  position: relative
  width: 100%

=container
  +container-base
  padding-left: var(--container-padding-x)
  padding-right: var(--container-padding-x)

=container-desktop
  +gte_laptop
    +container-base
    padding-left: var(--container-padding-x)
    padding-right: var(--container-padding-x)

=container-ipad
  +ipad
    +container-base
    padding-left: var(--container-padding-x)
    padding-right: var(--container-padding-x)

=container-mobile
  +lte_ipad
    +container-base
    padding-left: var(--container-padding-x)
    padding-right: var(--container-padding-x)
