article.mobile_app_tabs
  +container-desktop
  padding-bottom: 0

  +lte_ipad
    gap: rem4(24px)

  +gte_laptop
    gap: var(--spacing-6xl)

  header,
  section
    +container-mobile

  .swiper
    +mx-auto
    display: flex
    flex-direction: column
    height: 100%

    +lte_ipad
      width: 100%

    +gte_laptop
      width: 584px

    .swiper-wrapper
      background: #f5f5f5
      overflow: hidden

      +lte_ipad
        border-radius: rem4(24px)
        height: rem4(447px)

      +gte_laptop
        border-radius: 32px
        height: 479px

    .swiper-slide
      display: flex
      flex-shrink: 0
      justify-content: center

      +lte_ipad
        border-radius: rem4(24px)

      +gte_laptop
        border-radius: 32px

      img
        width: 100%

      &.slide_1
        align-items: flex-end
      &.slide_2
        align-items: center
      &.slide_3
        align-items: flex-start
      &.slide_4
        align-items: flex-end
      &.slide_5
        align-items: flex-start

  .b-tabs
    .tab
      &.slide_1:before
        +font-icon('\E816')
      &.slide_2:before
        +font-icon('\E810')
      &.slide_3:before
        +font-icon('\E821')
      &.slide_4:before
        +font-icon('\E825')
      &.slide_5:before
        +font-icon('\E806')
