.p-storages-show
  .boxes-slider
    .box
      box-sizing: border-box
      cursor: pointer
      display: flex

      +lte_ipad
        border-bottom: 2px solid #f1f1f1
        padding: rem(16px) 0

      +gte_laptop
        border-radius: 20px
        border: 2px solid #f1f1f1
        box-shadow: 0px 12px 44px -22px rgba(0, 0, 0, 0.15)
        margin-bottom: 12px
        min-height: 100px
        padding-bottom: 18px
        +padding-left_ltr(42px)
        +padding-right_ltr(24px)
        padding-top: 18px
        width: 100%

        &:hover,
        &:active
          border: 2px solid var(--primary-color)

        &:active
          box-shadow: none

      &:hover
        text-decoration: none
        color: var(--text-strong)

      &.disabled
        display: none

      .inner
        position: relative
        align-items: center
        justify-content: space-between
        display: flex
        width: 100%

        +lte_ipad
          gap: rem(16px)

        .item
          flex-grow: 1

          +gte_laptop
            flex-basis: rem(100px)

          &.level-number
            flex-grow: 0

        .number
          color: #ced6e4
          font-weight: 700
          letter-spacing: 0.1em
          white-space: nowrap

          +lte_ipad
            font-size: rem(12px)
            line-height: rem(16px)
            margin: rem(2px) 0

          +gte_laptop
            font-size: 16px
            +left_ltr(-70px)
            position: absolute
            text-align: center
            top: 20px
            transform: rotate(-90deg)
            width: 98px

        .level
          color: var(--secondary-color)
          font-weight: 900

          +lte_ipad
            font-size: rem(16px)
            min-width: rem(44px)

          +gte_laptop
            width: 70px
            font-size: 24px

        .discount-wrapper
          +gte_laptop
            margin-top: 10px

        .discount
          background-color: var(--secondary-color)
          border-radius: rem(10px)
          color: #fff
          display: inline-block
          font-size: rem(12px)
          font-weight: 700
          padding: rem(6px) rem(8px)
          text-align: center
          white-space: nowrap

        .square
          font-size: rem(16px)
          font-weight: 700
          font-size: rem(16px)
          margin-bottom: rem(6px)

        .dimension
          color: var(--text-secondary)
          font-size: rem(14px)

        .features
          flex-grow: 2
          min-width: rem(128px)

          +lte_ipad
            display: none

          .entresol,
          .high-box
            display: flex
            align-items: center

            .icon
              height: rem(32px)
              width: rem(32px)
              +margin-right_ltr(rem(8px))
              position: relative
              flex-shrink: 0

              &:before
                background-image: url('../images/blocks/b-box/icon-entresol.svg')
                background-position: center
                background-repeat: no-repeat
                background-size: contain
                content: ''
                height: rem(22px)
                +left_ltr(rem(2px))
                position: absolute
                top: rem(5px)
                width: rem(26px)

            .text
              color: var(--text-base)
              font-size: rem(12px)
              line-height: rem(14px)
              +margin-right_ltr(rem(8px))

          .high-box
            .icon
              &:before
                background-image: url('../images/blocks/b-box/icon-high-box.svg')
                width: rem(24px)
                height: rem(24px)

        .price
          +button_red
          font-size: rem(12px) !important
          padding: 0 rem(8px)
          white-space: nowrap

          +lte_ipad
            max-width: rem(100px)
            width: rem(100px)

          +gte_laptop
            flex-grow: 2
            max-width: rem(175px)

          .full-price
            +margin-right_ltr(rem(4px))
            text-decoration: line-through
            opacity: 0.6

            +lte_ipad
              display: none
