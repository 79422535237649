.p-privacies
  .container.privacy
    h1
      margin-bottom: rem4(40px)
      text-align: left

      +lte_ipad
        line-height: rem4(36px)
        margin-bottom: rem4(32px)

      .hint
        margin-top: rem4(14px)
        font-size: 18px
        line-height: rem4(26px)
        font-weight: normal

        +lte_ipad
          font-size: rem4(16px)

    a
      color: var(--primary-color)
      white-space: nowrap

    p
      font-size: 18px
      line-height: 26px
      margin-bottom: 24px

      +lte_ipad
        font-size: rem4(16px)
        margin-bottom: rem4(20px)
        line-height: 1.5
        letter-spacing: rem4(0.16px)

    h2
      color: var(--text-headline)
      margin-top: rem4(32px)
      margin-bottom: rem4(24px)
      letter-spacing: -0.48px
      font-size: rem4(24px)
      text-align: left

      +lte_ipad
        font-size: rem4(18px)
        margin-bottom: rem4(20px)

    ol,
    ul
      +padding-left_ltr(rem4(20px))
      +lte_ipad
        font-size: rem4(16px)

      li
        margin-bottom: rem4(20px)
        line-height: 1.5

    ol
      list-style: decimal

      ul
        margin-top: rem4(20px)
        list-style: disc

        li
          margin-bottom: 0

    ul
      list-style: disc
