// fuck designer units: design is made for 360px screen size
// we had to divide everything to 1.125 (18/16) to scale it properly to 320px
@function fd($size)
  @return math.div($size, 1.125)

.p-pages-qr
  #container_wrapper
    max-width: 100vw
    overflow: hidden
    position: relative

  // disabled because it affects the whole page even header
  // .container
  //   +padding-left_ltr(rem(fd(24px)))
  //   +padding-right_ltr(rem(fd(24px)))

  .sticky-container
    bottom: rem(fd(62px))
    position: fixed
    z-index: 10

    .sticky-button
      +button_red

      +lte_ipad
        font-size: rem(14px)
        height: rem(52px)
        min-width: 260px

      +gte_laptop
        font-size: 20px
        height: 56px
        min-width: 280px

      &.apple,
      &.google
        &:after
          background-repeat: no-repeat
          background-size: contain
          content: ''
          display: inline-block
          height: rem(fd(22px))
          +margin-left_ltr(rem(fd(14px)))
          width: rem(fd(22px))

      @each $type in apple, google
        &.#{$type}:after
          background-image: url('../images/pages/qr/store_#{$type}.svg')

  article
    padding: rem(fd(56px)) 0
    margin: 0

    h1,
    h2,
    h3
      margin: 0 0 rem(fd(12px)) 0
      max-width: none
      +text-align_ltr(left)

    h2
      font-size: rem(fd(24px))
      line-height: rem(fd(28px))

    h3
      font-size: rem(fd(16px))
      line-height: rem(fd(24px))

    .note
      font-size: rem(fd(16px))
      line-height: rem(fd(20px))
      color: var(--text-base)

      &-misc
        font-size: rem(fd(14px))
        line-height: 1.3

  .slide-1
    height: calc(#{rem(1910px)} + var(--slidesYOffset))
    padding-top: rem(fd(52px))
    position: relative

    .head
      margin-bottom: rem(fd(68px))

      h1
        font-size: rem(fd(32px))
        line-height: rem(fd(44px))

    .phone-fixator
      &.is-fixed
        max-width: 100vw
        position: fixed
        top: rem(-489px)
        z-index: 9

      &.is-repositioned
        position: absolute
        margin-top: calc(var(--repositionY) - #{rem(70px)})

    .polygon
      $top-offset: 107px
      $bottom-offset: 20px
      $height: 524px

      background-color: #fff
      height: #{rem(fd($top-offset + $height + $bottom-offset))}
      position: relative
      width: 100%
      z-index: 1

      &:before
        +rtl_flip
        background-image: url('../images/pages/qr/polygon.svg')
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(fd($height))
        top: rem(fd($top-offset))
        position: absolute
        width: rem(fd(324px))

    .phone-container
      height: rem(fd(552px))
      +margin-left_ltr(rem(fd(57px)))
      padding: rem(fd(15px)) rem(fd(13px))
      position: absolute
      width: rem(fd(255px))
      z-index: 2

      +ltr
        transform: rotate(25.34deg)

      +rtl
        transform: rotate(-25.34deg)

      &:before
        background-image: url('../images/pages/qr/phone_container.svg')
        background-size: contain
        content: ''
        filter: drop-shadow(#{rem(fd(40px))} #{rem(fd(60px))} #{rem(fd(80px))} rgba(75, 114, 150, 0.15))
        height: 100%
        +left_ltr(0)
        position: absolute
        top: 0
        width: 100%
        z-index: -1

      .screens
        position: relative

        .other-screens
          opacity: 0

        .screen
          +left_ltr(0)
          max-width: 100%
          opacity: 0
          position: absolute
          top: 0
          width: rem(203px)

          &-0,
          &-active
            opacity: 1

          &:not(&-0)
            transition: opacity 750ms

    .how-it-works
      h2
        margin-bottom: rem(fd(32px))
        text-align: center

      .slides
        overflow: hidden

        .slides-wrapper
          display: flex

        .slide
          align-items: center
          display: flex
          flex-shrink: 0
          gap: rem(16px)
          +padding_left_ltr(rem(fd(50px)))

          &:not(:last-child)
            width: calc(100vw - #{rem(fd(50px + 17px))})

          &:last-child
            width: calc(100vw)

          .number
            background: #e6ecf7
            border-radius: rem(fd(12px))
            color: #5096ff
            flex-shrink: 0
            font-size: rem(fd(20px))
            font-weight: 700
            height: rem(fd(40px))
            line-height: rem(fd(24px))
            padding: rem(fd(8px)) rem(fd(12px))
            width: rem(fd(36px))

          .label
            color: var(--text-base)
            font-size: rem(fd(18px))
            line-height: rem(fd(26px))
            white-space: nowrap

  .slide-2
    background: #eff3fa

    .phone-isometric
      position: absolute
      margin-top: rem(fd(26px))
      +margin-left_ltr(rem(fd(-139px)))

      &:before
        +rtl_flip
        background-image: url('../images/pages/qr/phone_isometric.svg')
        background-size: contain
        content: ''
        height: rem(fd(577px))
        position: absolute
        width: rem(fd(777px))
        z-index: 1

      img
        margin-bottom: 0
        +margin-left_ltr(rem(fd(17px)))
        +margin-right_ltr(0)
        margin-top: rem(fd(17px))
        position: absolute
        width: rem(fd(705px))
        z-index: 2

    .thumb-up
      font-size: rem(fd(32px))
      font-weight: 700
      line-height: rem(fd(28px))
      margin-bottom: rem(fd(12px))
      margin-top: rem(fd(465px))

  .slide-3
    .note
      margin-bottom: rem(fd(32px))

    .items
      display: flex
      justify-content: space-between
      margin-bottom: rem(fd(76px))

      .item
        align-items: center
        color: var(--text-base)
        display: flex
        flex-direction: column
        font-size: rem(fd(16px))
        line-height: rem(fd(20px))
        position: relative

        &:before
          background: #eff3fa
          border-radius: rem(fd(80px))
          content: ''
          display: block
          height: rem(fd(80px))
          width: rem(fd(80px))
          margin-bottom: rem(fd(16px))

        &:after
          background-size: contain
          content: ''
          height: rem(fd(32px))
          position: absolute
          width: rem(fd(32px))
          left: 50%
          top: rem(fd(40px))
          transform: translateX(rem(fd(-16px))) translateY(rem(fd(-16px)))

        @each $i in 1,2,3
          &-#{$i}:after
            background-image: url('../images/pages/qr/icon_#{$i}.svg')

    .b-store_badges
      flex-direction: column
