.b-progress
  display: flex
  flex-direction: column
  padding-top: rem(34px)
  margin-bottom: rem(40px)

  &.mobile
    height: rem(90px)
    max-width: 100vh

    +gte_laptop
      display: none

    .b-progress-labels
      justify-content: space-between

  &.desktop
    height: 94px

    +lte_ipad
      display: none

    .b-progress-labels
      .item
        flex-basis: 100px
        flex-grow: 1
        flex-shrink: 1

.b-steps-lines
  padding: 0 9%
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: rem(-36px)

  &.short-version
    padding: 0 16%

  .step
    content: ''
    height: rem(8px)
    width: 100%
    background-color: #e6ecf7
    position: relative

    &.active
      background-color: var(--primary-color)

.b-progress-labels
  display: flex
  align-items: center

  .item
    align-items: center
    color: var(--text-secondary)
    display: flex
    flex-direction: column
    font-size: rem(14px)
    line-height: rem(17px)

    .icon
      background-color: #e6ecf7
      border-radius: rem(20px)
      height: rem(40px)
      margin-bottom: rem(6px)
      position: relative
      width: rem(40px)

      &:before
        background-color: #fff
        border-radius: rem(8px)
        content: ''
        height: rem(14px)
        +left_ltr(rem(13px))
        position: absolute
        top: rem(13px)
        width: rem(14px)

      &.active
        background-color: var(--primary-color)
        background-color: var(--primary-color)
        border-radius: rem(26px)
        box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.1)

        +lte_ipad
          height: rem(44px)
          width: rem(44px)

        +gte_laptop
          height: 52px
          width: 52px

        &:before
          display: none

        &:after
          background-image: url('../images/pages/orders/check.svg')
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(16px)
          width: rem(16px)
          position: absolute

          +lte_ipad
            +left_ltr(rem(14px))
            top: rem(14px)

          +gte_laptop
            +left_ltr(18px)
            top: 18px

    .label
      +lte_ipad
        display: none

    &.current
      .label
        color: var(--text-headline)
        font-size: rem(16px)
        font-weight: 700
        line-height: 1.3

      .icon
        width: rem(62px)
        height: rem(62px)
        border-radius: rem(32px)
        background-color: var(--secondary-color)
        border: 6px solid #fff
        box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.15)

        &:before
          display: none

        &:after
          background-repeat: no-repeat
          background-size: contain
          content: ''
          height: rem(24px)
          +left_ltr(rem(13px))
          position: absolute
          top: rem(13px)
          width: rem(24px)

        &.tariff
          &:after
            background-image: url('../images/pages/orders/tariff.svg')

        &.registration
          &:after
            background-image: url('../images/pages/orders/registration.svg')
            +left_ltr(rem(12px))
            top: rem(14px)

        &.profile
          &:after
            background-image: url('../images/pages/orders/profile.svg')
            +left_ltr(rem(15px))
            top: rem(14px)

        &.agreement-paid
          &:after
            background-image: url('../images/pages/orders/agreement.svg')

.b-progress-mobile-texts
  text-align: center
  font-size: rem(16px)
  line-height: 1.3

  +gte_laptop
    display: none
