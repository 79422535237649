.p-vacancies-index
  h1
    +lte_ipad
      margin-bottom: rem(24px)

    +gte_laptop
      margin-bottom: 56px

  .background-image-container
    display: flex
    justify-content: center
    +mx-auto

    img
      max-width: 100%

  .vacancies
    +lte_ipad
      margin-top: rem(-104px)

    +gte_laptop
      margin-top: -56px

  .vacancy
    background-color: #fff
    border-radius: rem(20px)
    border: 2px solid #e6ecf6
    box-shadow: 0px 22px 56px -22px rgba(95, 120, 143, 0.15)
    display: flex
    overflow: hidden
    position: relative
    transition: background-color var(--transition-duration), border-color var(--transition-duration)

    +iphone
      align-items: center
      flex-direction: column
      padding: rem(24px)
      text-align: center

    +gte_ipad
      height: 144px
      padding: 38px 56px 30px

      &:hover
        background-color: #5096ff
        border-color: #5096ff
        text-decoration: none

        &:before
          background-image: url('../images/pages/vacancies/logo-hover.svg')

        .name, .description, .salary
          color: #fff

    &:active
      background-color: var(--primary-color)
      border-color: var(--primary-color)
      text-decoration: none

      &:before
        background-image: url('../images/pages/vacancies/logo-hover.svg')

      .name, .description, .salary
        color: #fff

    &:not(:last-child)
      margin-bottom: rem(24px)

    &:before
      background-image: url('../images/pages/vacancies/logo.svg')
      background-size: cover
      content: ''
      position: absolute

      +lte_ipad
        bottom: rem(-60px)
        height: rem(160px)
        +right_ltr(rem(-11px))
        width: rem(179px)

      +gte_laptop
        bottom: -91px
        height: 204px
        +right_ltr(35px)
        width: 228px

    .name, .description, .salary
      transition: color var(--transition-duration)

    .side-left
      +lte_ipad
        margin-bottom: rem(24px)

      +gte_laptop
        width: 70%

    .side-right
      position: relative
      z-index: 1

      +gte_laptop
        align-self: flex-end

    .name
      font-weight: 700
      color: var(--text-strong)

      +lte_ipad
        font-size: rem(24px)
        line-height: rem(28px)
        margin-bottom: rem(8px)

      +gte_laptop
        font-size: 32px
        line-height: 36px
        margin-bottom: 18px

    .description
      color: var(--text-base)
      font-size: rem(16px)
      line-height: 1.3

    .salary
      font-weight: 700
      font-size: rem(24px)
      line-height: rem(28px)
      color: var(--text-strong)

      +lte_ipad
        margin-bottom: rem(12px)

      +gte_laptop
        margin-bottom: 16px

    .learn-more
      +button_red

      +lte_ipad
        width: rem(160px)

  .note
    color: var(--text-strong)
    text-align: center

    +lte_ipad
      font-size: 16px
      line-height: 1.3
      margin-top: rem(48px)

    +gte_laptop
      font-size: 20px
      line-height: 24px
      +mx-auto
      margin-top: 56px
      max-width: 474px

    a
      +link(var(--primary-color))
