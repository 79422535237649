article.mobile_app_slider
  position: relative

  +lte_ipad
    // have to cut overflowed mobile backgrounds
    overflow: hidden

  .inner
    position: relative

    +gte_laptop
      display: flex

    &[data-slide='1'] .slide_1-background,
    &[data-slide='2'] .slide_2-background,
    &[data-slide='3'] .slide_3-background,
    &[data-slide='4'] .slide_4-background
      opacity: 1

    .slide_1-background,
    .slide_2-background,
    .slide_3-background,
    .slide_4-background
      +rtl_flip
      background-repeat: no-repeat
      background-size: contain
      opacity: 0
      position: absolute
      transition: opacity var(--transition-duration)
      z-index: 1

      +gte_laptop
        +left_ltr(7px)

    .slide_1-background
      background-image: url('../images/articles/mobile_app_slider/slide_1_background.svg')

      +lte_ipad
        height: rem(124px)
        +right_ltr(rem(22px))
        top: rem(-17px)
        width: rem(126px)

      +gte_laptop
        height: 242px
        +left_ltr(33px)
        top: -72px
        width: 245px

    .slide_2-background
      background-image: url('../images/articles/mobile_app_slider/slide_2_background.svg')

      +lte_ipad
        height: rem(126px)
        top: rem(-20px)
        +left_ltr(rem(112px))
        width: rem(153px)

      +gte_laptop
        height: 246px
        top: -68px
        width: 299px

    .slide_3-background
      background-image: url('../images/articles/mobile_app_slider/slide_3_background.svg')

      +lte_ipad
        height: rem(123px)
        top: rem(-20px)
        +left_ltr(rem(182px))
        width: rem(96px)

      +gte_laptop
        height: 239px
        +left_ltr(41px)
        top: -65px
        width: 187px

    .slide_4-background
      +lte_ipad
        +right_ltr(rem(-87px))
        top: rem(-30px)

      +laptop-new
        +left_ltr(scale-laptop(-112px, -137px))
        top: scale-laptop(-14px, -17px)
        width: scale-laptop(423px, 519px)

      +gte_desktop-new
        +left_ltr(-137px)
        top: -17px
        width: 519px

        img
          width: 100%

    .slides-background
      position: relative
      z-index: 2

      +iphone
        +margin-left_ltr(rem(-10px))

      +laptop-new
        +margin-left_ltr(scale-laptop(96px, 54px))
        width: scale-laptop(510px, 640px)

      +gte_desktop-new
        +margin-left_ltr(54px)
        width: 640px

      img
        max-width: 100%

    picture.slide-background
      position: relative
      z-index: 2

      +lte_ipad
        top: rem(22px)

      +laptop-new
        +margin-left_ltr(scale-laptop(76px, 96px))
        width: scale-laptop(522px, 640px)

      +gte_desktop-new
        +margin-left_ltr(96px)
        width: 640px

      img
        max-width: 100%

    .background
      +gte_laptop
        display: flex
        flex-direction: column
        flex-grow: 1
        +margin-right_ltr(36px)

    .content
      +lte_ipad
        margin-top: rem(35px)

      +gte_laptop
        display: flex
        flex-direction: column
        justify-content: center

  .swiper-v8
    overflow: hidden

    +lte_ipad
      margin-bottom: rem(72px)
      width: 100%

    +gte_laptop
      margin-bottom: 97px
      +margin-right_ltr(82px)
      padding-top: 6px
      width: 273px

    .slides
      display: flex

      +lte_ipad
        margin-bottom: rem(28px)

      +gte_laptop
        margin-bottom: 40px

      .headline
        color: var(--text-strong)
        font-weight: bold

        +lte_ipad
          font-size: rem(16px)
          line-height: rem(24px)
          margin-bottom: rem(12px)

        +gte_laptop
          font-size: 18px
          line-height: 22px
          margin-bottom: 25px

      .note
        line-height: 1.3
        color: var(--text-strong)

        +lte_ipad
          font-size: rem(12px)

        +gte_laptop
          font-size: 16px

      .slide
        flex-shrink: 0
        width: 100%

        +lte_ipad
          display: flex
          flex-direction: column
          text-align: center

    .b-slider-prev
      +left_ltr(0)

    .b-slider-next
      +right_ltr(0)

    .b-slider-prev,
    .b-slider-next
      top: 50%
      transform: translateY(-50%)
      margin-top: -24px
      position: absolute
      z-index: 1

      +lte_ipad
        display: none

    .b-slider-pagination
      +lte_ipad
        margin-top: rem(-20px)
        transition: margin-top var(--transition-duration)

  .b-store_badges
    flex-direction: row

    +lte_ipad
      margin-bottom: rem(26px)

    +gte_laptop
      justify-content: space-between

    .google-play,
    .apple-store
      height: rem(47px)
      width: rem(161px)

    .apple-store
      margin-bottom: 0
