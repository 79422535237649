footer.b-footer.v3
  background: #1570ef
  color: #fff

  +iphone
    margin-top: rem(56px)
    padding: rem(56px) rem(12px)

  +gte_ipad
    margin-top: 96px
    padding: 96px 0 48px

  .b-sticky-area + &
    +gte_ipad
      padding-bottom: #{48px + 81px}

  .container
    +iphone
      padding: 0 rem(12px)

  .block
    &:not(:last-child)
      border-bottom: 1px solid var(--border-on-color)
      margin-bottom: var(--padding-5xl)
      padding-bottom: var(--padding-5xl)

    +gte_ipad
      display: flex

    .left
      +iphone
        margin-bottom: rem(32px)

      +gte_ipad
        +margin-right_ltr(auto)

    .right
      align-items: flex-end
      display: flex
      flex-direction: column

  .first
    .text
      +h2
      color: inherit

    .right
      gap: var(--padding-md)

      .book-now
        +button_white

        +gte_ipad
          +button_width_auto

      .view-demo
        +button_frame_white
        gap: rem(8px)

        +gte_ipad
          +button_width_auto

        &:before
          content: ''
          background-repeat: no-repeat
          background-size: contain
          background-image: url('../images/blocks/b-footer-v3/play-circle.svg')
          width: rem(20px)
          height: rem(20px)

  .second
    .left
      .text
        +h4
        margin-bottom: var(--padding-md)

      .note
        +iphone
          +p4

        +gte_ipad
          +p2
          max-width: 275px

    .right
      +iphone
        gap: rem(16px)

      +gte_ipad
        justify-content: space-between

      .text
        font-weight: 700

        +iphone
          +h4

        +gte_ipad
          +p2

      .buttons
        display: flex
        gap: rem(16px)

      .app_store,
      .google_play
        background-repeat: no-repeat
        background-size: contain
        height: rem(40px)

      .app_store
        width: rem(120px)

      .google_play
        width: rem(135px)

      @each $type in app_store, google_play
        .#{$type}
          background-image: url('../images/blocks/b-footer-v3/#{$type}.svg')

  .third
    .copy,
    a
      +iphone
        +p5

      +gte_ipad
        +p4

    .left
      +iphone
        margin-bottom: rem(16px)

    .right
      flex-direction: row

      +iphone
        gap: rem(12px)

      +gte_ipad
        gap: 24px

      a
        +gte_ipad
          &:hover
            color: var(--text-on-color-hover)

        &:active
          color: var(--text-on-color-active)

