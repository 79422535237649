.b-photos
  line-height: 0
  overflow: hidden
  position: relative
  width: 100%

  &:not(.swiper-container-initialized)
    .photos-slider-prev,
    .photos-slider-next
      display: none

  .photos-slider-prev,
  .photos-slider-next
    -webkit-appearance: none
    align-items: center
    background-color: #fff
    box-shadow: 0px 0px 21px rgba(139, 152, 167, 0.47)
    color: #fff
    cursor: pointer
    display: inline-flex
    justify-content: center
    opacity: 0.5
    outline: none
    position: absolute
    text-align: center
    top: 50%
    transform: translateY(-50%)
    user-select: none
    z-index: 3

    &:hover
      opacity: 0.8

    +iphone
      border-radius: rem(32px)
      height: rem(32px)
      width: rem(32px)

    +gte_ipad
      border-radius: 40px
      height: 40px
      width: 40px

    &:before
      background-image: url('../images/arrow-dark-right.svg')
      background-position: center
      background-repeat: no-repeat
      content: ''
      display: block

      +iphone
        height: rem(14px)
        width: rem(10px)

      +gte_ipad
        height: 20px
        width: 13px

    &[aria-disabled='true']
      background-color: #e5e5e5
      cursor: default

    +gte_laptop
      &:not([aria-disabled='true']):hover
        background-color: lighten($primary, 4%)

        &:before
          background-image: url('../images/arrow-light-right.svg')

    &:not([aria-disabled='true']):active
      background-color: darken($primary, 6%)

      &:before
        background-image: url('../images/arrow-light-right.svg')

  .photos-slider-prev
    +left_ltr(rem(24px))

    &:before
      transform: rotate(180deg)

  .photos-slider-next
    +right_ltr(rem(24px))

  .tns-horizontal.tns-subpixel
    white-space: normal // default "nowrap" breaks width for flex 

  &.swiper-v8
    .photos-slider
      box-sizing: content-box
      display: flex
      height: 100%
      position: relative
      transform: translate3d(0px, 0, 0)
      transition-property: transform
      width: 100%
      z-index: 1

      .photo
        align-items: center
        background: #fff
        display: flex
        flex-shrink: 0
        font-size: rem(18px)
        justify-content: center
        position: relative
        text-align: center
        transition-property: transform

  &.fullsize-horizontal
    &:before
      background: linear-gradient(to right, #fff, transparent)
      content: ''
      height: 100%
      +left_ltr(0)
      pointer-events: none
      position: absolute
      top: 0
      width: 60px
      z-index: 2

    &:after
      background: linear-gradient(to left, #fff, transparent)
      content: ''
      height: 100%
      pointer-events: none
      position: absolute
      +right_ltr(0)
      top: 0
      width: 60px
      z-index: 2

    .photo img
      +lte_ipad
        border-radius: rem(6px)

      +gte_laptop
        border-radius: 10px

    &:not(.swiper-v8)
      .photo img
        width: 100%
        max-width: 100%

      &:before,
      &:after,
      .photos-slider-next,
      .photos-slider-prev
        display: none

      .photos-slider
        display: flex
        justify-content: center

        .photo
          +mr(30px)

          img
            max-width: 100%
