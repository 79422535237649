.b-top_menu.v4
  align-items: center
  justify-content: space-between

  +lte_ipad
    display: flex
    margin-bottom: rem4(14px)
    padding: rem4(16px) 0
    position: relative

  +gte_laptop
    display: grid
    margin-bottom: 96px
    margin-top: 32px
    grid-template-columns: 1fr 210px 1fr
    padding: var(--spacing-md) 0

  .icons-support
    display: flex

    +lte_ipad
      gap: rem4(10px)

    +gte_laptop
      gap: 12px

    .icons
      display: flex
      gap: var(--spacing-md)

      .locale_switcher-modal-trigger,
      .whatsapp,
      .telegram,
      .contacts-modal-trigger
        +lte_ipad
          +button_v4('secondary', 'sm', $is_empty: true)

        +gte_laptop
          +button_v4('secondary', 'md', $is_empty: true)

      .locale_switcher-modal-trigger
        &:before
          +font-icon('\E811')

        &[data-country]:before
          content: ''
          background-position: center
          background-repeat: no-repeat
          background-size: contain

          +lte_ipad
            height: rem4(18px)
            width: rem4(26px)

          +gte_laptop
            height: 18px
            width: 26px

        &[data-country=estonia]:before
          background-image: url(../images/blocks/b-top_menu-v4/🇪🇪.png)

        &[data-country=uae]:before
          background-image: url(../images/blocks/b-top_menu-v4/🇦🇪.png)

      .whatsapp
        &:before
          +font-icon('\E826')

        +lte_ipad
          display: none

      .telegram
        &:before
          +font-icon('\E822')

        +lte_ipad
          display: none

      .contacts-modal-trigger
        +lte_ipad
          &:before
            +font-icon('\E82A')

        +gte_laptop
          display: none

    .support
      +p4(600)
      display: flex
      flex-direction: column
      gap: -2px

      +lte_ipad
        display: none

      .hint
        +p5(600)

  .logo-container
    display: flex
    justify-content: center

  .logo
    background-repeat: no-repeat
    background-size: contain
    flex-shrink: 0

    +lte_ipad
      +margin-right_ltr(auto)
      background-image: url(../images/blocks/b-top_menu-v4/logo-icon.svg)
      height: rem4(32px)
      order: -1
      width: rem4(32px)

    +gte_laptop
      &.logo-ru
        background-image: url(../images/blocks/b-top_menu-v4/logo-ru.svg)
        height: 32px
        width: 155px

      &.logo-et
        background-image: url(../images/blocks/b-top_menu-v4/logo-et.svg)
        height: 32px
        width: 137px

  .auth-selfstorage
    display: flex
    gap: var(--spacing-md)

    +lte_ipad
      +margin-left_ltr(rem4(10px))

    +gte_laptop
      +margin-left_ltr(10px)
      justify-content: flex-end

    a.auth,
    a.profile,
    a.admin
      +lte_ipad
        +button_v4('secondary', 'sm', $is_empty: true)

        span
          display: none

        &.auth:before
          +font-icon('\E824')

        &.profile:before
          +font-icon('\E823')

      +gte_laptop
        +button_v4('secondary', 'md')

    a.admin
      +lte_ipad
        display: none

    a.selfstorage
      +lte_ipad
        +button_v4('brand', 'sm')

      +gte_laptop
        +button_v4('brand', 'md')

  .popup
    +p1(700)
    backdrop-filter: blur(rem4(60px))
    background: rgba(255, 255, 255, 0.34)
    border-radius: rem4(16px)
    display: flex
    flex-direction: column
    gap: rem4(16px)
    left: 0
    line-height: rem4(28px)
    opacity: 0
    padding: rem4(16px)
    position: absolute
    top: rem4(64px)
    transition: opacity var(--transition-duration)
    width: 100%
    z-index: -1

    +gte_laptop
      display: none

    &.is-active
      opacity: 1
      z-index: 1

    .close
      align-items: center
      color: var(--gray-400)
      display: flex
      height: rem4(30px)
      justify-content: center
      position: absolute
      right: rem(12px)
      top: rem(12px)
      transition: color var(--transition-duration)
      width: rem4(30px)

      &:before
        +font-icon('\E82B')
        font-size: rem4(20px)

      &:active
        color: var(--gray-900)

    a
      &.is-current,
      &.is-current:active
        color: var(--gray-400)

      &:active
        text-decoration: none
        color: var(--gray-900)
