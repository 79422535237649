.b-storage_map
  .level.active
    background-color: #467fcf
    color: #fff

  .fabric-inputs
    align-items: center
    display: flex

    input
      font-size: 13px

      &.sizes
        width: 50px

      &.coords
        width: 65px

  .box-list
    max-height: 480px

    .box
      column-gap: 24px
      display: flex
      white-space: nowrap
      flex-wrap: wrap

      &.active .tag
        background-color: #467fcf
        color: #fff

      .tag
        display: inline-block
        min-width: 50px
        text-align: center

      .icon
        font-size: 18px

    .fabric-inputs
      display: inline-flex

  .map-inner
    border: 1px solid #ccc
    position: relative
    width: 100%

    .controls
      bottom: 6px
      position: absolute
      +right_ltr(10px)

      .zoom-in,
      .zoom-out
        cursor: pointer
        font-size: 10px
        height: 24px
        line-height: 24px
        opacity: 0.6
        position: relative
        text-align: center
        transition: .2s color ease
        user-select: none
        width: 24px

        &:hover
          opacity: 1.0

        &:active
          opacity: 0.8

        svg
          +left_ltr(3px)
          position: absolute
          top: 3px
          width: auto
          z-index: 1

        //&:after
          content: ''
          background: #fff
          width: 12px
          height: 12px
          position: absolute
          left: 5px
          top: 6px
