$gap: 30px
$gray: #e6ecf6
.p-orders-v2 .progress
  display: flex
  gap: rem($gap)
  margin-bottom: rem(65px)

  .step
    align-items: center
    display: flex
    flex-basis: 33.3333%
    flex-grow: 1
    justify-content: center
    position: relative

    .icon
      align-items: center
      background-color: $gray
      border-radius: 100%
      box-sizing: content-box
      display: flex
      justify-content: center

      +lte_ipad
        height: rem(40px)
        width: rem(40px)

      +gte_laptop
        height: 56px
        width: 56px

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''

    &.is-complete .icon
      background-color: var(--primary-color)
      filter: drop-shadow(0px #{rem(2px)} #{rem(8px)} rgba(51, 51, 51, 0.10))

      &:before
        background-image: url('../images/pages/orders-v2/icon-check.svg')

        +lte_ipad
          height: rem(15px)
          width: rem(15px)

        +gte_laptop
          height: 16px
          width: 16px

    &.is-current .icon
      background-color: var(--secondary-color)
      border: solid #{rem(5px)} #fff
      filter: drop-shadow(0px #{rem(4px)} #{rem(12px)} rgba(51, 51, 51, 0.15))

      +lte_ipad
        height: rem(56px)
        width: rem(56px)

      &:before
        height: rem(24px)
        margin-top: rem(2px)
        width: rem(24px)

      &.icon-auth:before
        background-image: url('../images/pages/orders-v2/icon-auth.svg')
        margin-left: rem(-4px)

      &.icon-profile:before
        background-image: url('../images/pages/orders-v2/icon-profile.svg')
        margin-left: rem(4px)

    &.is-pending .icon
      +gte_laptop
        height: 40px
        width: 40px

      &:before
        background-color: #fff
        border-radius: 100%

        +lte_ipad
          height: rem(11px)
          width: rem(11px)

        +gte_laptop
          height: 12px
          width: 12px

    &.is-complete:before,
    &.is-complete:after,
    &.is-current:before
      background-color: var(--primary-color)

      +gte_laptop
        height: 12px

    &.is-current:after,
    &.is-pending:before,
    &.is-pending:after
      background-color: $gray

      +gte_laptop
        height: 8px

    &:first-child:before,
    &:last-child:after
      display: none

    &:before,
    &:after
      content: ''
      position: absolute
      top: 50%
      transform: translateY(-50%)
      width: calc(50% + #{rem($gap)})
      z-index: -1

      +lte_ipad
        height: rem(6px)

    &:before
      left: rem(-$gap)

    &:after
      right: rem(-$gap)

    .text
      position: absolute

      +lte_ipad
        font-size: rem(14px)
        text-align: center

      +gte_laptop
        font-size: 14px

    &.is-complete .text,
    &.is-pending .text
      color: var(--text-secondary)

      +lte_ipad
        top: rem(70px)

      +gte_laptop
        bottom: -16px

    &.is-current .text
      color: var(--text-strong)
      font-weight: 700
      line-height: 1.3

      +lte_ipad
        top: rem(72px)

      +gte_laptop
        font-size: 16px
        bottom: -28px
