=mr($offset)
  +margin-right_ltr($offset)

  &:last-child
    +margin-right_ltr(0)

=mb($offset)
  margin-bottom: $offset

  &:last-child
    margin-bottom: 0

=mx-auto
  margin-left: auto
  margin-right: auto
