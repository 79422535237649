.b-card
  border: 1px solid var(--secondary-color)
  border-radius: rem(12px)
  display: flex
  flex-direction: column

  +lte_ipad
    padding: rem(27px) rem(15px) rem(24px)
    min-height: rem(288px)

  +gte_laptop
    padding: 0 24px 30px
    min-height: 350px

    &:hover
      box-shadow: 0px 5px 20px rgba(241, 83, 39, 0.2)

  &.active
    border: 1px solid var(--primary-color)

    .header
      font-weight: 500
      border-bottom: 1px solid var(--primary-color)

  .header
    border-bottom: 1px solid var(--secondary-color)
    font-size: rem(26px)
    line-height: rem(74px)
    text-align: center

  .mobile-header
    +lte_ipad
      border-bottom: 1px solid var(--secondary-color)
      padding-bottom: rem(12px)

  .centered
    margin: auto 0

    +lte_ipad
      padding: rem(17px) 0

    +gte_laptop
      padding: 30px 0

    & + .footer
      margin-top: 0

  .body-text
    color: #8b98a7
    line-height: 1.65
    margin: rem(45px) 0
    text-align: center

    & + .footer
      padding-top: 0

  .footer
    margin-top: auto

    +lte_ipad
      display: flex

    .b-button
      +lte_ipad
        width: 100%
