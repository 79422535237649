.p-pages-promotions
  article.headline .image:before
    background-image: url('../images/pages/promotions/background.jpg')
    background-size: 100%
    background-size: contain
    content: ''
    display: block

    +lte_ipad
      height: rem(249px * math.div(288, 722))
      width: rem(288px)

    +gte_laptop
      height: 262px
      width: 722px

  .promotion
    margin-bottom: rem(60px)

    a
      color: var(--primary-color)
      font-size: rem(18px)
      font-weight: 700
      line-height: rem(24px)
