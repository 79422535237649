=ltr
 html[dir='ltr'] &
    @content

=rtl
 html[dir='rtl'] &
    @content

// миксин нужен только с одной целью, увеличить nesting стилеей,
// чтобы придать правильный приоритет по аналогии,
// как +ltr/+rtl увеличивают nesting у стилей
=ltr_rtl
  html[dir] &
    @content

=text-align_ltr($value)
  +ltr
    text-align: $value

  +rtl
    @if $value == left
      text-align: right
    @else if $value == right
      text-align: left
    @else
      @error "unexpected text-align: #{$value}"

=margin-left_ltr($value)
  +ltr
    margin-left: $value

  +rtl
    margin-right: $value

=margin-right_ltr($value)
  +ltr
    margin-right: $value

  +rtl
    margin-left: $value

=padding-left_ltr($value)
  +ltr
    padding-left: $value

  +rtl
    padding-right: $value

=padding-right_ltr($value)
  +ltr
    padding-right: $value

  +rtl
    padding-left: $value

=left_ltr($value)
  +ltr
    left: $value

  +rtl
    right: $value

=right_ltr($value)
  +ltr
    right: $value

  +rtl
    left: $value

=rtl_flip
  +rtl
    transform: scaleX(-1)
